<template>
    <div v-if="getValues.success === false">
        <h1 class="text-center">{{ getValues.message }}</h1>
        <div v-if="user.role === 'admin'">
            <router-link to="/newcourse">Crea Nuovo Corso</router-link>
        </div>
    </div>
    <div v-else>
        <h1 class="bgBlue">Percorsi ESAMIFINANZA</h1>
        <Toolbar v-if="user.role === 'admin'">
            <template #start>
                <Button label="Aggiungi Nuovo Corso" icon="pi pi-plus" class="btnLightBlue mr-2" @click="toAddNewCourse" />
            </template>
            <template #end>
                <Button label="Sottoscrizioni" icon="pi pi-sign-out" class="btnLightBlue mr-2" @click="toSubs" />
            </template>
        </Toolbar>
        <DataTable :value="courses" class="p-datatable" responsiveLayout="stack">
            <Column field="courseName" header="PERCORSO"></Column>
            <Column field="editionNum" header="#EDIZIONE"></Column>
            <Column v-if="user.role === 'admin'" :exportable="false" style="min-width:8rem">
                <template #body="slotProps">
                    <Button icon="pi pi-search" class="mr-2 btnLightBlue" @click="toCourseDetails(slotProps.data.course_id)" />
                    <Button icon="pi pi-plus" class="mr-2 btnGreen" @click="showPanel" />
                    <Dialog v-model:visible="displayPanel">
                        <template #header>
                            <h3>BOX PER AGGIUNTA EDIZIONE CORSO</h3>
                        </template>
                        <p>
                            Il corso in modifica è il <strong>{{slotProps.data.courseName}}</strong>, attualmente all'edizione <strong>#{{slotProps.data.editionNum}}</strong>. <br> 
                            Se si vuole creare una nuova edizione, mettere il numero nella box sottostante e premere crea!
                        </p>
                        <span class="p-float-label">
                            <InputText id="newEditionNum" type="text" v-model="form.newEditionNum" />
                            <label for="newEditionNum">Numero nuova edizione</label>
                        </span>
                        <template #footer>
                            <Button label="ANNULLA" icon="pi pi-times" @click="closePanel" class="p-button-text"/>
                            <Button label="CREA" icon="pi pi-check" @click="addEdCreateNewCourse(slotProps.data.courseName)" />
                        </template>
                    </Dialog>
                    <!-- <Button icon="pi pi-pencil" class="mr-2 btnGrey" /> -->
                </template>
            </Column>
            <Column v-else :exportable="false" style="min-width:8rem">
                <template #body="slotProps">
                    <Button icon="pi pi-search" class="mr-2 btnLightBlue" @click="toCourseDetails(slotProps.data.course_id)" />
                    <Button v-if="trialSubUsed" label="PROVA GRATIS" class="mr-2 btnGrey" disabled="disabled" />
                    <Button v-else label="PROVA GRATIS" class="mr-2 btnGreen" @click="showFilteredSubs(slotProps.data.courseName, parseInt(slotProps.data.course_id))" />
                    <!-- <Button v-if="existsActiveSub" label="ACQUISTA" class="mr-2 btnGrey" disabled="disabled" /> -->
                    <Button label="ACQUISTA" class="mr-2 btnGreen" @click="showFilteredSubs(slotProps.data.courseName, parseInt(slotProps.data.course_id))" />
                </template>
            </Column>
        </DataTable>
    </div>  
</template>

<script>
//import { mapState } from 'vuex';
import axios from 'axios';
import { mapState, mapActions } from 'vuex';


export default {
    data() {
        return {
            token: '',
            courses: [],
            getValues: {
                success: '',
                message: ''
            },
            form: {
                newEditionNum: null
            },
            boxIsOpen: false,
            selectedBoxId: null,
            selectedBoxCourseName: '',
            customMex: {},
            insertBoxValues: {
                success: '',
                message: ''
            },
            displayPanel: false,
            numUserActiveSubs: null,
            existsActiveSub: false,
            trialSubUsed: false,
            infoMex: ''
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['setCourseName', 'setCourseId', 'setEdtId', 'setExistsActiveSubs', 'changeUserHasActiveSub']),
        getCourseList() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/coursesEditions`)
            .then((res) => {
                console.log(res.data.courses);
                if(res.data.success) {
                    
                    this.courses = res.data.courses;
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;

                } else {
                    
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message; 
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.courses.length > 0) {
                    this.trialSubUsedForEdCourse(this.$store.state.user.id);
                }
                
                console.log('chiamata terminata');
            });
        },
        countUserSub(id) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/userActiveSubs', { id: id }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.numUserActiveSubs = res.data.numActiveSubs;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.numUserActiveSubs === 0) {
                    // scrivere nel vuex false in corrispondenza della voce se esistono sub attive
                    console.log('valore store.state.areThereUserActiveSubs');
                    console.log(this.$store.state.areThereUserActiveSubs);
                    this.setExistsActiveSubs(false);
                    // cambio anche il valore di has_active_sub nel vuex, perchè anche se cambiato in db nel vuex non cabia fino al logout
                    this.changeUserHasActiveSub(0);
                } else if(this.numUserActiveSubs > 0) {
                    // scrivere nel vuex true
                    this.setExistsActiveSubs(true);
                    console.log('valore store.state.areThereUserActiveSubs');
                    console.log(this.$store.state.areThereUserActiveSubs);
                    this.changeUserHasActiveSub(1);
                }
                console.log('chiamata terminata da fn countUserSub');
            });
        },
        trialSubUsedForEdCourse(uID) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/trialSubUsedForEdCourse', { params: { userID: uID } , headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.trialSubUsed = res.data.trialSubUsed;
                } else {
                    this.trialSubUsed = res.data.trialSubUsed;
                    this.infoMex = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.trialSubUsed) {
                    // la sub trial è già stata usata dall'utente
                    // blocco la possibilità di usarla ancora
                    console.log('trial sub already used');
                    console.log(this.trialSubUsed);
                }
                console.log('chiamata terminata fn getFilteredSubs');
            });
        },
        showFilteredSubs(cName, courseID) {
            this.setCourseName(cName);
            this.setEdtId(courseID);
            this.$router.push({ name: 'ShowcaseSubs', params: { courseName: this.$store.state.selCourseName, edition_id: parseInt(this.$store.state.selEdtId) }  });
        },
        toCourseDetails(courseId) {
            this.$router.push({ name: 'CourseDetails', params: { id: courseId } });
        },
        // FUNZIONI SOLO ADMIN
        toAddNewCourse() {
            this.$router.push('/newcourse');
        },
        toSubs() {
            this.$router.push('/subs');
        },
        addEdCreateNewCourse(courseName) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/addcourse', { courseName: courseName, editionNum: this.form.newEditionNum }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);    
                    }
                    this.insertBoxValues.success = res.data.success;
                    this.insertBoxValues.message = res.data.message;
                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);    
                    }
                    this.insertBoxValues.success = res.data.success;
                    this.insertBoxValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log(this.insertBoxValues.message);
                this.token = this.$store.state['x-access-token'];
                if(this.insertBoxValues.success) {
                    this.form.newEditionNum = null;
                    this.boxIsOpen = false;
                    this.box.style.display = "none";
                    this.getCourseList();
                    setInterval(this.hideMex, 3000);
                }
                console.log('chiamata terminata');
            });
        },
        hideMex() {
            this.insertBoxValues.success = '';
        },
        showPanel() {
            this.displayPanel = true;
        },
        closePanel() {
            this.displayPanel = false;
        },
        // FUNZIONI NON UTILIZZATE 
        showHide(obj) {
            console.log(obj);
            //this.selectedBoxId = obj.id;
            if(!this.boxIsOpen) {
                this.selectedBoxId = obj.id;
                console.log(this.selectedBoxId);
                this.selectedBoxCourseName = obj.courseName;
                console.log(this.selectedBoxCourseName);
                this.boxIsOpen = true;
                this.box.style.display = "block";
                
                let edition;
                if(!obj.editionNum) {
                    edition = 0;
                } else {
                    edition = obj.editionNum;
                }
                const content = `Il corso in modifica è il ${obj.courseName}, attualmente all'edizione #${edition}. \n 
                Se si vuole creare una nuova edizione, mettere il numero nella box sottostante e premere crea!`;
            
                this.customMex.innerHTML = content;

            } else if(this.boxIsOpen && this.selectedBoxId == obj.id){
                this.boxIsOpen = false;
                this.box.style.display = "none";
            } else { // caso in cui il box è aperto, ma l'id è diverso
                // in questo caso mostriamo il nuovo messaggio 
                // settiamo il nuovo id come selezionato
                this.selectedBoxId = obj.id;
                console.log(this.selectedBoxId);
                this.selectedBoxCourseName = obj.courseName;
                console.log(this.selectedBoxCourseName);
                let edition;
                if(!obj.editionNum) {
                    edition = 0;
                } else {
                    edition = obj.editionNum;
                }
                const content = `Il corso in modifica è il ${obj.courseName}, attualmente all'edizione #${edition}. \n 
                Se si vuole creare una nuova edizione, mettere il numero nella box sottostante e premere crea!`;
                
                this.customMex.innerHTML = content;
            }
            
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        // valutazione user role
        if(this.$store.state.user.role === 'admin') {
            this.getCourseList();
        } else {
            this.getCourseList();
            this.countUserSub(this.$store.state.user.id);
            if(this.$store.state.areThereUserActiveSubs || this.$store.state.user['has_active_sub'] === 1) {
                this.existsActiveSub = true;
            }
        }
    } 
}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.p-datatable {
    max-width: 100vw;
}
</style>
