import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import LoginForm from '../views/LoginForm.vue'
import RegisterForm from '../views/RegisterForm.vue'
import ConfirmRegistration from '../views/ConfirmRegistration.vue'
import ForgotPwd from '../views/ForgotPwd.vue'
import ConfForgPwd from '../views/ConfForgPwd.vue'
import ChangePwd from '../views/ChangePwd.vue'
import Dashboard from '../views/Dashboard.vue'
import PaymentArea from '../views/PaymentArea.vue'
import AdminProfile from '../views/admin/AdminProfile.vue'
import Users from '../views/admin/Users.vue'
import UserDetails from '../views/admin/UserDetails.vue'
import EditUser from '../views/admin/EditUser.vue'
import AddNewUser from '../views/admin/AddNewUser.vue'
import UserProfile from '../views/users/UserProfile.vue'
import UserResults from '../views/users/UserResults.vue'
import UserSubsIndex from '../views/users/UserSubsIndex.vue'
import UserCourseChoice from '../views/users/UserCourseChoice.vue'
import Courses from '../views/courses/Courses.vue'
import CourseDetails from '../views/courses/CourseDetails.vue'
import CreateCourse from '../views/courses/CreateCourse.vue'
import PaymentOk from '../views/courses/private/PaymentOk.vue'
import PrivateAreaHome from '../views/courses/private/PrivateAreaHome.vue'
import QuizArea from '../views/courses/private/QuizArea.vue'
import ShowcaseSubs from '../views/subscriptions/ShowcaseSubs.vue'
import SubControlPanel from '../views/subscriptions/SubControlPanel.vue'
import AddSub from '../views/subscriptions/AddSub.vue'
import EditSub from '../views/subscriptions/EditSub.vue'
import Questions from '../views/questions/Questions.vue'
import EditQ from '../views/questions/EditQ.vue'
import AddQ from '../views/questions/AddQ.vue'
import QuizIndex from '../views/quiz/QuizIndex.vue'
import QuizCompleted from '../views/quiz/QuizCompleted.vue'
import CreateQuiz from '../views/quiz/CreateQuiz.vue'
import UpdateQuiz from '../views/quiz/UpdateQuiz.vue'
import ShowQuizCompleted from '../views/quiz/ShowQuizCompleted.vue'
import ShowQuizInProgress from '../views/quiz/ShowQuizInProgress.vue'

import store from '../store'

const routes = [
    // ROTTE LIBERE DA RESTRIZIONI
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/login',
        name: 'LoginForm',
        component: LoginForm
    },
    {
        path: '/register',
        name: 'RegisterForm',
        component: RegisterForm
    },
    {
        path: '/confirmRegistration',
        name: 'ConfirmRegistration',
        component: ConfirmRegistration
    },
    {
        path: '/forgotPwd',
        name: 'ForgotPwd',
        component: ForgotPwd
    },
    {
        path: '/confForgPwd',
        name: 'ConfForgPwd',
        component: ConfForgPwd
    },
    // ROTTE CON NECESSITA' DI LOGIN
    {
        path: '/changepwd',
        name: 'ChangePwd',
        component: ChangePwd,
        beforeEnter: checkIfUserIsLogged
    },
    // ROTTE ESCLUSIVAMENTE ADMIN
    {
        path: '/adminProfile',
        name: 'AdminProfile',
        component: AdminProfile,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/user/:id',
        name: 'UserDetails',
        component: UserDetails,
        props: true,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/user/:id/edit',
        name: 'EditUser',
        component: EditUser,
        props: true,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/newuser',
        name: 'AddNewUser',
        component: AddNewUser,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/newcourse',
        name: 'CreateCourse',
        component: CreateCourse,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/addsub',
        name: 'AddSub',
        component: AddSub,
        props: true,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/editsub',
        name: 'EditSub',
        component: EditSub,
        props: true,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/questions',
        name: 'Questions',
        component: Questions,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/question/edit',
        name: 'EditQ',
        component: EditQ,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    {
        path: '/question/add',
        name: 'AddQ',
        component: AddQ,
        beforeEnter: checkIfUserIsLoggedAsAdmin
    },
    // FINE SEZIONE ROTTE ESCLUSIVE ADMIN

    // SEZIONE ROTTE SIA ADMIN SIA USER
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: checkIfUserIsLoggedPWDChangedAndProfileComplete
    },
    {
        path: '/userProfile',
        name: 'UserProfile',
        component: UserProfile,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/userSubs',
        name: 'UserSubsIndex',
        component: UserSubsIndex,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/shop',
        name: 'PaymentArea',
        component: PaymentArea,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/userResults',
        name: 'UserResults',
        component: UserResults,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/courses',
        name: 'Courses',
        component: Courses,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/course/:id',
        name: 'CourseDetails',
        component: CourseDetails,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/courses/homePrivate',
        name: 'PrivateAreaHome',
        component: PrivateAreaHome,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/courses/paymentDone/:session_id/:us_id/:sub_id/:duration/:courseId',
        name: 'PaymentOk',
        component: PaymentOk,
        params: true,
        beforeEnter: checkIfUserIsLoggedPWDChangedAndProfileComplete
    },
    {
        path: '/courses/areaQuiz',
        name: 'QuizArea',
        component: QuizArea,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/subs',
        name: 'ShowcaseSubs',
        component: ShowcaseSubs,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/subcontrol',
        name: 'SubControlPanel',
        component: SubControlPanel,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    },
    {
        path: '/quiz',
        name: 'QuizIndex',
        component: QuizIndex,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/quizdone',
        name: 'QuizCompleted',
        component: QuizCompleted,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/createquiz',
        name: 'CreateQuiz',
        component: CreateQuiz,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/updatequiz',
        name: 'UpdateQuiz',
        component: UpdateQuiz,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/showquizended',
        name: 'ShowQuizCompleted',
        component: ShowQuizCompleted,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    {
        path: '/showquizinprog',
        name: 'ShowQuizInProgress',
        component: ShowQuizInProgress,
        props: true,
        beforeEnter: checkIfUserIsLoggedAndGetActiveSubs
    },
    //ROTTE ESCLUSIVAMENTE USER
    {
        path: '/userCourseChoice',
        name: 'UserCourseChoice',
        component: UserCourseChoice,
        beforeEnter: checkIfUserIsLoggedAndPWDChanged
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeEach((to, from, next) => {
    const token = window.localStorage.getItem('x-access-token');
    
    if (to.fullPath === '/dashboard') {
        if (!token) {
            next('/');
            return;
        }
    }
    if (to.fullPath === '/login' || to.fullPath === '/register') {
        if (token && store.state.user.role === 'user') {
            if(store.state.user['has_active_sub']) {
                next('/userResults');
            } else {
                next('/courses');
            }
            return;
        } else if (token && store.state.user.role === 'admin') {
            next('/dashboard');
            return;
        } 
    }
    next();
});

export default router

function checkIfUserIsLogged(to, from, next) {
    // controlliamo se l'utente è loggato
    // se loggato non facciamo nulla
    if(store.state.logged) {
        next();
    } else {
        // se non loggato lo mandiamo alla homepage
        console.log('utente non loggato! Non può visualizzare questa pagina');
        next('/');
    }
}

function checkIfUserIsLoggedAsAdmin(to, from, next) {
    if(store.state.logged) {
        if(store.state.user.role === 'admin') { //FIXME: non è sicuro controllare solo lo stored state dal vuex perche è modificabile da client
            next();
        } else {
            next('/courses');
        }
    } else {
        // se non loggato lo mandiamo alla homepage
        console.log('utente non loggato! Non può visualizzare questa pagina');
        next('/');
    }
}

function checkIfUserIsLoggedAndPWDChanged(to, from, next) {
    if(store.state.logged) {
        if(store.state.user.role === 'admin') {
            next();
        } else if(store.state.user.role === 'user') {
            if(store.state.user['force_password_change'] === 1) {
                next();
            } else {
                console.log('utente non ha ancora camobiato la password');
                next('/changepwd')
            }
        }
    } else {
        // se non loggato lo mandiamo alla homepage
        console.log('utente non loggato! Non può visualizzare questa pagina');
        next('/');
    }
}

function checkIfUserIsLoggedPWDChangedAndProfileComplete(to, from, next) {
    if(store.state.logged) {
        if(store.state.user.role === 'admin') {
            next();
        } else if(store.state.user.role === 'user') {
            if(store.state.user['force_password_change'] === 1) {
                if(store.state.user.readonly === 1) {
                    next();
                } else if(store.state.userProfileComplete) {
                    next();
                } else {
                    console.log('utente non ha completato i dati del suo profilo');
                    next('/userProfile');
                }
            } else {
                console.log('utente non ha ancora camobiato la password');
                next('/changepwd')
            }
        }
    } else {
        // se non loggato lo mandiamo alla homepage
        console.log('utente non loggato! Non può visualizzare questa pagina');
        next('/');
    }
}

function checkIfUserIsLoggedAndGetActiveSubs(to, from, next) {
    if(store.state.logged) {
        if(store.state.user.role === 'admin') {
            next();
        } else if(store.state.user.role === 'user') {
            if(store.state.areThereUserActiveSubs) {
                next()
            } else {
                console.log('utente non possiede sub attive');
                next('/courses');
            }
        }
    } else {
        // se non loggato lo mandiamo alla homepage
        console.log('utente non loggato! Non può visualizzare questa pagina');
        next('/');
    }
}