<template>
    <div v-if="!success">
    </div>
    <div v-else>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            success: '',
            message: '',
            // utente già registrato
            info: '',
            htmlInfo: '', 
            //token scaduto
            htmlError: `Devi effettuare nuovamente la registrazione per poter continuare!<br>Cerca di controllare subito la tua mail oppure se non puoi farlo,<br>effettua la procedura quando hai possibilità di accedere alla tua casella di posta elettronica!`
        }
    },
    methods: {
        confirmRegistration(tkn) {
            //console.log(tkn);
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/auth/confirmRegister', { token: tkn })
            .then((res) => {
                console.log(res.data);
                this.success = res.data.success;
                this.message = res.data.message;
                if(res.data.info) {
                    this.info = res.data.info;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.$swal({
                        title: this.message,
                        html: `Stai per essere reindirizzato alla pagina dove effettuare il login.<br>Controlla la tua mail per scoprire le tue credenziali.<br>Se non puoi effettuare subito l'accesso, puoi interrompere il redirect usando il pulsante sottostante.<br>Torna sul sito una volta viste le tue credenziali, per effettuare il login!`,
                        position: 'center',
                        width: '700px',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Homepage',
                        timer: 15000,
                        timerProgressBar: true,
                        icon: 'success'
                    }).then((result) => {
                        if(!result.value) {
                            this.$router.push('/');
                        } else {
                            this.$router.push('/login');
                        }
                    });
                } else {
                    if(this.info === 'info') {
                        // siamo nel caso dell'utente già registrato
                        this.failMessage(this.message, this.htmlInfo, 'Login', '/login');
                    } else {
                        // caso del token scaduto
                        this.failMessage(this.message, this.htmlError, 'Registrati', '/register');
                    }
                    
                }
                console.log('chiamata terminata da fn confirmRegister');
            });
        },
        failMessage(msg, customMex, confBtnTxt, redirect) {
            this.$swal({
                title: msg,
                html: customMex,
                position: 'center',
                width: '700px',
                confirmButtonText: confBtnTxt,
                showCancelButton: true,
                cancelButtonText: 'Homepage',
                icon: 'warning'
            }).then((result) => {
                if(result.value) {
                    this.$router.push(redirect);
                } else {
                    this.$router.push('/');
                }
            }); 
        }
    },
    mounted() {
        this.confirmRegistration(this.$route.query.CT);
    }
}
</script>

<style>

</style>
