<template>
    <div>
        <h1 class="bgBlue mb-3">Dettaglio Utente</h1>
        <router-link to='/users' class="btn btnLightBlue mb-3">Torna alla lista Utenti</router-link>
        <div v-if="message" class="text-danger">
            {{ message }}
        </div>
        <div v-else>
            <Card style="width: 500px; margin:auto;">
                <template #header>
                </template>
                <template #title>
                    <span class="my_txt_cap px-1"><b>{{ user.firstname }}</b></span>
                    <span class="my_txt_cap px-1"><b>{{ user.lastname }}</b></span>
                </template>
                <template #content>
                    <h5 v-if="user.role === 'user'" class="card-title">Ruolo: Utente</h5>
                    <p class="card-text"></p>
                    <span v-if="user.status === 0">Stato: <b>Non Attivo</b></span>
                </template>
                <template #footer>
                    <router-link :to="{ name: 'EditUser', params: { 'userId': user.id } }" class="btn btnLightBlue">MODIFICA</router-link>
                </template>
            </Card>
            <div v-if="subsList.length > 0" class="d-flex flex-wrap justify-content-around mt-5">
                <Card v-for="sub of subsList" :key="sub.sub_id" style="width: 500px;" class="mt-4">
                    <template #header>
                        <span class="my_txt_cap px-1"><b>Sottoscrizione al percorso: {{ sub.courseName }}</b></span>
                    </template>
                    <template #title>
                        <h5>Edizione #{{ sub.editionNum }}</h5>
                    </template>
                    <template #content>
                        <p class="card-text">Stato pagamento: {{ sub.paymentProgress }}</p>
                        <p class="card-text">Stato sottoscrizione: {{ sub.sub_state }}</p>
                    </template>
                    <template #footer>
                        <!-- <router-link :to="{ name: 'EditUser', params: { 'userId': user.id } }" class="btn btn-primary">MODIFICA</router-link>  -->    
                    </template>
                </Card>
            </div>
        </div>   
    </div>
</template>

<script>
//import { mapState } from 'vuex';
import axios from 'axios'


export default {
    props: ['id'],
    data() {
        return {
            token: '',
            user: [],
            subsList: [],
            message: ''
        }
    },
    computed: {
        //...mapState(['user'])
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        //console.log(this.token);
        // devo fare la chiamata per ottenere la lista degli utenti
        axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userDetails', { params: {'id': this.id} , headers: { 'x-access-token': this.token  } })
        .then((res) => {
            console.log(res.data);
            if(res.data.success) { //true
                if(res.headers['x-token-refresh']) {
                    //refresho il token
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    // quindi salvo i dati dell'utente
                    this.user = res.data.user;
                    this.subsList = res.data.userSubsList;
                } else { // il token non è ancora stato ricalcolato
                    // salvo solo i dati dell'utente
                    this.user = res.data.user;
                    this.subsList = res.data.userSubsList;
                }
            } else { // res.data.success == false
                if(res.headers['x-token-refresh']) {
                    //refresho il token
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    // faccio vedere solo il messaggio
                    this.message = res.data.message;
                } else { // il token non è ancora stato ricalcolato
                    // faccio vedere solo il messaggio
                    this.message = res.data.message;
                }
            }
        })
        .catch((err) => {
            console.error(err.toString());
        })
        .finally(() => {
            console.log('chiamata terminata');
        });
    }
}
</script>

<style>
.my_txt_cap {
    text-transform: capitalize;
}
</style>
