<template>
    <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-10 lg:w-10 mx-auto my-6">
        <h1 class="text-center bgBlue mb-3">Crea un nuovo corso</h1>
        <form @submit.prevent="createCourse(!v$.$invalid)" style="width: 500px; margin:auto;" class="grid p-fluid">
            <div class="field col-12 mb-4">
                <label for="courseName" :class="{'p-error':v$.form.courseName.$invalid && submitted}" class="mr-3">NOME CORSO * :</label>
                <InputText id="courseName" v-model="v$.form.courseName.$model" :class="{'p-invalid':v$.form.courseName.$invalid && submitted}" placeholder="Es. Promotori Finanza"/>
                <div v-if="(v$.form.courseName.$invalid && submitted)" class="p-error">{{v$.form.courseName.required.$message.replace('Value', 'NOME CORSO')}}</div>
            </div>
            <div class="field col-12 mb-4">
                <label for="horizontal" :class="{'p-error':v$.form.courseName.$invalid && submitted}" class="mr-3">NUMERO EDIZIONE * :</label>
                <InputNumber id="horizontal" v-model="v$.form.editionNum.$model" :class="{'p-invalid':v$.form.editionNum.$invalid && submitted}" showButtons buttonLayout="horizontal" :step="1" :min="1"
                    decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" placeholder="Es. 1" />
                <div v-if="(v$.form.editionNum.$invalid && submitted)" class="p-error">{{v$.form.editionNum.required.$message.replace('Value', 'NUMERO EDIZIONE')}}</div>
            </div>
            <div class="field col-6 mb-4">
                <Button label="LISTA CORSI" @click="goToCourses" class="btnGrey mr-3 mb-2 sm:col-12 md:col-5" />
            </div>
            <div class="field col-6 mb-4">
                <Button label="CREA" class="btnLightBlue mb-2 sm:col-12 md:col-5" @click="createCourse(!v$.invalid)" />
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios'
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            submitted: false,
            form: {
                courseName: null,
                editionNum: null
            },
            token: '',
            getValues: {
                success: '',
                message: ''
            }
        }
    },
    validations() {
        return {
            form: {
                courseName: {
                    required: helpers.withMessage('Il campo Nome Corso è obbligatorio', required)
                },
                editionNum: {
                    required: helpers.withMessage('Il campo Numero Edizione è obbligatorio', required)
                }
            }
        }
    },
    methods: {
        createCourse() {
            if(!this.form.courseName || !this.form.editionNum) {
                this.submitted = true;
                return;
            } else {            
                axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/addcourse', this.form, { headers: { 'x-access-token': this.token } })
                .then((res) => {
                    console.log(res);
                    if(res.data.success) {
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);    
                        }
                        this.getValues.success = res.data.success;
                        this.getValues.message = res.data.message;
                    } else {
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);    
                        }
                        this.getValues.success = res.data.success;
                        this.getValues.message = res.data.message;
                    }
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    this.token = this.$store.state['x-access-token'];
                    if(this.getValues.success) {
                        setTimeout(() => {
                            this.$router.push("/courses");
                        }, 1000);
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            title: this.getValues.message,
                            icon: 'success'
                        });
                    } else {
                        this.$swal({
                            toast: true,
                            position: 'center',
                            showConfirmButton: true,
                            timer: 5000,
                            title: this.getValues.message,
                            icon: 'error'
                        });
                    }
                    console.log('chiamata terminata');
                });
            }    
        },
        goToCourses() {
            this.$router.push('/courses');
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        console.log(this.token);
    }

}
</script>

<style>

</style>
