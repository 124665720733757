<template>
    <div class="grid w-screen h-screen">
        <div class="col-12">
            <h1>Sezione a pagamento</h1>
        </div>
        <div class="col-12">
            <p>Questa pagina mostra la vetrina del corso con ID edizione #{{ idTableEditions }}</p>
        </div>
        <div class="col-12">
            <p>NOME DEL CORSO: {{ course.courseName }}</p>
        </div>
        <div class="col-12">
            <p>EDIZIONE NUMERO: {{ course.editionNum }}</p>
        </div>
        <div class="col-12">
            <router-link to="/quiz" class="btn btn-success col-4">
                AREA QUIZ
            </router-link>
        </div>
        <footer class="col-12">
            <span>TERMINE SOTTOSCRIZIONE: <strong>{{ course.end_date }}</strong></span>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            token: '',
            idTableEditions: null,
            course: {}
        }
    },
    methods: {
        findCourse(id) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/courseByEdtsId', { params: { 'id': id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                // va impostato il ragionamento del refresh token epr ora viene omesso
                this.course = res.data.course;
            })
            .catch((err) => {})
            .finally(() => {});
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.idTableEditions = this.$store.state.selEdtId;

        this.findCourse(this.idTableEditions);
    }

}
</script>

<style scoped>
footer {
    padding: 20px 0;
    background-color: lightskyblue;
}
</style>
