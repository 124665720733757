<template>
    <h1 class="bgBlue">Domande</h1>
    <div v-if="loading">
        <ProgressSpinner />
    </div>
    <div v-else-if="getValues.success">
        <TabView lazy>
            <TabPanel header="DOMANDE OCF">
                <Toolbar>
                    <template #start>
                        <Button label="CREA STRUTTURA DEL QUIZ" icon="pi pi-plus" class="btnLightBlue mr-2" @click="goToCreateQuiz"/>
                        <Button label="AGGIUNGI DOMANDA OCF" icon="pi pi-plus" class="btnLightRed mr-2" @click="goToAddQuestion(qEFPA=false)"/>
                    </template>
                    <template #end>
                        <form @submit.prevent="getQuestions()" class="filterBox d-flex justify-content-around align-items-center">
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per domanda" v-model="form.question">
                            </div>
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per contenuto" v-model="form.content">
                            </div>
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per argomento" v-model="form.subject">
                            </div>
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per materia" v-model="form.matter">
                            </div>
                            <div class="form-group mr-3">
                                <button class="btn btn-secondary">FILTRA</button>
                            </div>
                        </form>
                    </template>
                </Toolbar>
                <DataTable :value="questions" v-model:expandedRows="expandedRows" dataKey="id"
                    responsiveLayout="scroll" class="p-datatable">
                    <template #header>
                        <div class="flex justify-content-center align-items-center">
                            <div class="mr-2">Risultato della ricerca: </div>
                            <div class="mr-2"><strong>{{totalQuestions}}</strong> domande, di cui <strong>{{inactiveQuestions}}</strong> non attive.</div>
                            <!-- <div class="mr-4"><strong>(valore)</strong> non attive.</div>
                            <div>Ultimo aggiornamento: <strong>(edizione)</strong></div> -->
                        </div>
                    </template>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="id" header="Qid"></Column>
                    <Column field="qScope" header="ambito"></Column>
                    <Column field="edition" header="edizione"></Column>
                    <Column field="content_code" header="codice contenuto"></Column>
                    <Column field="subject_code" header="codice argomento"></Column>
                    <Column field="matter_code" header="codice materia"></Column>
                    <Column field="weight" header="livello"></Column>
                    <Column>
                        <template #body="slotProps">
                            <Button label="EDIT" class="btnGreen" @click="goToEditQuestion(slotProps.data.id, qEFPA=false)"/>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="orders-subtable">
                            <h5>Dettaglio domanda ID #{{slotProps.data.id}}</h5>
                            <div>CONTENUTO => <strong>{{slotProps.data.content}}</strong></div>
                            <div>ARGOMENTO => <strong>{{slotProps.data.subject}}</strong></div>
                            <div>MATERIA => <strong>{{slotProps.data.matter}}</strong></div>
                            <div>DOMANDA => <strong>{{slotProps.data.question}}</strong></div>
                            <div>RISP A => <strong>{{slotProps.data.rispA}}</strong></div>
                            <div>RISP B => <strong>{{slotProps.data.rispB}}</strong></div>
                            <div>RISP C => <strong>{{slotProps.data.rispC}}</strong></div>
                            <div>RISP D => <strong>{{slotProps.data.rispD}}</strong></div>
                            <div>RISPOSTA CORRETTA => <strong>{{slotProps.data.response}}</strong></div>
                        </div>
                    </template>
                </DataTable>
            
                <div v-if="pages !== 1" class="myPaginator">
                    <div v-if="previous.page" class="arrowBox" @click="getQuestions(parseInt(previous.page), parseInt(limit))"><i class="pi pi-angle-left"></i></div>
                    <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
                    <div class="pagesBox">
                        <span>PAGINA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
                    </div>
                    <div v-if="next.page" class="arrowBox" @click="getQuestions(parseInt(next.page), parseInt(limit))"><i class="pi pi-angle-right"></i></div>
                    <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
                </div>
            </TabPanel>
            <!-- sezione tabella domande EFPA (nuove) -->
            <TabPanel header="DOMANDE EFPA"> <Toolbar>
                    <template #start>
                        <Button label="CREA STRUTTURA DEL QUIZ" icon="pi pi-plus" class="btnLightBlue mr-2" @click="goToCreateQuiz"/>
                        <Button label="AGGIUNGI DOMANDA EFPA" icon="pi pi-plus" class="btnLightRed mr-2" @click="goToAddQuestion(qEFPA=true)"/>
                    </template>
                    <template #end>
                        <form @submit.prevent="getQuestionsEFPA()" class="filterBox d-flex justify-content-around align-items-center">
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per domanda" v-model="formNew.question">
                            </div>
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per tipologia" v-model="formNew.question_typology">
                            </div>
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per argomento" v-model="formNew.new_courses_subject">
                            </div>
                            <div class="form-group mr-3">
                                <input type="text" class="form-control" placeholder="filtra per materia" v-model="formNew.new_courses_matter">
                            </div>
                            <div class="form-group mr-3">
                                <button class="btn btn-secondary">FILTRA</button>  <!-- FIXME: persist selected tabpanel after filtering -->
                            </div>
                        </form>
                    </template>
                </Toolbar>
                <DataTable :value="questionsEFPA" v-model:expandedRows="expandedRows" dataKey="id"
                    responsiveLayout="scroll" class="p-datatable">
                    <template #header>
                        <div class="flex justify-content-center align-items-center">
                            <div class="mr-2">Risultato della ricerca: </div>
                            <div class="mr-2"><strong>{{totalQuestionsEFPA}}</strong> domande, di cui <strong>{{inactiveQuestionsEFPA}}</strong> non attive.</div>
                        </div>
                    </template>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="id" header="Qid"></Column>
                    <Column field="qScope" header="ambito"></Column>
                    <Column field="question_typology" header="tipologia"></Column>
                    <Column field="new_courses_matter" header="codice materia"></Column>
                    <Column field="new_courses_subject" header="codice argomento"></Column>
                    <Column>
                        <template #body="slotProps"> 
                            <Button label="EDIT" class="btnGreen" @click="goToEditQuestion(slotProps.data.id, qEFPA=true)"/> 
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="orders-subtable">
                            <h5>Dettaglio domanda ID #{{slotProps.data.id}}</h5>
                            <div>TIPOLOGIA => <strong>{{slotProps.data.question_typology}}</strong></div>
                            <div>ARGOMENTO => <strong>{{slotProps.data.new_courses_subject}}</strong></div>
                            <div>MATERIA => <strong>{{slotProps.data.new_courses_matter}}</strong></div>
                            <div>DOMANDA => <strong>{{slotProps.data.question}}</strong></div>
                            <div>RISP A => <strong>{{slotProps.data.rispA}}</strong></div>
                            <div>RISP B => <strong>{{slotProps.data.rispB}}</strong></div>
                            <div>RISP C => <strong>{{slotProps.data.rispC}}</strong></div>
                            <div>RISP D => <strong>{{slotProps.data.rispD}}</strong></div>
                            <div>RISPOSTA CORRETTA => <strong>{{slotProps.data.response_id}}</strong></div>
                        </div>
                    </template>
                </DataTable>
            
                <div v-if="pages !== 1" class="myPaginator">
                    <div v-if="previous.page" class="arrowBox" @click="getQuestionsEFPA(parseInt(previous.page), parseInt(limit))"><i class="pi pi-angle-left"></i></div>
                    <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
                    <div class="pagesBox">
                        <span>PAGINA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
                    </div>
                    <div v-if="next.page" class="arrowBox" @click="getQuestionsEFPA(parseInt(next.page), parseInt(limit))"><i class="pi pi-angle-right"></i></div>
                    <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
                </div>
            </TabPanel>
        </TabView>
    </div>
    <div v-else>
        <h3>{{ getValues.message }}</h3>
    </div>    
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            token: '',
            questions: [],
            questionsEFPA: [],
            getValues: {
                success: '',
                message: ''
            },
            form: {
                question: '',
                content: '',
                subject: '',
                matter: ''
            },
            formNew: {
                question: '',
                question_typology: '',
                new_courses_subject: '',
                new_courses_matter: ''
            },
            currentPage: 1,
            page: 1,
            pages: '',
            totalQuestions: '',
            totalQuestionsEFPA: '',
            inactiveQuestions: '',
            inactiveQuestionsEFPA: '',
            limit: 25,
            previous: {
                page: '',
                limit: ''
            },
            next: {
                page: '',
                limit: ''
            },
            expandedRows: [],
            loading: false
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['setQuestionID']),
        getQuestions(page = 1, limit = 25) {
            this.loading = true;
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/allQuestions', this.form,{ params: { page: page, limit: limit }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log(res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.questions = res.data.questions;
                    this.pages = res.data.pages;
                    this.totalQuestions = res.data.totalQuestions;
                    this.inactiveQuestions = res.data.inactiveQuestions;
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                    this.getValues.success = true;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = false;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                if(this.getValues.success) {
                    //console.log(this.questions);
                    console.log(this.getValues.message);
                } else {
                    console.log(this.getValues.message);
                }
                //console.log('chiamata terminata da fn getQuestions');
            });
        },
        getQuestionsEFPA(page = 1, limit = 25) {
            this.loading = true;
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/allQuestionsEFPA', this.formNew,{ params: { page: page, limit: limit }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log(res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.questionsEFPA = res.data.questionsEFPA;
                    this.pages = res.data.pages;
                    this.totalQuestionsEFPA = res.data.totalQuestionsEFPA;
                    this.inactiveQuestionsEFPA = res.data.inactiveQuestionsEFPA;
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                    this.getValues.success = true;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = false;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                if(this.getValues.success) {
                    //console.log(this.questions);
                    console.log(this.getValues.message);
                } else {
                    console.log(this.getValues.message);
                }
                //console.log('chiamata terminata da fn getQuestionsNEW');
            });
        },
        textTruncate(text) {
            if(text.length > 20) {
                return text.slice(0, 20) + " ...";
            } else {
                return text;
            }
        },
        goToEditQuestion(id, qEFPA) {
            this.setQuestionID(id);
            this.$router.push({ name: 'EditQ', params: { id: id, qEFPA: qEFPA } })
        },
        goToAddQuestion(qEFPA) {
            this.$router.push({ name: 'AddQ', params: { qEFPA: qEFPA } })
        },
        goToCreateQuiz() {
            this.$router.push('/createquiz');
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.getQuestions();
        this.getQuestionsEFPA();
        this.page = 1;
        this.currentPage = 1;
    }

}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.btnGreen {
    background: #017632 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGreen:enabled:hover {
    background: #017300 !important;
}
.btnLightBlue {
    background: #009bca !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnLightBlue:enabled:hover {
    background: #007cdb !important;
}
.btnLightRed {
    background: #ca0700 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnLightRed:enabled:hover {
    background: #db1600 !important;
}
.filterBox {
    width: 100%;
    height: 50px;
}
.myFooter {
    margin-top: 30px;
    margin-bottom: 70px
}
.p-datatable {
    margin-bottom: 0;
}
</style>
