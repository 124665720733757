<template>
    <div>
        <h1 class="mb-5">Pannello di controllo</h1>
        <form @submit.prevent="updateUsr" style="width: 500px; margin:auto;">
            <h5>Sezione modifica dati utente</h5>
            <div class="form-group mb-3">
                <select v-model="selectedRole" class="form-control">
                    <option v-for="role in roleOptions" :key="role.role" :value="role.value">{{ role.role }}</option>
                </select>
            </div>
            <div class="form-group mb-3">
                <select v-model="selectedStatus" class="form-control">
                    <option v-for="status in statusOptions" :key="status.status" :value="status.value">{{ status.status }}</option>
                </select>
            </div>
           <div class="form-group mb-3" v-if="user.sub_id">
               <h5>Sezione modifica stato sottoscrizione</h5>
                <div class="form-group mb-3">
                    <select v-model="selectedSubState" class="form-control">
                        <option v-for="subState in subStateOptions" :key="subState.status" :value="subState.value">{{ subState.status }}</option>
                    </select>
                </div>
                <div class="form-group mb-3">
                    <select v-model="selectedPyStatus" class="form-control">
                        <option v-for="pay in paymentOptions" :key="pay.status" :value="pay.value">{{ pay.status }}</option>
                    </select>
                </div>
           </div>
            <router-link :to="{ name: 'UserDetails', params: { 'userId': user.id } }" class="btn btn-info mx-2">Torna al dettaglio</router-link>
            <button type="submit" class="btn btn-primary mx-2">Aggiorna</button>
        </form>
        <div v-if="getValues.success === true" class="alert alert-success alert-dismissible fade show mt-5" role="alert" style="width: 500px; margin: auto;">
            <strong>{{ getValues.message }}</strong> 
        </div>
        <div v-if="getValues.success === false" class="alert alert-danger alert-dismissible fade show mt-5" role="alert" style="width: 500px; margin: auto;">
            <strong>{{ getValues.message }}</strong> 
        </div>
    </div>
</template>

<script>
//import { mapState } from 'vuex';
import axios from 'axios'


export default {
    props: ['id'],
    data() {
        return {
            user: [],
            selectedRole: '',
            roleOptions: [
                { role: 'admin' , value: 'admin' },
                { role: 'user' , value: 'user' }
            ],
            selectedStatus: '',
            statusOptions: [
                { status: 'Non Attivo' , value: 0 },
                { status: 'Attivo' , value: 1 }
            ],
            selectedPyStatus: '',
            paymentOptions: [
                { status: 'in attesa' , value: 'in attesa' },
                { status: 'pagato' , value: 'pagato' }
            ],
            selectedSubState: '',
            subStateOptions: [
                { status: 'non iniziata' , value: 'non iniziata' },
                { status: 'sospesa' , value: 'sospesa' },
                { status: 'attiva' , value: 'attiva' },
                { status: 'terminata' , value: 'terminata' }
            ],
            token: '',
            getValues: {
                success: '',
                message: ''
            }
        }
    },
    computed: {
        //...mapState(['user'])
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        //console.log(this.token);
        // devo fare la chiamata per ottenere la lista degli utenti
        axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userDetails', { params: {'id': this.id} , headers: { 'x-access-token': this.token  } })
        .then((res) => {
            if(res.data.success) { //true
                if(res.headers['x-token-refresh']) {
                    //refresho il token
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    // quindi salvo i dati dell'utente
                    console.log(res.data.user);
                    this.user = res.data.user;
                    this.selectedStatus = res.data.user.status;
                    this.selectedRole = res.data.user.role;
                    this.selectedPyStatus = res.data.user.paymentProgress;
                    this.selectedSubState = res.data.user['sub_state'];
                } else { // il token non è ancora stato ricalcolato
                    // salvo solo i dati dell'utente
                    this.user = res.data.user;
                    console.log(res.data.user);
                    this.selectedStatus = res.data.user.status;
                    this.selectedRole = res.data.user.role;
                    this.selectedPyStatus = res.data.user.paymentProgress;
                    this.selectedSubState = res.data.user['sub_state'];
                }
            } else { // res.data.success == false
                if(res.headers['x-token-refresh']) {
                    //refresho il token
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    // faccio vedere solo il messaggio
                    this.message = res.data.message;
                    //this.selectedStatus = res.data.user.status;
                    //this.selectedRole = res.data.user.role;
                } else { // il token non è ancora stato ricalcolato
                    // faccio vedere solo il messaggio
                    this.message = res.data.message;
                    //this.selectedStatus = res.data.user.status;
                    //this.selectedRole = res.data.user.role;
                }
            }
        })
        .catch((err) => {
            console.error(err.toString());
        })
        .finally(() => {
            console.log('chiamata terminata');
        });
    },
    methods: {
        updateUsr() {
            axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/userSubUpdt', {'role': this.selectedRole, 'status': this.selectedStatus, 'paymentProgress': this.selectedPyStatus, 'sub_state': this.selectedSubState, 'days_duration': this.user['days_duration'], 'id': this.id} , {headers: { 'x-access-token': this.token  }} )
            .then((res) => {
                console.log(res);
                if(res.data.success) { //true
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;
                } else {// false, ci sono stati errori
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log(this.getValues);
                if(this.getValues.success) {
                    setTimeout(() => {
                        this.$router.push(`/user/${this.id}`);
                    }, 3000);
                }
                console.log('chiamata terminata');
            });
        }
    }
}
</script>

<style>
.my_txt_cap {
    text-transform: capitalize;
}
</style>
