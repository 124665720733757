<template>
    <div v-if="!success">
    </div>
    <div v-else>
    </div>
    <!-- <div v-if="!success">
        <p><strong>{{ message }}</strong></p>
        <p><small><strong>Ripeti la procedura dall'inizio se vuoi recuperare la password</strong></small></p>
    </div>
    <div v-else>
        <p><strong>{{ mail }}</strong></p>
    </div>
    <Toast /> -->
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            success: '',
            message: '',
        }
    },
    methods: {
        checkForgTkn(tkn) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/auth/confForgotPwd', { token: tkn })
            .then((res) => {
                console.log(res.data);
                this.success = res.data.success;
                this.message = res.data.message;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.$swal({
                        title: this.message,
                        html: `Stai per essere reindirizzato alla pagina dove effettuare il login.<br>Controlla la tua mail per scoprire la tua nuova password.<br>Se non puoi effettuare subito l'accesso, puoi interrompere il redirect usando il pulsante sottostante.<br>Torna sul sito una volta viste le tue credenziali, per effettuare il login!`,
                        position: 'center',
                        width: '700px',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Homepage',
                        timer: 15000,
                        timerProgressBar: true,
                        icon: 'success'
                    }).then((result) => {
                        if(!result.value) {
                            this.$router.push('/');
                        } else {
                            this.$router.push('/login');
                        }
                    });
                } else {
                    // token scaduto
                    this.$swal({
                        title: this.message,
                        html: `Ripeti la procedura per il recupero della password, oppure torna alla home!`,
                        position: 'center',
                        width: '700px',
                        confirmButtonText: 'Recupera Password',
                        showCancelButton: true,
                        cancelButtonText: 'Homepage',
                        icon: 'warning'
                    }).then((result) => {
                        if(result.value) {
                            this.$router.push('/forgotPwd');
                        } else {
                            this.$router.push('/');
                        }
                    }); 
                }
                console.log('chiamata terminata da fn checkForgTkn');
            });
        }
    },
    mounted() {
        this.checkForgTkn(this.$route.query.FT);
    }
}
</script>

<style>

</style>
