<template>
    <div v-if="loading">
        <ProgressSpinner />
    </div>
    <div v-else>
        <Dialog v-model:visible="display" :showHeader="false" :closable="false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
            <h3 class="text-center">{{ message }}</h3>
            <template #footer>
                <Button label="Dashboard" icon="pi pi-check" @click="goToDashboard" />
                <Button v-if="payStatus === 'unpaid'" @click="goToUserSubsIndex" label="Sottoscrizioni" icon="pi pi-sign-out"  />
            </template>
        </Dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            loading: true,
            token: '',
            userSubIDToActivate: '',
            subID: null,
            message: '',
            success: '',
            display: false,
            payStatus: '',
            paymentMethod: null,
            sub_days_duration: null,
            courseID: null
        }
    },
    methods: {
        ...mapActions(['changeUserHasActiveSub']),
        getPaymentSession(session_id) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getSession', { params: { session_id: session_id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.payment_status === 'paid') {
                    this.userSubIDToActivate = this.$route.params.us_id;
                    this.subID =  this.$route.params.sub_id;
                    this.sub_days_duration = this.$route.params.duration;
                    this.courseID = this.$route.params.courseId;
                    this.paymentMethod = 'cc';
                } else if(res.data.payment_status === 'unpaid') { 
                    this.message = `Pagamento interrotto o non andato a buon fine!\n
                    Se avete interrotto voi il pagamento, perchè volete apportare delle modifiche,\n
                    (come il metodo di pagamento o la durata della sottoscrizione),\n
                    nella pagina delle sottoscrizioni trovate l'opzione per eliminare la transazione, così da poterne creare una nuova!\n
                    Se il pagamento non è andato a buon fine per problemi relativi alla vostra carta, sempre nella pagina sottoscrizioni\n
                    potete usare il pulsante paga per riprovare ad effettuare il pagamento!\n
                    Grazie`;
                    this.display = true; 
                    this.payStatus = 'unpaid';
                } else {
                    // il pagamento non è andato a buon fine
                    this.message = 'Attenzione, il pagamento NON è andato a buon fine!!! Tornare alla Dashboard e verificare i dati inseriti!';
                    this.display = true;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.loading = false;
                console.log(this.userSubIDToActivate);
                if(this.userSubIDToActivate) {
                    console.log(this.userSubIDToActivate);
                    console.log('Adesso si deve chiamare la funzione che salva in db la sottoscrizione');
                    this.subToCourse(this.$store.state.user.id, this.subID);
                }
            });
        },
        subToCourse(uid, sid) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/subToCourse', { user_id: uid, sub_id: sid, sub_type: 'notTrial', paymentMethod: this.paymentMethod, days_duration: this.sub_days_duration, us_id: this.userSubIDToActivate, courseID: this.courseID },{ headers: { 'x-access-token': this.token } })
            .then((res) => {
                // console.log(res);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                console.log(res.data);
                //{ success: true, message: 'Sottoscrizione attivata con successo!' }
                if(res.data.success) {
                    this.success = true;
                    console.log(this.success);
                    this.message = res.data.message;
                } else {
                    this.success = false;
                    this.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.changeUserHasActiveSub(1);
                    this.loading = false;
                    this.display = true;
                } else {
                    this.loading = false;
                    this.display = true;
                }
                console.log('chiamata terminata subToCourse');
            });
        },
        goToDashboard() {
            if(this.$store.state.user['has_active_sub'] === 1) {
                this.$router.push('/userResults');
            } else {
                this.$router.push("/courses");
            }
        },
        goToUserSubsIndex() {
            this.$router.push('/userSubs');
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        console.log(this.$route);
        this.getPaymentSession(this.$route.params.session_id);
        console.log('VALORE has_active_sub');
        console.log(this.$store.state.user['has_active_sub']);
    }

}
</script>

<style>

</style>
