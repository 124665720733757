<template>
    <div class="grid">
        <div class="sm:col-12 md:col-10 md:m-auto p-fluid">
            <div class="card p-3">
                <form @submit.prevent="updateAdminPersonalData()">
                    <h1 class="mb-4">Pagina di modifica anagrafica</h1>
                    <div class="grid formgrid">
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="firstname">Nome</label>
                            <InputText type="text" id="firstname" :value="user.firstname" readonly />
                        </div>
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="lastname">Cognome</label>
                            <InputText type="text" id="lastname" :value="user.lastname" readonly />
                        </div>
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label v-if="user.role === 'user'" for="username">Codice Fiscale</label>
                            <label v-else for="username">Username</label>
                            <InputText type="text" id="username" :value="user.username" readonly />
                        </div>
                    </div>
                    <div class="grid formgrid justify-content-evenly">
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="email">Email (modificabile)</label>
                            <InputText type="text" id="email" v-model="user.email" />
                        </div>
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="mobile">Cellulare (modificabile)</label>
                            <InputMask id="mobile" v-model="user.mobile" mask="+99 9999999999" slotChar="+39 0000000000"/>
                        </div>
                    </div>
                    <div class="grid formgrid justify-content-center mt-2">
                        <div class="sm:col-6 sm:mb-2 lg:col-2 lg:mb-0">
                            <Button label="Dashboard" @click="goToDash" class="btnGrey" />
                        </div>
                        <div class="sm:col-6 sm:mb-2 lg:col-2 lg:mb-0">
                            <Button label="Salva" class="btnGreen" type="submit" />
                        </div>
                    </div>
                </form>    
            </div>
        </div>
        <Toast />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from 'axios';

export default {
    data() {
        return {
            token: '',
            success: '',
            message: ''
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        updateAdminPersonalData() {
            const obj = {
                userID: this.user.id,
                email: this.user.email,
                mobile: this.user.mobile
            }

            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/updatePD', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.success = res.data.success;
                this.message = res.data.message;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.$toast.add({ severity: 'success', summary: this.message, life: 1500 });
                    setTimeout(() => {
                        this.$router.push('/dashboard');
                    }, 1000);
                } else {
                    this.$toast.add({ severity: 'error', summary: this.message, life: 2000 });
                }
                console.log('chiamata terminata da fn updateAdminPersonalData');
            });   
        },
        goToDash() {
            this.$router.push('/dashboard');
        },
        getUserData(id) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userDetails', { params: { id: id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {

                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata da fn getUserData');
            });
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.getUserData(this.$store.state.user.id);
    }
}
</script>

<style>

</style>
