<template>
    <h1>Pagina di dettaglio del corso #{{ id }}</h1>
</template>

<script>
export default {
    props: ['id']

}
</script>

<style scoped>

</style>