<template>
    <div v-if="loading">
        <p class="py-5"><strong>Attendere...generazione recupero Tentativo in corso, ci vorrà qualche secondo</strong></p>
        <ProgressSpinner />
    </div>
    <div v-else>
        <div v-if="!success">
            <h1>{{message}}</h1>
        </div>
        <div v-else>
            <h1 class="bgBlue">In Svolgimento: {{ description }}</h1>
            <div class="bgBlue">
                <router-link to="/quiz" class="btn btnRed">Lista Quiz</router-link>
            </div>
            <div v-for="item in quiz" :key="item.id" class="p-card">
                <div class="questionBox">
                    <p class="mb-1"><strong>{{item.question}}</strong></p>
                </div>
                <div v-if="courseID > 1">
                    <p class="mb-1">Modulo: {{item.matter}}, Tipologia: {{item.questionTypology}}, corretta: {{item.points}} pt / penalità: -{{item.malus}} pt</p>
                </div>
                <div class="answerBox grid">
                    <div v-for="(option, index) in answers" :key="index" class="field-radiobutton col-12">
                        <RadioButton :id="option+index" name="user_answer" :value="option.value" v-model="item.user_answer" />
                        <label :for="option+index">{{ option.text }}</label>
                    </div>
                    <div v-if="next.page" class="field-radiobutton col-12 justify-content-center">
                        <Button v-if="!item.user_answer || btn === 'off'" label="SALVA" class="myBtnBg" disabled="disabled" />
                        <Button v-else-if="item.user_answer" label="SALVA" class="myBtnBg" @click="sendAnswer(parseInt(next.page), parseInt(next.limit))" />
                    </div>
                    <div v-else class="field-radiobutton flex-column col-12 justify-content-center">
                        <Button v-if="item.user_answer" label="SALVA" class="myBtnBg mb-3" @click="sendAnswer(parseInt(currentPage))" />
                        <Button v-else label="SALVA" class="myBtnBg mb-3" disabled="disabled" />
                        <Button v-if="ansLeft !== 0 && courseID === 1" label="TERMINA E INVIA" class="p-button-warning" @click="openPosition(position)" />
                        <Button v-else-if="ansLeft === 0 || courseID !== 1" label="TERMINA E INVIA" class="myBtnBg" @click="openPosition(position)" />
                        <!-- <Button v-else-if="courseID !== 1" label="TERMINA E INVIA" class="myBtnBg" @click="openPosition(position)" /> -->
                        <Dialog :header="header" v-model:visible="displayPosition" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :position="position" :modal="true" :closable="false">
                            <p class="m-0">{{ message }}</p>
                            <template #footer>
                                <Button label="TORNA AL QUIZ" icon="pi pi-times" @click="closePosition" class="p-button-text" />
                                <Button v-if="ansLeft !== 0 && courseID === 1" label="INVIA" icon="pi pi-send" class="p-button-text" disabled="disabled" />
                                <Button v-else-if="ansLeft === 0 || courseID !== 1" label="INVIA" icon="pi pi-send" class="p-button-text" @click="quizEnded" />
                            </template>
                        </Dialog>
                        <Dialog :header="header" v-model:visible="displayResult" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :position="position" :modal="true" :closable="false" class="dialogFail">
                            <p class="m-0">{{ customMex }}</p>
                            <template #footer>
                                <Button label="DASHBOARD" icon="pi pi-times" @click="closeResultDash" class="p-button-text" />
                                <Button label="COMPLETATI" icon="pi pi-sign-out" @click="closeResultCompletedQuiz" class="p-button-text" />
                            </template>
                        </Dialog>
                    </div>
                    <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}"/>
                </div>
            </div>
            <div class="myPaginator">
                <div v-if="previous.page" class="arrowBox" @click="switchQuestions(attemptID, this.reqStatus, parseInt(previous.page), parseInt(previous.limit))"><i class="pi pi-angle-left"></i></div>
                <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
                <div class="pagesBox">
                    <span>DOMANDA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
                </div>
                <div v-if="next.page" class="arrowBox" @click="switchQuestions(attemptID, this.reqStatus, parseInt(next.page), parseInt(next.limit))"><i class="pi pi-angle-right"></i></div>
                <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['quizID'],
    data() {
        return {
            loading: false,
            token: '',
            quizStructureID: null,
            courseID: null,
            userID: null,
            description: '',
            quizStructure: [],
            success: '',
            message: '',
            quiz: [],
            answers: [],
            currentPage: 1,
            page: 1,
            first: 0,
            totalRecords: null,
            next: {
                page: null,
                limit: ''
            },
            previous: {
                page: null,
                limit: ''
            },
            pages: null,
            ansLeft: null,
            displayPosition: false,
            displayResult: false,
            position: 'bottom',
            header: '',
            isLastPage: false,
            attemptID: null,
            quizStatus: '',
            threshold: null,
            userResult: null,
            customMex: '',
            reqStatus: '',
            quizResultSuccess: '',
            btn: 'on',
            
        }
    },
    methods: {
        getSingleQuizByUserID(userID) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/singlequizbyUID', { params: { id: this.$store.state.selQuizID, userID: userID }, headers:{ 'x-access-token': this.token } })
            .then((res) => {
                //console.log(res.data);
                if(res.data.success) {
                    this.success = res.data.success;
                    this.description = res.data.singlequiz.description;
                    this.quizStructure = res.data.singlequiz.quiz_structure;
                    this.threshold = res.data.singlequiz.threshold;
                    this.quizStructureID = res.data.singlequiz.qStructureID;
                    if(res.data.info) {
                        // caso del nuovo tentativo
                        this.reqStatus = 'new';
                        this.message = res.data.message;

                    } else {
                        // caso del tentativo in progress
                        this.reqStatus = 'in progress';
                        this.attemptID = res.data.singlequiz.attemptID;
                        this.message = res.data.message;
                    }
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
                
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.reqStatus === 'new') {
                    // chiamo la funzione primo accesso nuovo tentativo
                    this.firstAccessNewQuiz(this.quizStructureID, this.userID, this.reqStatus, this.courseID);
                } else if(this.reqStatus === 'in progress') {
                    // chiamo la funzione primo accesso ad un tentativo in corso
                    this.firstAccessQuizInProgress(this.quizStructureID, this.attemptID, this.reqStatus, this.courseID);
                } else {
                    // errore
                    console.log('Errore Generico');
                }
                //console.log('chiamata terminata da funzione get single quiz by userID');
            });
        },
        firstAccessQuizInProgress(qID, aID, reqStatus, courseID, limit = 1) {
            this.loading = true;
            this.answers = [];
            
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/firstAccessQuizInProgress', { params: { qStructureID: qID, attemptID: aID, qStructure: this.quizStructure, reqStatus: reqStatus, courseID: courseID, limit:limit}, headers: { 'x-access-token': this.token }})
            .then((res) => {
                //console.log(res.data);
                if(res.data.success) {
                    this.quiz = res.data.quizQuestion;
                    console.log("QUIZDATA:", this.quiz);
                    //console.log(res.data.quizQuestion[0].rispA);
                    this.answers.push(
                        { value: 1, text: res.data.quizQuestion[0].rispA},
                        { value: 2, text: res.data.quizQuestion[0].rispB},
                        { value: 3, text: res.data.quizQuestion[0].rispC},
                        { value: 4, text: res.data.quizQuestion[0].rispD}
                    );
                    this.totalRecords = res.data.totalQuizQuestions;
                    this.pages = res.data.pages;
                    this.ansLeft = res.data.ansLeft;
                    this.quizStatus = res.data.quizStatus;
                    if(res.data.previous && res.data.next) {
                        this.page = res.data.previous.page + 1;
                    } else if(res.data.previous && !res.data.next) {
                        this.page = res.data.previous.page + 1;
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    this.success = res.data.success;
                    this.message = res.data.message;
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
    
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                if(this.success) {
                    //console.log('valore attemptID in finally firstAccess', this.attemptID);
                    this.currentPage = this.page;
                    //console.log(this.currentPage);
                    this.answers = this.shuffleAnswers(this.answers);
                }
                //console.log('chiamata terminata fn first access quiz in progress');
            });
        },
        firstAccessNewQuiz(qID, uID, reqStatus, courseID, limit = 1) {
            this.loading = true;
            this.answers = [];

            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/createNewAttempt', { params: { qStructureID: qID, userID: uID, qStructure: this.quizStructure, reqStatus: reqStatus, courseID: courseID, limit:limit,}, headers: { 'x-access-token': this.token }})
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.quiz = res.data.quizQuestion;
                    //console.log(res.data.quizQuestion[0].rispA);
                    this.answers.push(
                        { value: 1, text: res.data.quizQuestion[0].rispA},
                        { value: 2, text: res.data.quizQuestion[0].rispB},
                        { value: 3, text: res.data.quizQuestion[0].rispC},
                        { value: 4, text: res.data.quizQuestion[0].rispD}
                    );
                    console.log(res.data.quizQuestion[0].attempt_id);
                    this.attemptID = res.data.quizQuestion[0].attempt_id;
                    this.totalRecords = res.data.totalQuizQuestions;
                    this.pages = res.data.pages;
                    this.ansLeft = res.data.ansLeft;
                    this.quizStatus = res.data.quizStatus;
                    if(res.data.previous && res.data.next) {
                        this.page = res.data.previous.page + 1;
                    } else if(res.data.previous && !res.data.next) {
                        this.page = res.data.previous.page + 1;
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    this.success = res.data.success;
                    this.message = res.data.message;
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
    
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                if(this.success) {
                    console.log('valore attemptID in finally firstAccess');
                    console.log(this.attemptID);
                    this.currentPage = this.page;
                    console.log(this.currentPage);
                    this.answers = this.shuffleAnswers(this.answers);
                }
                console.log('chiamata terminata fn firstAccessNewQuiz');
            });
        },
        switchQuestions(aID, reqStatus, page, limit = 1) {
            console.log('valore page');
            console.log(page);
            this.answers = [];
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/changePage', { params: { attemptID: aID, reqStatus: reqStatus, courseID: this.courseID, page: page, limit:limit}, headers: { 'x-access-token': this.token }})
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.quiz = res.data.quizQuestion;
                    //console.log(res.data.quizQuestion[0].rispA);
                    this.answers.push(
                        { value: 1, text: res.data.quizQuestion[0].rispA},
                        { value: 2, text: res.data.quizQuestion[0].rispB},
                        { value: 3, text: res.data.quizQuestion[0].rispC},
                        { value: 4, text: res.data.quizQuestion[0].rispD}
                    );
                    this.totalRecords = res.data.totalQuizQuestions;
                    this.pages = res.data.pages;
                    this.ansLeft = res.data.ansLeft;
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    this.success = res.data.success;
                    this.message = res.data.message;
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
    
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    /* console.log('ORDINE ORIGINALE');
                    this.answers.forEach(element => {
                        console.log(element.text);
                    }); */
                    console.log(this.currentPage);
                    this.answers = this.shuffleAnswers(this.answers);
                    /* console.log('MISCHIATO');
                    console.log(this.answers); */
                }
                console.log('chiamata terminata');
            });
        },
        sendAnswer(page, limit = 1) {
            this.btn = 'off';
            const data = {
                quiz_id: this.quizStructureID,
                qData: this.quiz,
                page,
                limit
            }
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/sendAnswer', data, { headers: { 'x-access-token': this.token }})
            .then((res) => {
                console.log(res.data);
                if(res.data.success === true) {
                    console.log('sono qui');
                    console.log(this.currentPage);
                    if(this.currentPage == this.totalRecords) {
                        this.isLastPage = true;
                    } else {
                        this.isLastPage = false;
                    }
                    this.ansLeft = res.data.ansLeft;
                    this.message =  res.data.message;
                    this.success = res.data.success;
                } else if(res.data.success === 'info') {
                    this.success = res.data.success;
                    this.message = res.data.message;
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success === true) {
                    this.$toast.add({severity:'success', summary: this.message, life: 800})
                    if(!this.isLastPage) {
                        setTimeout(() => {
                            console.log('valore next page in setTimout');
                            console.log(this.next.page);
                            this.btn = 'on';
                            this.switchQuestions(this.attemptID, this.reqStatus, this.next.page, this.next.limit);
                        }, 750);
                    } else {
                      this.btn = 'on';
                    }
                } else if(this.success === 'info') {
                    this.$toast.add({severity:'info', summary: this.success, detail: this.message, life: 3000})
                    this.btn = 'on';
                }

                console.log('chiamata terminata da fn sendAnswer');
            });
        },
        shuffleAnswers(array) {
            //console.log('INIZIO LOOP => expected values 4');
            //console.log(array.length);
            let result = [];

            while (array.length > 0) {
                let randomIndex = Math.floor(Math.random() * (array.length));
                //console.log(randomIndex);
                // trovo indice random
                // all inizio gli elementi saranno 4 perchè sono presenti tutte le opzioni
                // con il find trovo l'elemento
                const item = array.find((ele, index) => {
                    if(index === randomIndex) {
                        //console.log(ele);
                        return ele;
                    };
                });
                //console.log('valore elemento alla posizione random');
                //console.log(item);
                // push questo item nel nuovo array risultato
                result.push(item);
                // e lo rimuovo dall array originale
                array.splice(randomIndex, 1);
                //console.log('valore array length alla fine del ciclo');
                //console.log(array.length);
            }
            return result;
        },
        openPosition(position) {
            this.position = position;
            this.displayPosition = true;

            if(this.ansLeft != 0 && this.courseID === 1) {
                this.header = `ATTENZIONE, NON PUOI TERMINARE IL QUIZ!!!`
                if(this.ansLeft === 1) {
                    console.log(`C'è ancora ${this.ansLeft} domanda a cui è necessario rispondere, prima di poter procedere con l'invio del test`);
                    this.message = `C'è ancora ${this.ansLeft} domanda a cui è necessario rispondere, prima di poter procedere con l'invio del test`;
                } else {
                    console.log(`Ci sono ancora ${this.ansLeft} domande a cui è necessario rispondere, prima di poter procedere con l'invio del test`);
                    this.message = `Ci sono ancora ${this.ansLeft} domande a cui è necessario rispondere, prima di poter procedere con l'invio del test`;
                }
            } else if(this.ansLeft != 0 && this.courseID != 1) {
                console.log('SICURO DI VOLER TERMINARE IL QUIZ?\n Una volta terminato, NON sarà più possibile modificare le scelte fatte, ma sarà solo possibile vedere risultato e correzioni!');
                this.header = `ATTENZIONE, RISULTANO PRESENTI DOMANDE NON RISPOSTE. TERMINARE DEFINITIVAMENTE IL QUIZ?`;
                this.message = 'Una volta inviato, NON sarà più possibile modificare le scelte fatte!\nSarà solo possibile vedere il risultato e le correzioni! Le domande non risposte varranno 0 punti ai fini della correzione.';
            } else {
                console.log('SICURO DI VOLER TERMINARE IL QUIZ?\n Una volta terminato, NON sarà più possibile modificare le scelte fatte, ma sarà solo possibile vedere risultato e correzioni!');
                this.header = `SICURO DI VOLER TERMINARE DEFINITIVAMENTE IL QUIZ?`
                this.message = 'Una volta inviato, NON sarà più possibile modificare le scelte fatte!\nSarà solo possibile vedere il risultato e le correzioni!';
                
            }

        },
        closePosition() {
            this.displayPosition = false;
        },
        quizEnded() {
            let pWA = false;
            if(this.description.includes('Playlist domande errate [')) {
                pWA = true;
            }
            const data = {
                quiz_id: this.quizStructureID,
                attemptID: this.attemptID,
                pWA: pWA,
                courseID: this.courseID
            }
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/quizEnded', data, { headers:{ 'x-access-token': this.token }})
            .then((res) => {
                console.log(res.data);
                this.quizResultSuccess = res.data.success;
                this.message = res.data.message;
                this.threshold = res.data.threshold;
                this.userResult = res.data.userResult;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.quizResultSuccess) {
                    this.header = '';
                    this.closePosition();
                    this.openResult(this.position);
                } else {
                    this.header = '';
                    this.closePosition();
                    this.openResult(this.position);
                }
                console.log('chiamata terminata');
            });
        },
        openResult(position) {
            this.position = position;
            this.displayResult = true;
            
            if(this.quizResultSuccess) {
                this.header = `COMPLIMENTI, ${this.message}`;
                this.customMex = `Risultato ${this.userResult}%, soglia per il superamento del test ${this.threshold}%`;
            } else {
                this.header = `SPIACENTE, ${this.message}`;
                this.customMex = `Risultato ${this.userResult}%, soglia per il superamento del test ${this.threshold}%`;
            }

        },
        closeResultDash() {
            this.displayResult = false;
            this.$router.push('/userResults');
        },
        closeResultCompletedQuiz() {
            this.displayResult = false;
            this.$router.push('/quizdone');
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.userID = this.$store.state.user.id;
        //console.log("props:", this.quizID, this.courseID);
        this.courseID = this.$store.state.selCourseID;
        this.getSingleQuizByUserID(this.userID);
    }


}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.quizBtn {
    background-color: #ff0000;
    color: #ffffff;
}
.p-card {
    width: 100vw;
    margin: auto;
    padding: 0;
}
.questionBox {
    /* border: 1px solid black; */
    padding: 25px 10px;
    background-color: #f1f2f2;
}
.answerBox {
    background-color: #ffffff;
    padding: 30px 0 25px 30px;
    margin-right: 0;
    text-align: left;
    flex-grow: 1;
}
.wrongOption {
    color: #ff0000;
}
.p-dialog {
    background-color: #ff0000;
    color: white;
}
.myBtnBg {
    background-color: #017632;
    border: none;
    color: #eaeaea;
}
.p-button:enabled:hover {
    background-color: #037f36;
}
</style>
