<template>
    <div class="surface-card p-4 shadow-4 border-round sm:w-full lg:w-6 mx-auto my-6">
        <div class="text-center mb-4">
          <div class="text-900 text-3xl font-medium mb-3">Inserimento nuovo utente</div>
            <Button label="Lista Utenti" class="mt-2" @click="goToUsers" />
        </div>
        <form @submit.prevent="registerUser" class="p-fluid">
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText id="firstname" v-model="form.firstname" class="capitalize" />
                    <label for="firstname">Nome *</label>
                </div>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText id="lastname" v-model="form.lastname" class="capitalize" />
                    <label for="lastname">Cognome *</label>
                </div>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText id="username" v-model="form.username" class="uppercase" />
                    <label for="username">Codice Fiscale *</label>
                </div>
            </div>
            <div class="field mb-4">
                <div class="p-float-label p-input-icon-right">
                    <i class="pi pi-envelope" />
                    <InputText id="email" v-model="form.email" class="lowercase" />
                    <label for="email">Email *</label>
                </div>    
            </div>
            <Button type="submit" label="Inserisci" class="mt-2" />
        </form>
        <Toast />
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            token: '',
            form: {
                firstname: '',
                lastname: '',
                username: '',
                email: ''
            },
            message: '',
            failMex: '',
            success: ''
        }
    },
    methods: {
        registerUser() {
            this.form.firstname = this.form.firstname.toLowerCase();
            this.form.lastname = this.form.lastname.toLowerCase();
            this.form.username = this.form.username.toUpperCase();
            this.form.email = this.form.email.toLowerCase();

            axios.post(process.env.VUE_APP_API_URL + '/api/v1/auth/register', this.form)
            .then((res) => {
                console.log(res.data);
                this.success = res.data.success;
                if(res.data.success === false) {
                    this.failMex = res.data.message; 
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.message = 'Utente inserito con successo!';
                    this.$toast.add({ severity: 'success', summary: this.message, life: 2000 });
                    this.form.firstname = "";
                    this.form.lastname = "";
                    this.form.username = "";
                    this.form.email = "";
                } else {
                    this.$toast.add({ severity: 'error', summary: this.failMex, life: 3000 });
                }
                console.log('chiamata terminata da fn registerUser');
            });
        },
        goToUsers() {
            this.$router.push('/users');
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
    }
}
</script>

<style>

</style>
