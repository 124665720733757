<template>
    <div class="container">
        <form v-if="isqEFPA === 'false'" @submit.prevent="addQuestion(question)">
            <h1>AREA DI AGGIUNTA DOMANDA OCF</h1>
            <div class="form-group">
                <label>CONTENUTO</label>
                <select v-model="question.content_id" required>
                    <option v-for="item in contents" :value="item.id" :key="item.id">
                        {{ item.content }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>EDIZIONE</label>
                <input type="text" class="form-control" v-model="question.edition" placeholder="es. Gennaio 2023" required>
            </div>
            <div class="form-group">
                <label>DOMANDA</label>
                <textarea v-model="question.question" cols="30" rows="5" class="form-control" required></textarea>
            </div>
            <div class="form-group">
                <label>OPZIONE A</label>
                <input type="text" class="form-control" v-model="question.rispA" required>
            </div>
            <div class="form-group">
                <label>OPZIONE B</label>
                <input type="text" class="form-control" v-model="question.rispB" required>
            </div>
            <div class="form-group">
                <label>OPZIONE C</label>
                <input type="text" class="form-control" v-model="question.rispC" required>
            </div>
            <div class="form-group">
                <label>OPZIONE D</label>
                <input type="text" class="form-control" v-model="question.rispD" required>
            </div>
            <div class="form-group">
                <label>RISPOSTA CORRETTA</label>
                <input type="text" class="form-control" v-model="question.response" placeholder="inserire il testo della domanda corretta" required>
            </div>
            <div class="form-group">
                <label>PESO / LIVELLO (inserire "1" oppure "2")</label>
                <input type="text" class="form-control" v-model="question.weight" >
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="question.active" id="enableState" value=1 checked>
                <label class="form-check-label" for="enableState">ATTIVA</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="question.active" id="disableState" value=0 >
                <label class="form-check-label" for="disableState">NON ATTIVA</label>
            </div>
            <div class="form-group">
                <button class="btn btn-success">SALVA MODIFICHE</button>
            </div>
        </form>

        <form v-else @submit.prevent="addQuestionEFPA(questionEFPA)">
            <h1>AREA DI AGGIUNTA DOMANDA EFPA</h1>
            <div class="form-group">
                <label>AMBITO</label>
                <select v-model="questionEFPA.qScope" required>
                    <option v-for="item in qScopeEFPA" :value="item" :key="item">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>MATERIA</label>
                <select v-model="questionEFPA.new_courses_matter" @change="getSubjectsEFPA" required>
                    <option v-for="item in mattersEFPA" :value="item.id + ' ' + item.matter" :key="item.id">
                        {{ item.matter }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>ARGOMENTO</label>
                <select v-model="questionEFPA.new_courses_subject" @change="setSubjectId" required>
                    <option v-for="item in subjectsEFPA" :value="item.subject" :key="item.id">
                        {{ item.subject }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>TIPOLOGIA</label>
                <select v-model="questionEFPA.question_typology" @change="setQtypology" required>
                    <option v-for="item in qTypologyEFPA" :value="item" :key="item">
                        {{ item }} 
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>DOMANDA</label>
                <textarea v-model="questionEFPA.question" cols="30" rows="5" class="form-control" required></textarea>
            </div>
            <div class="form-group">
                <label>OPZIONE A</label>
                <input type="text" class="form-control" v-model="questionEFPA.rispA" required>
            </div>
            <div class="form-group">
                <label>OPZIONE B</label>
                <input type="text" class="form-control" v-model="questionEFPA.rispB" required>
            </div>
            <div class="form-group">
                <label>OPZIONE C</label>
                <input type="text" class="form-control" v-model="questionEFPA.rispC" required>
            </div>
            <div class="form-group">
                <label>OPZIONE D</label>
                <input type="text" class="form-control" v-model="questionEFPA.rispD" required>
            </div>
            <div class="form-group">
                <label>RISPOSTA CORRETTA</label>
                <input type="text" class="form-control" v-model="questionEFPA.response_id" placeholder="inserire il numero della domanda corretta" required>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="questionEFPA.active" id="enableState" value=1 checked>
                <label class="form-check-label" for="enableState">ATTIVA</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="questionEFPA.active" id="disableState" value=0 >
                <label class="form-check-label" for="disableState">NON ATTIVA</label>
            </div>
            <div class="form-group">
                <button class="btn btn-success">SALVA MODIFICHE</button>
            </div>
        </form>
        
    </div>
</template>

<script>
import axios from 'axios';
import crypto from 'crypto';

export default {
    data() {
        return {
            token: '',
            signature: '',   
            contents: {},
            question: {
                content_id: null,
                active: 1,
                weight: 1,
                signature: '',
            },
            getValues: {
                success: '',
                message: ''
            },
            getResult: {
                success: '',
                message: ''
            },
            isqEFPA: '',
            qScopeEFPA: ['EIP','EIP per CF','Da EIP a EFA'],  //sono 4? vedi tabella 'courses'
            qTypologyEFPA: ['Knowledge','Analysis','Application'],
            mattersEFPA: {},
            subjectsEFPA: {},
            questionEFPA: {
                qScope: '',
                new_courses_matter: '',
                new_courses_subject: '',
                new_courses_subject_id: null,
                question_typology: '',
                questionTypeId: null,
                active: 1,
                signature: '',
            }

        }
    },
    methods: {
        getContentsOCF(){
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getContentsList', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.contents = res.data.contents;
                    this.getValues.success = true;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = false;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log("tabella contents estratta correttamente", this.contents);
            });
        },
        addQuestion(obj) {
            this.createSignature(obj);
            this.question.signature = this.signature;
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/addQuestion', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {

                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.getResult.success = true;
                    this.getResult.message = res.data.message;
                } else {
                    this.getResult.success = false;
                    this.getResult.message = res.data.message;
                }

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.getResult.success) {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        title: this.getResult.message,
                        icon: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/questions');
                    }, 2000);
                } else {
                    this.$swal(this.getResult.message);
                }
                //console.log('chiamata terminata');
            });
        },
        getMattersEFPA(){
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getMatters', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.mattersEFPA = res.data.matters;
                    this.getValues.success = true;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = false;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log("tabella matters estratta correttamente", this.matters);
            });
        },
        getSubjectsEFPA(event){
            const selectedM = event.target.value.split(' ')[0];
            let array = [{matter_code: 'EFPA_M'+selectedM}];
            
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/getSubjects', {matter_code: array} ,{ headers: { 'x-access-token': this.token } })
            .then((res) => {
                
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.subjectsEFPA = res.data.subjects;
                    this.getValues.success = true;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = false;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log("tabella subjects estratta correttamente", this.subjectsEFPA);
            });
        },
        setSubjectId(){
            const selectedSubject = this.questionEFPA.new_courses_subject;
            const selectedItem = this.subjectsEFPA.find(item => item.subject === selectedSubject);
            this.questionEFPA.new_courses_subject_id = selectedItem.id;
        },
        setQtypology(event){
            const selectedIndex = event.target.selectedIndex;
            this.questionEFPA.questionTypeId = selectedIndex + 1;
        },
        addQuestionEFPA(obj) {
            this.createSignature(obj);
            this.questionEFPA.signature = this.signature;
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/addQuestionEFPA', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {

                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.getResult.success = true;
                    this.getResult.message = res.data.message;
                } else {
                    this.getResult.success = false;
                    this.getResult.message = res.data.message;
                }

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.getResult.success) {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        title: this.getResult.message,
                        icon: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/questions');
                    }, 2000);
                } else {
                    this.$swal(this.getResult.message);
                }
                //console.log('chiamata terminata');
            });
        },
        createSignature(obj){
            const secret = 'The lamb lies down on broadway';
            const hash = crypto.createHmac('sha256', secret);
            hash.update( obj.question.toString() );
            hash.update( obj.rispA.toString() );
            hash.update( obj.rispB.toString() );
            hash.update( obj.rispC.toString() );
            hash.update( obj.rispD.toString() );
            this.signature = hash.digest('hex').toString();
        }
    
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.isqEFPA = this.$route.params.qEFPA; //per verificare se ho cliccato EDIT nella tabella domande OCF o EFPA FIXME: se la pagina si ricarica si perde (salvarlo nel localstorage?)
        this.getContentsOCF(); //prelevo i valori dalla tabella 'contents' per popolare il dropdown
        this.getMattersEFPA(); //prelevo i valori dalla tabella 'new_courses_matters' per popolare il dropdown

    }
}
</script>

<style scoped>
div {
    margin-top: 30px;
}
div > label {
    margin-bottom: 15px;
}
div > button {
    margin-bottom: 50px;
}
</style>
