<template>
    <div v-if="loading">
        <ProgressSpinner />
    </div>
    <div v-else>
        <Toast />
        <div v-if="subs.length === 0">
            <div v-if="user.role === 'admin'">
                <h1 class="mt-5">NON CI SONO SOTTOSCRIZIONI DISPONIBILI</h1>
                <router-link to="/addsub" class="btn btnBlue">AGGIUNGI SOTTOSCRIZIONE</router-link>
            </div>
            <div v-else-if="user.role === 'user'">
                <h1 class="mt-5">{{ getValues.message }}</h1>
                <router-link to="/courses" class="btn btnBlue">TORNA AI CORSI</router-link>
            </div>
        </div>
        <div v-else>
            <div v-if="user.role === 'admin'">
                <h1 class="bgBlue">Lista Sottoscrizioni</h1>
                <Toolbar v-if="user.role === 'admin'">
                    <template #start>
                        <Button label="CREA SOTTOSCRIZIONE" icon="pi pi-plus" class="btnLightBlue mr-2" @click="toAddNewSub" />
                    </template>
                </Toolbar>
                <DataTable :value="subs" class="p-datatable" responsiveLayout="stack">
                    <Column field="courseName" header="CORSO"></Column>
                    <Column field="edtID" header="ID EDIZIONE"></Column>
                    <Column field="sub_type" header="TIPOLOGIA"></Column>
                    <Column field="price" header="COSTO in €"></Column>
                    <Column field="days_duration" header="DURATA in gg"></Column>
                    <Column field="comment" header="COMMENTO"></Column>
                    <Column>
                        <template #body="slotProps">
                            <InputSwitch v-if="slotProps.data.isActive" :modelValue="true" @click="enableDisableSub(slotProps.data.SUBid, 0)"/>
                            <InputSwitch v-else :modelValue="false" @click="enableDisableSub(slotProps.data.SUBid, 1)"/>
                        </template>
                    </Column>
                    <!-- <Column header="dettaglio sub">
                        <template #body>
                            <Button icon="pi pi-search" class="p-button-info"/>
                        </template>
                    </Column> -->
                </DataTable>
            </div>
            <div v-else-if="user.role === 'user'">
                <div v-if="getValues.success">
                    <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-10 lg:w-10 mx-auto my-6">
                        <h3 class="bgBlue py-2">SOTTOSCRIZIONI {{ selectedCourseName }}</h3>
                        <DataTable :value="subs" stripedRows responsiveLayout="stack">
                            <Column field="sub_type" header="SOTTOSCRIZIONE"></Column>
                            <Column field="days_duration" header="DURATA GG"></Column>
                            <Column field="price" header="€"></Column>
                            <Column>
                                <template #body="slotProps">
                                    <Button v-if="(slotProps.data.days_duration === 10 && trialSubUsed) || (slotProps.data.days_duration === 7 && trialSubUsed)" label="GIA' USATA" class="btnGrey" disabled="disabled" />
                                    <Button v-else label="ATTIVA" class="btnGreen" @click="goTOSubSummary(slotProps.data.SUBid, slotProps.data.price)" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <Toast />
                </div>
                <div v-else>
                    {{ getValues.message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
    props: ['courseName', 'edition_id', 'course_id'],
    data() {
        return {
            loading: false,
            token: '',
            subs: [],
            getValues: {
                success: '',
                message: ''
            },
            selectedCourseName: '',
            selectedCourseId: null,
            selectedEdtId: null,
            trialSubUsed: false,
            infoMex: '',
            isActiveSuccess: false,
            isActiveMex: ''
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['setSubID']),
        getAllSubs(edCourseID = null) {
            this.loading = true;
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/subs', { params: { userRole: this.user.role , edCourseID: edCourseID}, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.subs = res.data.subs;
                }
                this.getValues.success = res.data.success; 
                this.getValues.message = res.data.message; 
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                console.log('chiamata terminata fn getAllSubs');
            });
        },
        trialSubUsedForEdCourse(uID) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/trialSubUsedForEdCourse', { params: { userID: uID } , headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.trialSubUsed = res.data.trialSubUsed;
                } else {
                    this.trialSubUsed = res.data.trialSubUsed;
                    this.infoMex = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.trialSubUsed) {
                    // la sub trial è già stata usata dall'utente
                    // blocco la possibilità di usarla ancora
                    console.log('trial sub already used');
                }
                console.log('chiamata terminata fn trialSubUsedForEdCourse');
            });
        },
        /* getFilteredSubs() {
            this.loading = true;
            console.log(`Qui ci saranno le subs per il corso con edizione: ${this.$store.state.selEdtId}`);
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/filteredsubs', { params: { edition_id: this.$store.state.selEdtId, userID: this.$store.state.user.id } , headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.subs = res.data.subsList;
                    this.trialSubUsed = res.data.trialSubUsed;
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                console.log('valore this.subs.length');
                console.log(this.subs.length);
                if(this.trialSubUsed) {
                    // la sub trial è già stata usata dall'utente
                    // blocco la possibilità di usarla ancora
                    console.log('trial sub already used');
                }
                
                console.log('chiamata terminata fn getFilteredSubs');
            });
        }, */
        subToCourse(uid, sid) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/subToCourse', { user_id: uid, sub_id: sid },{ headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    //this.getValues.success = res.data.success;
                    //this.getValues.message = res.data.message;

                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);  
                    }
                    //this.getValues.success = res.data.success;
                    //this.getValues.message = res.data.message; 
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata');
            });
        },
        goTOSubSummary(subID, price) {
            console.log(price);
            if(price === 0) {
                // è la trial non servono i dati di fatturazione
                this.setSubID(subID);
                this.$router.push({ name: 'SubControlPanel' , params: { id: subID }});
            } else {
                if(this.user.readonly === 1 || this.$store.state.userProfileComplete) {
                    // ha già il profilo compilato
                    this.setSubID(subID);
                    this.$router.push({ name: 'SubControlPanel' , params: { id: subID }});
                } else {
                    // vanno chiesti i dati per la fatturazione
                    this.$swal({
                        title: 'DATI PER FATTURAZIONE',
                        html: `Per continuare con l'acquisto, è necessario compilare i dati per la fatturazione.<br>
                        Una volta inseriti, potrai tornare in quest'area e acquistare la sottoscrizione che hai scelto!<br>
                        Grazie`,
                        position: 'center',
                        width: '700px',
                        showConfirmButton: true,
                        confirmButtonText: 'Profilo',
                        showCancelButton: true,
                        cancelButtonText: 'Annulla',
                        icon: 'info'
                    }).then((result) => {
                        if(result.value) {
                            this.$router.push('/userProfile');
                        } else {
                            this.$toast.add({ severity: 'info', summary: 'Torna quando vorrai acquistare.', life: 1500 });
                        }
                    });
                }
            }
        },
        toAddNewSub() {
            this.$router.push('/addsub');
        },
        // ONLY FOR ADMIN
        enableDisableSub(sub_id, stateValue) {
            // resettiamo variabile messaggio isActive
            this.isActiveMex = '';
            const params = {
                sub_id: sub_id,
                state: stateValue
            }
            console.log('valore parametri passati alla fn #######');
            console.log(params);
            
            axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/manageSub', params, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                if(res.data) {
                    if(res.data.success) {
                        this.subs.forEach((sub) => {
                            if(sub.SUBid === sub_id) {
                                sub.isActive = stateValue;
                                this.isActiveMex = res.data.message;
                                this.isActiveSuccess = res.data.success;
                            }
                        });
                    } else {
                        this.isActiveMex = res.data.message;
                        this.isActiveSuccess = res.data.success;
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.$toast.add({ severity: 'info', summary: this.isActiveMex, life: 1500 });
                console.log('Chiamata terminata da fn enableDisableSub');
            });
        }

    },
    mounted() {
        //console.log(this.$store.state.selCourseName);
        
        this.token = this.$store.state['x-access-token'];
        //console.log(this.token);
        if(this.user.role === 'admin') {
            this.getAllSubs();
        } else if(this.user.role === 'user') {
            // salvo gli attuali dati che mi servono per dispensare la view
            // popolata con  dati corretti
            // questo mi mette al riparo da eventuali ricaricamenti della pagina
            // che farebbero perdere i dati delle props
            // provocando un differente ed errato risultato
            //this.$store.dispatch("setCourseName", this.courseName);
            //console.log(this.$store.state.selCourseName);
            //this.$store.dispatch("setEdtId", this.edition_id);
            this.selectedCourseName = this.$store.state.selCourseName;
            this.selectedEdtId = this.$store.state.selEdtId;
            console.log('valore salvato nel vuex del nome del corso selezionato');
            console.log(this.selectedCourseName);
            console.log('valore salvato nel vuex del numero di edizione del corso selezionato');
            console.log(this.selectedEdtId);
            console.log('valore courseID');
            console.log(this.selectedCourseId);
            this.getAllSubs(this.selectedEdtId);
            this.trialSubUsedForEdCourse(this.$store.state.user.id);
        }
    }
}
</script>

<style scoped>
    /* .myMagicEff:first-child:hover {
        transform: scale(1.1,1.1);
        background-color: #eeeeee;
    }
    .myMagicEff:nth-child(2):hover {
        transform: scale(1.1,1.1);
        background-color: #CD7F32;
    }
    .myMagicEff:nth-child(3):hover {
        transform: scale(1.1,1.1);
        background-color: silver;
    }
    .myMagicEff:last-child:hover {
        transform: scale(1.1,1.1);
        background-color: goldenrod;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1,1);
        }
        100% {
            transform: scale(1.1, 1.1);
        }
    } */
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.p-datatable {
    max-width: 100vw;
}
</style>
