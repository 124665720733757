<template>
    <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-full lg:w-10 mx-auto my-6">
        <h3 class="bgBlue py-2">SOTTOSCRIZIONI</h3>
        <DataTable :value="userSubs" stripedRows responsiveLayout="stack">
            <Column field="courseName" header="PERCORSO"></Column>
            <Column field="sub_type" header="SOTTOSCRIZIONE"></Column>
            <Column field="sub_state" header="STATO"></Column>
            <Column field="days_duration" header="DURATA GG"></Column>
            <Column field="price" header="€"></Column>
            <Column field="start_date" header="INIZIO"></Column>
            <Column field="end_date" header="TERMINE"></Column>
            <Column>
                <template #body="slotProps">
                    <Button v-if="slotProps.data.paymentProgress === 'in attesa' && payBtn === 'on'" title="riprova il pagamento" icon="pi pi-euro" class="btnGreen mr-2" @click="checkout(slotProps.data.sub_id, slotProps.data.sub_type, slotProps.data.price, slotProps.data.days_duration, slotProps.data.id)"/>
                    <Button v-else-if="slotProps.data.paymentProgress === 'in attesa' && payBtn === 'off'" icon="pi pi-euro" class="btnGreen mr-2" disabled="disabled" />
                    <Button v-if="slotProps.data.paymentProgress === 'in attesa' && deleteBtn === 'on'" title="elimina tentativo di pagamento" icon="pi pi-trash" class="btnRed" @click="deleteIncompleteUserSub(slotProps.data.id)" />
                    <Button v-else-if="slotProps.data.paymentProgress === 'in attesa' && deleteBtn === 'off'" icon="pi pi-trash" class="btnRed" disabled="disabled" />
                </template>
            </Column>
        </DataTable>
    </div>
    <Toast />
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            token: '',
            userSubs: [],
            payBtn: 'on',
            deleteBtn: 'on',
            getResponse: {
                success: '',
                message: ''
            }
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.getUserSubs();
    },
    methods: {
        getUserSubs() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userSubs', { params: { userId: this.$store.state.user.id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.userSubs = res.data.userSubs;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata da fn getUserSubs');
            });
        },
        checkout(sub_id, sub_type, price, days_duration, usid) {
            this.payBtn = 'off';
            this.deleteBtn = 'off';
            this.$swal({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                title: 'Attendi, verrai indirizzato alla pagina del pagamento',
                icon: 'info'
            });
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/checkout', { sub_id: sub_id, sub_type: sub_type, price: price, days_duration: days_duration, us_id: usid, repay: true }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.session.url) {
                    window.location.href = res.data.session.url;
                }
            });
        },
        deleteIncompleteUserSub(usid) {
            this.payBtn = 'off';
            this.deleteBtn = 'off';
             this.$swal({
                title: 'Conferma Eliminazione',
                html: `Vuoi eliminare definitivamente la sottoscrizione?<br>
                Se decidi di eliminarla dovrai ripetere tutta la procedura, dalla lista percorsi.`,
                position: 'center',
                width: '700px',
                showConfirmButton: true,
                confirmButtonText: 'Elimina',
                showCancelButton: true,
                cancelButtonText: 'Annulla',
                icon: 'info'
            }).then((result) => {
                console.log(result.value);
                if(result.value) {
                    axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/deleteIUS', { usid: usid, userID: this.$store.state.user.id }, { headers: { 'x-access-token': this.token } })
                    .then((res) => {
                        console.log(res.data);
                        this.getResponse.success = res.data.success;
                        this.getResponse.message = res.data.message;
                    })
                    .catch((err) => {
                        console.error(err.toString());
                    })
                    .finally(() => {
                        // qui ci metto anche la gestione del post eliminazione della userSub
                        if(this.getResponse.success) {
                            // row eliminata
                            this.$toast.add({ severity: 'success', summary: this.getResponse.message, life: 1500 });
                            this.getUserSubs();
                        } else {
                            // row non eliminata per qualche errore o già eliminata
                            this.$toast.add({ severity: 'error', summary: this.getResponse.message, life: 1500 });
                            this.getUserSubs();
                        }
                        // mostro un toast di successo di avvenuta eliminazione e contestualmente
                        // richiamo la fn che carica le sub dell utente
                        // per aggiornare la pagina
                    
                        console.log('chiamata terminata da confirm del Swal di fn deleteIcompleteUserSub');
                    });
                } else {
                    this.payBtn = 'on';
                    this.deleteBtn = 'on';
                }
            });

        }
    }

}
</script>

<style>

</style>