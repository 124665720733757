<template>
    <div class="">
        <h1 class="bgBlue">CREA IL TUO QUIZ</h1>
        <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-full lg:w-10 mx-auto my-6">
            <div v-if="userRole === 'admin'" class="p-fluid grid">
                <div class="field sm:col-12 md:col-6 md:col-offset-3">
                    <label>Corso/i a cui assegnare il nuovo quiz</label>
                    <Dropdown v-model="userCourseChoiceId" :options="coursesList" optionLabel="courseName" optionValue="id" placeholder="Seleziona un corso*" @change="fetchMatters(userCourseChoiceId)" />
                </div>
            </div>
            <div v-if="userCourseChoiceId">
                <div class="p-fluid grid">
                    <div class="field sm:col-12 md:col-4 md:col-offset-3">
                        <label>Descrizione</label>
                        <InputText v-model="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && submitted}" type="text" placeholder="Es. Simulazione Esame" />
                        <div v-if="(v$.description.$invalid && submitted)" class="p-error">{{v$.description.required.$message.replace('Value', 'Descrizione')}}</div>
                    </div>
                    <div class="field sm:col-12 md:col-2">
                        <label>Soglia</label>
                        <template v-if="userCourseChoiceId == 1">
                            <Dropdown v-model="selectedThreshold" :options="thresholdOptions" />
                        </template>
                        <template v-else>
                            <InputNumber v-model="selectedThreshold" value="62" readonly/>
                        </template>
                    </div>
                </div>

                <div  v-if="userCourseChoiceId > 1" class="p-fluid grid">
                    <div class="field sm:col-12 md:col-6 md:col-offset-3">
                        <label>Modulo</label>
                        <Dropdown v-model="selectedMatter" :options="matters" optionLabel="matter" placeholder='Scegli il modulo' @change="fetchSubjects(this.selectedMatter.matter_id, userCourseChoiceId)">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>{{ slotProps.option.matter_code }} - {{ slotProps.option.matter }}</div>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
                <div v-if="selectedMatter && userCourseChoiceId > 1" class="p-fluid grid">
                    <div class="field sm:col-12 md:col-6 md:col-offset-3">
                        <label>Argomento</label>
                        <MultiSelect v-model="selectedSubject" :options="subjects" optionLabel="subject" placeholder="Scegli l'argomento">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>{{ slotProps.option.subject_code }} - {{ slotProps.option.subject }}</div>
                                </div>
                            </template>
                        </MultiSelect>
                    </div>
                </div>

                <div v-if="userCourseChoiceId == 1" class="p-fluid grid">
                    <div class="field sm:col-12 md:col-6 md:col-offset-3">
                        <label>Materia</label>
                        <Dropdown v-model="selectedMatter" :options="matters" optionLabel="matter" placeholder="Scegli la materia" @change="fetchContents(this.selectedMatter.matter_code, userCourseChoiceId)">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>{{ slotProps.option.matter_code }} - {{ slotProps.option.matter }}</div>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                    <div  v-if="selectedMatter" class="field sm:col-12 md:col-6 md:col-offset-3">
                        <label>Contenuto</label>
                        <MultiSelect v-model="selectedContent" :options="contents" optionLabel="content" placeholder="Scegli il contenuto">
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>{{ slotProps.option.content_code }} - {{ slotProps.option.content }}</div>
                                </div>
                            </template>
                        </MultiSelect>
                    </div>
                </div>

                <div class="p-fluid grid align-items-end">
                    <div class="field sm:col-12 md:col-3 md:col-offset-3">
                        <label>Numero di domande</label>
                        <InputNumber v-model="number" placeholder="Es. 1" /> <!-- @keyup="checkQuestionsQuantity($event)" -->
                    </div>
                <!-- TODO/FIXME:: controllo numero domande sufficienti e aggiungi riga/materia
                    <div v-if="infoMex" class="field sm:col-12 md:col-3">
                        <InlineMessage v-if="checkQNum === false" severity="warn">{{ infoMex }}</InlineMessage>
                        <InlineMessage v-else-if="checkQNum === true" severity="success">{{ infoMex }}</InlineMessage>
                    </div>
                    <div class="field sm:col-12 md:col-2">
                        <Button v-if="checkQNum === true" icon="pi pi-plus" class="btnGreen" @click="addQuizStructure()"/>
                        <Button v-else-if="checkQNum === false" icon="pi pi-plus" class="btnRed" disabled="disabled"/>
                        <Button v-else icon="pi pi-plus" class="btnLightBlue" disabled="disabled"/>
                    </div>
                -->
                </div>
                
                <div v-if="quizStructure.length > 0">
                    <h3 class="mt-3">Riepilogo scelte effettuate</h3>
                    <div v-for="(item, index) in quizStructure" :key="index">
                        <div class="grid p-fluid">
                            <div class="sm:col-12 md:col-5">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">MOD.</span>
                                    <InputText :value="item.matter" readonly/>
                                </div>
                            </div>
                            <div class="sm:col-12 md:col-4">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">CONT.</span>
                                    <InputText :value="item.content" readonly/>
                                </div>
                            </div>
                            <div class="sm:col-12 md:col-4">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">SUBJ.</span>
                                    <InputText :value="item.subject" readonly/>
                                </div>
                            </div>
                            <div class="sm:col-12 md:col-2">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">#DOM.</span>
                                    <InputText :value="item.quantity" readonly />
                                </div>
                            </div>
                            <div class="col-1"> 
                                <Button icon="pi pi-trash" class="btnRed" @click="removeRow(index) "/>
                            </div>
                        </div>
                    </div>
                </div>
                
                <footer class="flex justify-content-center mt-5 mb-3">
                    <Button label="LISTA QUIZ" class="mr-4 btnGrey" @click="goToQuizIndex" />
                    
                    <Button v-if="!number || !selectedMatter" label="AGGIUNGI RIGA" icon="pi pi-plus" class="mr-4 btnGreen txtSmall" :disabled="true" />
                    <Button v-else label="AGGIUNGI RIGA" icon="pi pi-plus" class="mr-4 btnGreen txtSmall" :disabled="false" @click="addQuizStructure()" />
                    
                    <Button v-if="quizStructure.length === 0" label="CREA QUIZ" class="btnGreen txtSmall" :disabled="true" />
                    <Button v-else label="CREA QUIZ" class="btnGreen txtSmall" :disabled="false" @click="createQuiz(!v$.$invalid)" />
                </footer>
            </div>
            <Toast />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            submitted: false,
            token: '',
            isAdmin: false,
            userCourseChoiceId: null,
            description: '',
            date_start: null,
            date_end: null,
            matter: '',
            subject: '',
            content: '',
            number: null,
            data: [],
            selectedMatter: null,
            matters: [],
            selectedSubject: [],
            subjects: [],
            selectedContent: [],
            contents: [],
            getResults: {
                success: false,
                message: ''
            },
            quizStructure: [],
            avaiableQ: [],
            checkQNum: '',
            infoMex: '',
            selectedThreshold: 70,
            thresholdOptions: [70, 75, 80, 85, 90, 95, 100],
            btnCreateQuiz: 'off',
            coursesList: [],
            selectedCourse: [],
            paramsObj: {},
            selectedCourseId: null,
            userRole: ''

        }
    },
    validations() {
        return {
            description: {
                required: helpers.withMessage('Il campo Descrizione è obbligatorio', required)
            }
        }
    },
    methods: {
        goToQuizIndex() { //FUNZIONE DI REDIRECT
            this.$router.push('/quiz');
        },

        addQuizStructure() {
            const row = {};
            row.matter = this.selectedMatter.matter_code;
            if (this.userCourseChoiceId == 1){
                row.content = [];
                this.selectedContent.forEach(content => {
                row.content.push(content.content_code)
                });
                row.typeweight1 = null; 
                row.typeweight2 = null;
                row.subject = [];
            }else{
                row.subject = [];
                this.selectedSubject.forEach(subject => {
                    row.subject.push(subject.subject_code)
                });
                row.type1 = null;
                row.type2 = null;
                row.type3 = null;
            }
            row.quantity = this.number;
            this.quizStructure.push(row);
            console.log("createdQuizStructure", this.quizStructure);
            this.selectedMatter = '';
            this.selectedContent = '';
            this.selectedSubject = '';
            this.number = null;
        },
        removeRow(index) {
            this.quizStructure.splice(index, 1);
            if(this.quizStructure.length === 0 && this.btnCreateQuiz === 'on') {
                this.btnCreateQuiz = 'off';
            }
        },

        createQuiz() {
            if(this.quizStructure.length === 0) {this.addQuizStructure()}
            if(!this.description || this.quizStructure.length === 0) {
                this.submitted = true;
                this.$toast.add({ severity: 'error', summary: 'DEVI selezionare almeno una materia e/o contenuto e scegliere il numero di domande, per creare il quiz!', life: 5000});
                return;
            } else {
                this.submitted = true;

                const obj = {
                    user: {
                        id: this.$store.state.user.id,
                        role: this.$store.state.user.role
                    },
                    description: this.description,
                    threshold: this.selectedThreshold,
                    date_start: this.date_start,
                    date_end: this.date_end,
                    structure: this.quizStructure,
                    courseID: this.userCourseChoiceId
                }
                
                axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/quizStructure', obj, { headers: { 'x-access-token': this.token } })
                .then((res) => {
                    console.log(res);
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    if(res.data.success === true) {
                        this.getResults.success = true;
                        this.getResults.message = res.data.message;
                    } else if(res.data.success === 'info') {
                        this.getResults.success = 'info';
                        this.getResults.message = res.data.message;
                    } else {
                        this.getResults.message = res.data.message;
                    }

                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    this.submitted = false;
                    if(this.getResults.success === true) {
                        // successo, redirect 
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            title: this.getResults.message,
                            icon: 'success'
                        });
                        setTimeout(() => {
                            this.$router.push('/quiz');
                        }, 1500);
                    } else if(this.getResults.success == 'info') {
                        // info no redirect, box info
                        this.$swal({
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 5000,
                            title: this.getResults.message,
                            icon: 'info'
                        });
                        this.quizStructure = [];
                        this.checkQNum = "";
                        this.infoMex = "";
                    } else {
                        // errore box danger
                        this.$swal({
                            toast: true,
                            position: 'center',
                            showConfirmButton: true,
                            timer: 5000,
                            title: this.getResults.message,
                            icon: 'error'
                        });
                        this.quizStructure = [];
                        this.checkQNum = "";
                        this.infoMex = "";
                    }
                    console.log('chiamata terminata');
                });
            }    
        },
        checkQuestionsQuantity(event) {
            //console.log(event);
            console.log(this.number);
            if(this.number == "") {
                this.checkQNum = "";
                this.infoMex = "";
            } else {
                if(this.selectedContent) {
                    console.log(this.selectedContent.content_code);
                    // se è stato selezionato il contenuto
                    // verifichiamo la disponibilità a questo livello
                    this.avaiableQ.find((item) => {
                        if(item.codice == this.selectedContent.content_code) {
                            console.log(item);
                            if(item.domande > this.number) {
                                console.log('Ok');
                                this.checkQNum = true;
                                this.infoMex = 'Quantità di domande scelte, disponibile, per questo contenuto!';
                            } else {
                                console.log('numero di domande richiesto supera la quantità disponibile');
                                this.checkQNum = false;
                                this.infoMex = `Attenzione, per questa materia il numero di domande disponibili è: ${item.domande}`;
                                return;
                            }
                        }
                    });
                } else if(this.selectedSubject) {
                    console.log(this.selectedSubject.subject_code);

                    this.avaiableQ.find((item) => {
                        if(item.codice == this.selectedSubject.subject_code) {
                            console.log(item);
                            if(item.domande > this.number) {
                                console.log('OK');
                                this.checkQNum = true;
                                this.infoMex = 'Quantità di domande scelte, disponibile, per questo argomento!';
                            } else {
                                console.log('numero di domande richiesto supera la quantità disponibile');
                                this.checkQNum = false;
                                this.infoMex = `Attenzione, il numero di domande scelto supera il numero di domande disponibili per questo argomento! \n
                                Numero di domande disponibili: ${item.domande}`;
                                return;
                            }
                        }
                    });
                } else if(this.selectedMatter) {
                    console.log(this.selectedMatter.matter_code);

                    this.avaiableQ.find((item) => {
                        if(item.codice == this.selectedMatter.matter_code) {
                            console.log(item);
                            if(item.domande > this.number) {
                                console.log('OK');
                                this.checkQNum = true;
                                this.infoMex = 'Quantità di domande scelte, disponibile, per questa materia!';
                            } else {
                                console.log('numero di domande richiesto supera la quantità disponibile');
                                this.checkQNum = false;
                                this.infoMex = `Attenzione, il numero di domande scelto supera il numero di domande disponibili per questa materia! \n
                                Numero di domande disponibili: ${item.domande}`;
                                return;
                            }
                        }
                    });
                } else {
                    console.log('DEVI SELEZIONARE ALMENO LA MATERIA, PRIMA DI SCEGLIERE IL NUMERO DI DOMANDE DA AGGIUNGERE!');
                    this.checkQNum = false;
                    this.infoMex = 'DEVI SELEZIONARE ALMENO LA MATERIA, PRIMA DI SCEGLIERE IL NUMERO DI DOMANDE DA AGGIUNGERE!';
                };
            }
        },

        getCoursesIds() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/coursesIds`, { headers: { 'x-access-token': this.token }})
            .then((res) => {
                //console.log(res.data);
                if(res.data.success) {
                    this.coursesList = res.data.coursesList;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                //console.log('chiamata terminata da fn getCoursesIds');
            });
        },
        fetchMatters(courseID) {
            this.selectedSubject = []; //pulisco array subjects selezionate
            this.selectedContent = []; //pulisco array contents selezionati
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/getmatters`, {
                params: { courseID: courseID },
                headers: { 'x-access-token': this.token } 
            })
            .then((res) => { 
                this.matters = res.data.matters;
                console.log("mattersDisponibili", this.matters);
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log('chiamata terminata da fn fetchmatters in QuizIndex.vue');
            });
        },
        fetchSubjects(mID, courseID) {
            this.selectedSubject = []; //pulisco array subjects selezionate
            console.log("selectedMatter", this.selectedMatter);
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/getsubjects`, {
                params: { courseID: courseID, matterID: mID, matterArray: null },
                headers: { 'x-access-token': this.token } 
            })
            .then((res) => { 
                this.subjects = res.data.subjects;
                console.log("subjectsDisponibili", this.subjects);
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log('chiamata terminata da fn fetchsubjects in QuizIndex.vue');
            });
        },
        fetchContents(mCode, courseID = 1) {
            this.selectedContent = []; //pulisco array contents selezionati
            //console.log("selectedSubject", this.selectedSubject);
            console.log("selectedMatter", this.selectedMatter);
            //if( this.selectedSubject.length == 1){ //ovviamente se l'utente seleziona più di una subject non posso cercare i contents corrispondenti
            console.log(mCode);
                axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/getcontents`, {
                    params: { courseID: courseID, isAdmin: 1 , subjectID: null, matter_code: mCode , matterArray: null },
                    headers: { 'x-access-token': this.token } 
                })
                .then((res) => { 
                    this.contents = res.data.contents;
                    console.log("contentsDisponibili", this.contents);
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    //console.log('chiamata terminata da fn fetchcontents in QuizIndex.vue');
                });
            //}
        },

    },
    computed: {
        ...mapState(['user'])
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.userRole = this.$store.state.user.role;
        this.userCourseChoiceId = this.$store.state.selCourseID;
        this.getCoursesIds();
        if(this.userRole === 'user'){this.fetchMatters(this.userCourseChoiceId)};
        
    }

}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.btnGreen {
    background: #017632 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGreen:enabled:hover {
    background: #017300 !important;
}
.btnLightBlue {
    background: #009bca !important;
    border: none !important;
    color: #eaeaea !important;
}
</style>
