<template>
    <div class="surface-card p-4 shadow-4 border-round sm:w-full lg:w-6 mx-auto my-6">
        <div class="text-center mb-4">
          <div class="myCol text-3xl font-medium mb-3">Benvenuto!</div>
          <span class="text-600 font-medium line-height-3">Hai già un account?</span>
          <router-link to="/login" class="font-medium no-underline ml-2 cursor-pointer loginText">Vai al login!</router-link>
        </div>
        <form @submit.prevent="submitForm(!v$.$invalid)" class="p-fluid">
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText id="name" v-model="v$.form.firstname.$model" :class="{'p-invalid':v$.form.firstname.$invalid && submitted}" class="capitalize" />
                    <label for="name" :class="{'p-error':v$.form.firstname.$invalid && submitted}">Nome *</label>
                </div>
                <small v-if="(v$.form.firstname.$invalid && submitted) || v$.form.firstname.$pending.$response" class="p-error">{{v$.form.firstname.required.$message.replace('Nome', 'Nome')}}</small>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText id="name" v-model="v$.form.lastname.$model" :class="{'p-invalid':v$.form.lastname.$invalid && submitted}" class="capitalize" />
                    <label for="name" :class="{'p-error':v$.form.lastname.$invalid && submitted}">Cognome *</label>
                </div>
                <small v-if="(v$.form.lastname.$invalid && submitted) || v$.form.lastname.$pending.$response" class="p-error">{{v$.form.lastname.required.$message.replace('Cognome', 'Cognome')}}</small>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText id="name" v-model="v$.form.username.$model" :class="{'p-invalid':v$.form.username.$invalid && submitted}" class="uppercase" />
                    <label for="name" :class="{'p-error':v$.form.username.$invalid && submitted}">Codice Fiscale *</label>
                </div>
                <small v-if="(v$.form.username.$invalid && submitted) || v$.form.username.$pending.$response" class="p-error">{{v$.form.username.required.$message.replace('CodiceFiscale', 'CodiceFiscale')}}</small>
            </div>
            <div class="field mb-4">
                <div class="p-float-label p-input-icon-right">
                    <i class="pi pi-envelope" />
                    <InputText id="email" v-model="v$.form.email.$model" :class="{'p-invalid':v$.form.email.$invalid && submitted}" aria-describedby="email-error" class="lowercase" />
                    <label for="email" :class="{'p-error':v$.form.email.$invalid && submitted}">Email *</label>
                </div>
                <span v-if="v$.form.email.$error && submitted">
                    <span id="email-error" v-for="(error, index) of v$.form.email.$errors" :key="index">
                    <small class="p-error">{{error.$message}}</small>
                    </span>
                </span>
                <small v-else-if="(v$.form.email.$invalid && submitted) || v$.form.email.$pending.$response" class="p-error">{{v$.form.email.required.$message.replace('Email', 'Email')}}</small>
            </div>
            <div class="flex justify-content-between">
              <div class="field-checkbox mb-4">
                  <Checkbox id="accept" name="accept" value="Accept" v-model="v$.form.accept.$model" :class="{'p-invalid':v$.form.accept.$invalid && submitted}" />
                  <label for="accept" :class="{'p-error': v$.form.accept.$invalid && submitted}" class="registerText">Accetto i termini e le condizioni *</label>
                  <small v-if="(v$.form.accept.$invalid && submitted) || v$.form.accept.$pending.$response" class="p-error ml-3">{{v$.form.accept.required.$message.replace('Privacy', 'Privacy')}}</small>
              </div>
              <div>
                <a href="https://esamifinanza.it/privacy-e-cookies/informativa-sulla-privacy/" target="_blank" rel="noopener noreferrer" class="font-medium ml-2 registerText text-right cursor-pointer mr-3">Privacy</a>
                <a href="https://esamifinanza.it/privacy-e-cookies/utilizzo-dei-cookies/" target="_blank" rel="noopener noreferrer" class="font-medium ml-2 registerText text-right cursor-pointer">Informativa cookie</a>
              </div>
            </div>
            <Button type="submit" label="Registrati" class="myBgC myBtnCol mt-2" />
        </form>
    </div>
</template>

<script>
import { email, required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import axios from 'axios';

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        accept: null
      },
      submitted: false,
      showMessage: false,
      getResult: {'success': '', 'message': '', 'data': {}}
    }
  },
  validations() {
    return {
      form: {
        firstname: {
          required: helpers.withMessage('Il campo Nome è obbligatorio', required)
        },
        lastname: {
          required: helpers.withMessage('Il campo Cognome è obbligatorio', required)
        },
        username: {
          required: helpers.withMessage('Il campo Codice Fiscale è obbligatorio', required)
        },
        email: {
          required: helpers.withMessage('Il campo Email è obbligatorio', required),
          email: helpers.withMessage('La mail inserita non è valida', email)
        },
        accept: {
          required: helpers.withMessage('Il campo è obbligatorio', required)
        }
      }
    }
  },
  methods: {
    submitForm(isFormValid) {
      this.submitted = true;
      console.log(isFormValid);
      if(!isFormValid) {
        return;
      } else {
        // il form è valido, faccio la chiamata axios 
        // normalizzo i dati che inserisce l'utente prima di passarli al back
        this.form.firstname = this.form.firstname.toLowerCase();
        this.form.lastname = this.form.lastname.toLowerCase();
        this.form.username = this.form.username.toUpperCase();
        this.form.email = this.form.email.toLowerCase();
        
        axios.post(process.env.VUE_APP_API_URL + '/api/v1/auth/register', this.form)
        .then((res) => {
          console.log(res.data);
          this.getResult.success = res.data.success;
          this.getResult.message = res.data.message;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.submitted = false;
          if(this.getResult.success) {
            this.$swal({
              toast: true,
              position: 'top-right',
              showConfirmButton: false,
              timer: 5000,
              title: this.getResult.message,
              icon: 'success'
            });
            setTimeout(() => {
              this.$router.push('/');
            }, 1500);
            this.form.lastname = '';
            this.form.firstname = '';
            this.form.email = '';
            this.form.username = '';
            this.form.accept = null
          } else {
            /* se il risultato di register è false, non devo andare al login con un messaggio di difficile comprensione che non ha a che vedere
               con il register
             */
            this.$swal({
              toast: true,
              position: 'top-right',
              showConfirmButton: false,
              timer: 5000,
              title: this.getResult.message,
              icon: 'error'
            });

            /*
            this.$swal({
              toast: true,
              position: 'center',
              width: '700px',
              timer: 7000,
              timerProgressBar: true,
              title: this.getResult.message,
              html: `Attenzione, Stai per essere reindirizzato alla pagina di login!<br>Controlla la tua mail, per scoprire le credenziali ed effettuare l'accesso alla piattaforma!<br>`,
              icon: 'warning'
            });
            setTimeout(() => {
              this.$router.push('/login');
            }, 5500);
            this.form.lastname = '';
            this.form.firstname = '';
            this.form.email = '';
            this.form.username = '';
            this.form.accept = null
            */
          }
          console.log('chiamata terminata');
        });
      }
    }
  }
}
</script>

<style scoped>
.myBgC {
  background-color: #004b87;
}
.myCol {
  color: #004b87;
}
.myBtnCol {
  color: #eaeaea;
}
.loginText {
  color: #017331;
}
.registerText {
  color: #004a87
}
</style>
