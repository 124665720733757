<template>
    <h1 class="mt-3">Area creazione nuova Sub</h1>
    <div class="surface-card p-4 shadow-4 border-round sm:w-full lg:w-6 mx-auto my-4">
        <form @submit.prevent="createSub" class="p-fluid">
            <div class="field mb-4">
                <Dropdown v-model="inputSelectCourse" :options="courses" optionLabel="courseName" placeholder="Seleziona un corso" @change="getCourseEditions($event)" />
            </div>
            <div v-if="inputSelectCourse" class="field mb-4">
                <Dropdown v-model="inputSelectEd" :options="editions" optionLabel="editionNum" placeholder="Seleziona il numero dell'edizione" @change="selectEdtionNum($event)" />
            </div>
            <div class="field mb-4">
                <!-- <Dropdown v-model="inputSelectSubType" :options="sub_types" optionLabel="name" placeholder="Seleziona tipo" @change="selectSubType($event)" /> -->
                <div class="p-float-label">
                    <InputText type="text" id="sType" v-model="form.sub_type" />
                    <label for="sType">Tipo Sottoscrizione</label>
                </div>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputText type="text" id="comment" v-model="form.comment" />
                    <label for="comment">Commento</label>
                </div>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputNumber id="durationGG" v-model="form.days_duration" suffix=" giorni" />
                    <label for="durationGG">Durata</label>
                </div>
            </div>
            <div class="field mb-4">
                <div class="p-float-label">
                    <InputNumber id="horizontal" v-model="form.price" showButtons buttonLayout="horizontal" :step="1"
                    decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="currency" currency="EUR" />
                    <label for="horizontal" class="ml-5">Prezzo</label>
                </div>  
            </div>
            <div class="field grid mb-4">
                <div class="col-4 col-offset-2">
                    <Button type="submit" label="CREA" class="mt-2" />
                </div>
                <div class="col-4">
                    <Button @click="toSubs" label="INDIETRO" class="mt-2 p-button-warning" />
                </div>
            </div>
        </form>
    </div>
    <Toast />
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            token: '',
            courses: [],
            inputSelectCourse: null,
            inputSelectEd: null,
            inputSelectSubType: null,
            editions: [],
            /* sub_types: [
                { name: 'Prova', value: 'trial' },
                { name: 'Bronzo', value: 'bronze' },
                { name: 'Argento', value: 'silver' },
                { name: 'Oro', value: 'gold' }
            ], */
            form: {
                course_id: null,
                edition_id: null,
                sub_type: '',
                price: null,
                days_duration: null,
                comment: ''
            },
            getResults: {
                success: '',
                message: ''
            }
        }
    },
    methods: {
        getCourseList() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/onlyCourses', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log('VALORE LISTA CORSI @@@@');
               console.log(res.data);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.courses = res.data.courses;

                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);  
                    }
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.token = this.$store.state['x-access-token'];
                console.log('chiamata terminata fn getCourseList');
            });
        },
        getCourseEditions(event) {
            console.log('VALORE variabile event @@@@@');
            console.log(event);
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/editionsByCourseId', { params: { "id": event.value.id } , headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log('VALORE Course editions trovato grazie all id del corso @@@');
                console.log(res.data);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.editions = res.data.courseEditions;
                    this.form.course_id = res.data.courseEditions[0].course_id;

                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);  
                    }
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('VALORE MOMENTANEO this.form @@@@@@');
                console.log(this.form);
                console.log('chiamata terminata fn getCourseEditions');
            });
        },
        selectEdtionNum(event) {
            //console.log(event.value);
            this.form.edition_id = event.value.id;
        },
        selectSubType(event) {
            //console.log(event.value.value);
            this.form.sub_type = event.value.value;
        },
        createSub() {
            /* console.log(this.form);
            return; */
            if(!this.inputSelectCourse) {
                this.$toast.add({ severity: 'error', summary: 'Si deve selezionare il corso per proseguire!', life: 3000 });
                return;
            } else if(!this.inputSelectEd) {
                this.$toast.add({ severity: 'error', summary: `Si deve selezionare l'edizione per proseguire!`, life: 3000 });
                return;
            /*}  else if(!this.inputSelectSubType) {
                this.$toast.add({ severity: 'error', summary: `Si deve selezionare una tipologia per proseguire!`, life: 3000 });
                return; */
            } else {
                axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/addSub', this.form, { headers: { 'x-access-token': this.token } })
                .then((res) => {
                    console.log(res.data);
                    this.getResults.success = res.data.success;
                    this.getResults.message = res.data.message;
                    
                })
                .catch((err) => {
                    console.error(err.message());
                })
                .finally(() => {
                    if(this.getResults.success === 'info') {
                        // la sub che si vuole inserire è già presente
                        // mandare un messaggio di warning
                        this.$toast.add({ severity: 'warn', summary: this.getResults.message, life: 3000 });
                    } else if(!this.getResults.success) {
                        // se false
                        // errore nella creazione
                        this.$toast.add({ severity: 'error', summary: this.getResults.message, life: 3000 });
                    } else {
                        // creazione sub con successo
                        this.$toast.add({ severity: 'success', summary: this.getResults.message, life: 1000 });
                        this.inputSelectCourse = null;
                        this.inputSelectEd = null;
                        this.form.edition_id = '';
                        this.form.comment = '';
                        this.form.sub_type = '';
                        this.form.price = null;
                        this.form.days_duration = null;
                        setTimeout(() => {
                            this.$router.push('/subs');
                        }, 1500);
                    }
                    console.log('chiamata terminata');
                });
            }    
        },
        toSubs() {
            this.$router.push('/subs');
        },
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.getCourseList();
    }
}
</script>

<style>

</style>
