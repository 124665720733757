<template>
    <div class="container">
        <h3 class="my-3">PAGINA DEL PAGAMENTO</h3>
        <form @submit.prevent="sendPayment">
            <div class="row mt-3">
                <div class="col-4 offset-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">NOME</span>
                        </div>
                        <input type="text" class="form-control" v-model="form.name">
                    </div>
                </div>
                <div class="col-4">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">EMAIL</span>
                        </div>
                        <input type="text" class="form-control" v-model="form.email">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <h5 class="mb-3">RESIDENZA</h5>
                <div class="col-5">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">INDIRIZZO</span>
                        </div>
                        <input type="text" class="form-control" v-model="form.address.line1">
                    </div>
                </div>
                <div class="col-3">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">CITTA'</span>
                        </div>
                        <input type="text" class="form-control" v-model="form.address.city">
                    </div>
                </div>
                <div class="col-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">STATO</span>
                        </div>
                        <input type="text" class="form-control" v-model="form.address.country">
                    </div>
                </div>
                <div class="col-2">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">CAP</span>
                        </div>
                        <input type="text" class="form-control" v-model="form.address.postal_code">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <h5 class="mb-3">DATI CARTA</h5>
                <div ref="card"></div>
            </div>
            <div class="form-group mt-3">
                <button class="btn btn-success">{{ loading ? "Caricamento..." : `Paga ${subPrice},00 €` }}</button>
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    props: ['price'],
    data() {
        return {
            token: '',
            subID: null,
            subPrice: null,
            userID: this.$store.state.user.id,
            amount: null,
            stripe: null,
            PUBLISHABLE_KEY: "pk_test_51KazkWD059KaO3c26M8bew3zSksEBIzf65COYs7LScn0KcGjjzGD7lMxdN4L5kIZezwIadMcl6tKO7CwECmLmR6300URNWfR00",
            loading: true,
            form: {
                name: '',
                email: '',
                address: {
                    line1: '',
                    city: '',
                    country: '',
                    postal_code: null
                },
            },
            card: undefined,
            style: null,
            paymentMethodReq: null,
            paymentConfirm: null
        }
    },
    methods: {
        async sendPayment(event) {
            if(this.loading) return;
            this.loading = true;
            
            await axios.post(process.env.VUE_APP_API_URL + "/api/v1/p/payment", { amount: this.amount, currency: "eur" }, { headers: { 'x-access-token': this.$store.state['x-access-token'] } })
            .then((res) => {
                //console.log(res.data.secret);

                this.stripe.createPaymentMethod({
                    type: "card",
                    card: this.card,
                    billing_details: this.form
                }).then((result) => {
                    //console.log(result);
                    this.paymentMethodReq = result;


                    this.stripe.confirmCardPayment(res.data.secret, {
                        payment_method: this.paymentMethodReq.paymentMethod.id
                    }).then((result) => {
                        this.paymentConfirm = result;
                        if(this.paymentConfirm.paymentIntent.status) {
                            console.log(this.paymentConfirm.paymentIntent);
                            console.log(this.paymentConfirm.paymentIntent.status);
                            // qui vorrei chiamare una funzione che invia dei paramentri al db 
                            // così da poter salvare il pagamento
                        }
                    });            
                });
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                setTimeout(() => {
                    console.log(this.paymentMethodReq);
                    console.log(this.paymentConfirm);
                    this.loading = false;
                    this.form.name = "";
                    this.form.email = "";
                    this.form.address.line1 = "";
                    this.form.address.city = "";
                    this.form.address.country = "";
                    this.form.address.postal_code = null;
                    this.card.clear();
                    console.log('chiamata terminata');
                }, 3000);
                
            });
        },
        /* 
        funzione che esegue un redirect ad una pagina di default di stripe
        redirect() {
            this.stripe.redirectToCheckout({
                successUrl: "http://localhost:8080/shop",
                cancelUrl: "http://localhost:8080/shop",
                lineItems: [
                    {
                        price: "price_1Kb5H3D059KaO3c2aNPdhqUl",
                        quantity: 1
                    }
                ],
                mode: "payment"
            })
        }, 
        */
        createAndMountFormElements() {
            let elements = this.stripe.elements();
            this.card = elements.create('card', this.style);
            this.card.mount(this.$refs.card);
        },
        storePayment(obj) {
            // l obj che passo a questa funzione contiene i dati che i servono per creare la riga in
        },
    },
    mounted() {
        this.style = {
            style: {
                base: {
                    iconColor: "#000",
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "20px",
                    fontSmoothing: "antialiased",
                    ":-webkit-autofill": {
                        color: "#fce883"
                    },
                    "::placeholder": {
                        color: "green"
                    }
                },
                invalid: {
                    iconColor: "#FFC7EE",
                    color: "red"
                }
            }
        };
        this.stripe = Stripe(this.PUBLISHABLE_KEY);
        this.createAndMountFormElements();
        this.subPrice = this.$store.state.selSubPrice;
        this.amount = this.$store.state.selSubPrice * 100;
        this.subID = this.$store.state.selSubID;
        console.log(this.userID);
        console.log(this.subID);
        
        this.loading = false;
    }
}; 
</script>

<style>

</style>
