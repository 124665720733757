<template>
    <div class="grid">
        <div class="sm:col-12 md:col-10 md:m-auto p-fluid">
            <div class="card p-3">
                <form @submit.prevent="updatePersonalData(!v$.$invalid)">
                    <h1 class="mb-4">Pagina di modifica anagrafica</h1>
                    <div class="grid formgrid">
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="firstname">Nome</label>
                            <InputText type="text" id="firstname" :value="user.firstname" readonly />
                        </div>
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="lastname">Cognome</label>
                            <InputText type="text" id="lastname" :value="user.lastname" readonly />
                        </div>
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label v-if="user.role === 'user'" for="username">Codice Fiscale</label>
                            <label v-else for="username">Username</label>
                            <InputText type="text" id="username" :value="user.username" readonly />
                        </div>
                    </div>
                    <div class="grid formgrid justify-content-evenly">
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="email">Email (modificabile)</label>
                            <InputText type="text" id="email" v-model="user.email" />
                        </div>
                        <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                            <label for="mobile">Cellulare (modificabile)</label>
                            <InputMask id="mobile" v-model="user.mobile" mask="+99 9999999999" slotChar="+39 0000000000"/>
                        </div>
                    </div>
                    <div v-if="user.role === 'user'">
                        <h3>Sezione Dati per la Fatturazione</h3>
                        <h5>Selezionare se si è un privato oppure soggetto con partita iva e procedere con la compilazione dei campi richiesti</h5>
                        <div v-if="user.custom_fields" class="grid formgrid justify-content-evenly">
                            <div v-if="normalUserOrCompany !== 'company'" class="field-radiobutton">
                                <RadioButton name="pFisica" id="pFisica" value="normalUser" v-model="normalUserOrCompany" />
                                <label for="pFisica">Privato</label>
                            </div>
                            <div v-else class="field-radiobutton">
                                <RadioButton name="pFisica" id="pFisica" value="normalUser" disabled="disabled" />
                                <label for="pFisica">Privato</label>
                            </div>
                            <div v-if="normalUserOrCompany !== 'normalUser'" class="field-radiobutton">
                                <RadioButton name="pGiuridica" id="pGiuridica" value="company" v-model="normalUserOrCompany" />
                                <label for="pGiuridica">Soggetto con Partita IVA</label>
                            </div>
                            <div v-else class="field-radiobutton">
                                <RadioButton name="pGiuridica" id="pGiuridica" value="company" disabled="disabled" />
                                <label for="pGiuridica">Soggetto con Partita IVA</label>
                            </div>
                        </div>
                        <div v-else class="grid formgrid justify-content-evenly">
                            <div class="field-radiobutton">
                                <RadioButton name="pFisica" id="pFisica" value="normalUser" v-model="normalUserOrCompany" @change="resetOtherBox($event, 'normalUser')" />
                                <label for="pFisica">Privato</label>
                            </div>
                            <div class="field-radiobutton">
                                <RadioButton name="pGiuridica" id="pGiuridica" value="company" v-model="normalUserOrCompany" @change="resetOtherBox($event, 'company')" />
                                <label for="pGiuridica">Soggetto con Partita IVA</label>
                            </div>    
                        </div>    
                        <div v-if="normalUserOrCompany === 'normalUser'" id="privateBox">
                            <div class="grid formgrid justify-content-evenly">
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="address" :class="{'p-error':v$.privateForm.address.$invalid}">Indirizzo *</label>
                                    <InputText type="text" id="address" v-model="v$.privateForm.address.$model" :class="{'p-invalid':v$.privateForm.address.$invalid && submitted}" placeholder="Via/Viale/Piazza (campo obbligatorio)"/>
                                    <small v-if="v$.privateForm.address.$pending.$response" class="p-error">{{v$.privateForm.address.required.$message.replace('Indirizzo', 'Indirizzo')}}</small>
                                    <small v-else-if="submitted && v$.privateForm.address.$invalid" class="p-error">{{v$.privateForm.address.required.$message.replace('Indirizzo', 'Indirizzo')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="bNum" :class="{'p-error':v$.privateForm.bNum.$invalid}">Numero Civico *</label>
                                    <InputText type="text" id="bNum" v-model="v$.privateForm.bNum.$model" :class="{'p-invalid':v$.privateForm.bNum.$invalid && submitted}" placeholder="Es. 1 (campo obbligatorio)"/>
                                    <small v-if="v$.privateForm.bNum.$pending.$response" class="p-error">{{v$.privateForm.bNum.required.$message.replace('Numero Civico', 'Numero Civico')}}</small>
                                    <small v-else-if="submitted && v$.privateForm.bNum.$invalid" class="p-error">{{v$.privateForm.bNum.required.$message.replace('Numero Civico', 'Numero Civico')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="city" :class="{'p-error':v$.privateForm.city.$invalid}">Città *</label>
                                    <InputText type="text" id="city" v-model="v$.privateForm.city.$model" :class="{'p-invalid':v$.privateForm.city.$invalid && submitted}" placeholder="Città/Comune (campo obbligatorio)"/>
                                    <small v-if="v$.privateForm.city.$pending.$response" class="p-error">{{v$.privateForm.city.required.$message.replace('Città', 'Città')}}</small>
                                    <small v-else-if="submitted && v$.privateForm.city.$invalid" class="p-error">{{v$.privateForm.city.required.$message.replace('Città', 'Città')}}</small>
                                </div>
                            </div>
                            <div class="grid formgrid justify-content-evenly">
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="prov" :class="{'p-error':v$.privateForm.prov.$invalid}">Provincia *</label>
                                    <InputText type="text" id="prov" v-model="v$.privateForm.prov.$model" :class="{'p-invalid':v$.privateForm.prov.$invalid && submitted}" placeholder="Es: MI (sigla provincia campo obbligatorio)"/>
                                    <small v-if="v$.privateForm.prov.$pending.$response" class="p-error">{{v$.privateForm.prov.maxLength.$message.replace('Provincia', 'Provincia')}}</small>
                                    <small v-else-if="submitted && v$.privateForm.prov.$invalid" class="p-error">{{v$.privateForm.prov.maxLength.$message.replace('Provincia', 'Provincia')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="cap" :class="{'p-error':v$.privateForm.cap.$invalid}">CAP *</label>
                                    <InputText type="text" id="cap" v-model="v$.privateForm.cap.$model" :class="{'p-invalid':v$.privateForm.cap.$invalid && submitted}" placeholder="cap (campo obbligatorio)"/>
                                    <small v-if="v$.privateForm.cap.$pending.$response" class="p-error">{{v$.privateForm.prov.maxLength.$message.replace('Provincia', 'Provincia')}}</small>
                                    <small v-else-if="submitted && v$.privateForm.cap.$invalid" class="p-error">{{v$.privateForm.cap.maxLength.$message.replace('cap', 'cap')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="country" :class="{'p-error':v$.privateForm.country.$invalid}">Stato *</label>
                                    <InputText type="text" id="country" v-model="v$.privateForm.country.$model" :class="{'p-invalid':v$.privateForm.country.$invalid && submitted}" placeholder="Es: Italia (campo obbligatorio)"/>
                                    <small v-if="v$.privateForm.country.$pending.$response" class="p-error">{{v$.privateForm.country.required.$message.replace('Stato', 'Stato')}}</small>
                                    <small v-else-if="submitted && v$.privateForm.country.$invalid" class="p-error">{{v$.privateForm.country.required.$message.replace('Stato', 'Stato')}}</small>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="normalUserOrCompany === 'company'">
                            <div class="grid formgrid justify-content-evenly">
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="address" :class="{'p-error':v$.companyForm.address.$invalid}">Indirizzo Sede Legale *</label>
                                    <InputText type="text" id="address" v-model="v$.companyForm.address.$model" :class="{'p-invalid':v$.companyForm.address.$invalid && submitted}" placeholder="Via/Viale/Piazza (campo obbligatorio)"/>
                                    <small v-if="v$.companyForm.address.$pending.$response" class="p-error">{{v$.companyForm.address.required.$message.replace('Indirizzo Sede Legale', 'Indirizzo Sede Legale')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.address.$invalid" class="p-error">{{v$.companyForm.address.required.$message.replace('Indirizzo Sede Legale', 'Indirizzo Sede Legale')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="city" :class="{'p-error':v$.companyForm.bNum.$invalid}">Numero Civico *</label>
                                    <InputText type="text" id="bNum" v-model="v$.companyForm.bNum.$model" :class="{'p-invalid':v$.companyForm.bNum.$invalid && submitted}" placeholder="Es: 1 (campo obbligatorio)"/>
                                    <small v-if="v$.companyForm.bNum.$pending.$response" class="p-error">{{v$.companyForm.bNum.required.$message.replace('Numero Civico', 'Numero Civico')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.bNum.$invalid" class="p-error">{{v$.companyForm.bNum.required.$message.replace('Numero Civico', 'Numero Civico')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="city" :class="{'p-error':v$.companyForm.city.$invalid}">Città *</label>
                                    <InputText type="text" id="city" v-model="v$.companyForm.city.$model" :class="{'p-invalid':v$.companyForm.city.$invalid && submitted}" placeholder="Città/Comune (campo obbligatorio)"/>
                                    <small v-if="v$.companyForm.city.$pending.$response" class="p-error">{{v$.companyForm.city.required.$message.replace('Città', 'Città')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.city.$invalid" class="p-error">{{v$.companyForm.city.required.$message.replace('Città', 'Città')}}</small>
                                </div>
                            </div>
                            <div class="grid formgrid justify-content-evenly">
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="prov" :class="{'p-error':v$.companyForm.prov.$invalid}">Provincia *</label>
                                    <InputText type="text" id="prov" v-model="v$.companyForm.prov.$model" :class="{'p-invalid':v$.companyForm.prov.$invalid && submitted}" placeholder="Es: MI (sigla provincia campo obbligatorio)"/>
                                    <small v-if="v$.companyForm.prov.$pending.$response" class="p-error">{{v$.companyForm.prov.maxLength.$message.replace('Provincia', 'Provincia')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.prov.$invalid" class="p-error">{{v$.companyForm.prov.maxLength.$message.replace('Provincia', 'Provincia')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="cap" :class="{'p-error':v$.companyForm.cap.$invalid}">CAP *</label>
                                    <InputText type="text" id="cap" v-model="v$.companyForm.cap.$model" :class="{'p-invalid':v$.companyForm.cap.$invalid && submitted}" placeholder="cap (campo obbligatorio)"/>
                                    <small v-if="v$.companyForm.cap.$pending.$response" class="p-error">{{v$.companyForm.cap.maxLength.$message.replace('cap', 'cap')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.cap.$invalid" class="p-error">{{v$.companyForm.cap.maxLength.$message.replace('cap', 'cap')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="country" :class="{'p-error':v$.companyForm.country.$invalid}">Stato *</label>
                                    <InputText type="text" id="country" v-model="v$.companyForm.country.$model" :class="{'p-invalid':v$.companyForm.country.$invalid && submitted}" placeholder="Es: Italia (campo obbligatorio)"/>
                                    <small v-if="v$.companyForm.country.$pending.$response" class="p-error">{{v$.companyForm.country.required.$message.replace('Stato', 'Stato')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.country.$invalid" class="p-error">{{v$.companyForm.country.required.$message.replace('Stato', 'Stato')}}</small>
                                </div>
                            </div>
                            <div class="grid formgrid justify-content-evenly">
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="pIva" :class="{'p-error':v$.companyForm.pIva.$invalid}">Partita IVA *</label>
                                    <InputText type="text" id="pIva" v-model="v$.companyForm.pIva.$model" :class="{'p-invalid':v$.companyForm.pIva.$invalid && submitted}" placeholder="campo obbligatorio"/>
                                    <small v-if="v$.companyForm.pIva.$pending.$response" class="p-error">{{v$.companyForm.pIva.required.$message.replace('PIVA', 'PIVA')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.pIva.$invalid" class="p-error">{{v$.companyForm.pIva.required.$message.replace('PIVA', 'PIVA')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="sdi" :class="{'p-error':v$.companyForm.sdi.$invalid}">SDI/CU *</label>
                                    <InputText type="text" id="sdi" v-model="v$.companyForm.sdi.$model" :class="{'p-invalid':v$.companyForm.sdi.$invalid && submitted}" placeholder="campo obbligatorio"/>
                                    <small v-if="v$.companyForm.sdi.$pending.$response" class="p-error">{{v$.companyForm.sdi.required.$message.replace('SDI', 'SDI')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.sdi.$invalid" class="p-error">{{v$.companyForm.sdi.required.$message.replace('SDI', 'SDI')}}</small>
                                </div>
                            </div>
                            <div class="grid formgrid justify-content-evenly">
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="businessName" :class="{'p-error':v$.companyForm.businessName.$invalid}">Ragione Sociale *</label>
                                    <InputText type="text" id="businessName" v-model="v$.companyForm.businessName.$model" :class="{'p-invalid':v$.companyForm.businessName.$invalid && submitted}" placeholder="campo obbligatorio"/>
                                    <small v-if="v$.companyForm.businessName.$pending.$response" class="p-error">{{v$.companyForm.businessName.required.$message.replace('Ragione Sociale', 'Ragione Sociale')}}</small>
                                    <small v-else-if="submitted && v$.companyForm.businessName.$invalid" class="p-error">{{v$.companyForm.businessName.required.$message.replace('Ragione Sociale', 'Ragione Sociale')}}</small>
                                </div>
                                <div class="sm:col-12 sm:mb-2 lg:col-4 lg:mb-0">
                                    <label for="pec">Pec/Email azienda</label>
                                    <InputText type="email" id="pec" v-model="companyForm.pec" placeholder="campo facoltativo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid formgrid justify-content-center mt-2">
                        <div class="sm:col-6 sm:mb-2 lg:col-2 lg:mb-0">
                            <Button v-if="readonly === 1" label="Dashboard" @click="goToDash" class="btnGrey" />
                            <Button v-else label="Dashboard" class="btnGrey" disabled="disabled" />
                        </div>
                        <div class="sm:col-6 sm:mb-2 lg:col-2 lg:mb-0">
                            <Button label="Salva" class="btnGreen" type="submit" />
                        </div>
                    </div>
                </form>    
            </div>
        </div>
        <Toast />
    </div>
</template>

<script>
import { required, helpers, minLength, maxLength, alpha, numeric } from "@vuelidate/validators/dist/raw.esm";
import { useVuelidate } from "@vuelidate/core";
import { mapActions, mapState } from 'vuex';
import axios from 'axios';

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            token: '',
            success: '',
            message: '',
            normalUserOrCompany: '',
            privateForm: {
                address: '',
                bNum: null,
                city: '',
                prov: '',
                cap: null,
                country: ''
            },
            companyForm: {
                address: '',
                bNum: null,
                city: '',
                prov: '',
                cap: null,
                country: '',
                pIva: '',
                sdi: null,
                businessName: '',
                pec: ''
            },
            readonly: null,
            submitted: false,
            privateBox: null,
            companyBox: null
        }
    },
    validations() {
        if(this.normalUserOrCompany === 'normalUser') {
            return {
                privateForm: {
                    address: { required: helpers.withMessage('Inserire solo via/piazza', required) },
                    bNum: { required: helpers.withMessage('Inserire il numero civico', required) },
                    city: { required: helpers.withMessage('Inserire la città', required) },
                    prov: { required, alpha, minLength: minLength(2), maxLength: helpers.withMessage('Inserire la sigla della provincia, massimo 2 caratteri', maxLength(2)) },
                    cap: { required, minLength: minLength(5), maxLength: helpers.withMessage('Inserire il CAP, massimo 5 cifre', maxLength(5)) },
                    country: { required: helpers.withMessage('Inserire lo stato', required) }
                }
            }
        } else {
            return {
                companyForm: {
                    address: { required: helpers.withMessage('Inserire solo via/piazza', required) },
                    bNum: { required: helpers.withMessage('Inserire il numero civico', required) },
                    city: { required: helpers.withMessage('Inserire la città', required) },
                    prov: { required, alpha, minLegnth: minLength(2), maxLength: helpers.withMessage('Inserire la sigla della provincia, massimo 2 caratteri', maxLength(2)) },
                    cap: { required, minLength: minLength(5), maxLength: helpers.withMessage('Inserire il CAP, massimo 5 cifre', maxLength(5)) },
                    country: { required: helpers.withMessage('Inserire lo stato', required) },
                    pIva: { required: helpers.withMessage('Inserire la PIVA', required) },
                    sdi: { required: helpers.withMessage('Inserire SDI', required) },
                    businessName: { required: helpers.withMessage('Inserire la ragione sociale', required) }
                }
            }
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['setUserProfileCompleted']),
        updatePersonalData(isFormValid) {
            this.submitted = true;

            if(!isFormValid) {
                console.log('valore isFormValid');
                console.log(isFormValid);
                console.log(this.privateForm);
                if(!this.normalUserOrCompany) {
                    this.$toast.add({ severity: 'warn', summary: 'Devi scegliere "Privato" oppure "Soggetto con Partita IVA" per poter proseguire', life: 3000 });
                    return;
                } else {
                    this.$toast.add({ severity: 'warn', summary: `E' necessario compilare correttamente tutti i campi per poter salvare e proseguire`, life: 3000 });
                    return;
                }
            } else {
                let data;
                console.log(this.privateForm);
                if(this.normalUserOrCompany === 'normalUser') {
                    data = this.privateForm;
                } else if(this.normalUserOrCompany === 'company') {
                    data = this.companyForm;
                } else {
                    this.$toast.add({ severity: 'warn', summary: 'Devi selezionare un opzione per proseguire!', life: 3000 });
                    return;
                }
                const obj = {
                    userID: this.user.id,
                    email: this.user.email,
                    mobile: this.user.mobile,
                    userOrCompany: this.normalUserOrCompany,
                    data: data
                }
            
                axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/updatePD', obj, { headers: { 'x-access-token': this.token } })
                .then((res) => {
                    console.log(res.data);
                    this.success = res.data.success;
                    this.message = res.data.message;
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    if(this.success) {
                        this.setUserProfileCompleted(true);
                        this.readonly = 1;
                        this.$store.state.user.readonly = 1;
                        this.$toast.add({ severity: 'success', summary: this.message, life: 2000 });
                        setTimeout(() => {
                            if(this.user['has_active_sub'] === 0) {
                                this.$router.push('/courses');
                            } else {
                                this.$router.push('/userResults');
                            }
                        }, 2500);
                    } else {
                        this.$toast.add({ severity: 'error', summary: this.message, life: 2000 });
                    }
                    console.log('chiamata terminata da fn updatePersonalData');
                });
            }
            
        },
        goToDash() {
            if(this.user['has_active_sub'] === 0) {
                this.$router.push('/courses');
            } else {
                this.$router.push('/userResults');
            }
        },
        getUserData(id) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userDetails', { params: { id: id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.normalUserOrCompany = res.data.user.custom_fields;
                    if(this.normalUserOrCompany === 'normalUser') {
                        this.privateForm.address = res.data.user.address;
                        this.privateForm.bNum = res.data.user.bNum;
                        this.privateForm.city = res.data.user.city;
                        this.privateForm.prov = res.data.user.prov;
                        this.privateForm.cap = res.data.user.cap;
                        this.privateForm.country = res.data.user.country;
                    } else {
                        this.companyForm.address = res.data.user.address;
                        this.companyForm.bNum = res.data.user.bNum;
                        this.companyForm.city = res.data.user.city;
                        this.companyForm.prov = res.data.user.prov;
                        this.companyForm.cap = res.data.user.cap;
                        this.companyForm.country = res.data.user.country;
                        this.companyForm.pIva = res.data.user.pIva;
                        this.companyForm.sdi = res.data.user.sdi;
                        this.companyForm.businessName = res.data.user.businessName;
                        this.companyForm.pec = res.data.user.pec;
                    }
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata da fn getUserData');
            });
        },
        resetOtherBox(event, value) {
            console.log(event, value);
            if(value === 'normalUser') {
                this.companyForm.address = '';
                this.companyForm.bNum = '';
                this.companyForm.city = '';
                this.companyForm.prov = '';
                this.companyForm.cap = '';
                this.companyForm.country = '';
                this.companyForm.pIva = '';
                this.companyForm.sdi = '';
                this.companyForm.businessName = '';
                this.companyForm.pec = '';

            } else if(value === 'company') {
                this.privateForm.address = '';
                this.privateForm.bNum = '';
                this.privateForm.city = '';
                this.privateForm.prov = '';
                this.privateForm.cap = '';
                this.privateForm.country = '';
            }
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.readonly = this.$store.state.user.readonly;
        if(this.readonly === 0 && !this.$store.state.userProfileComplete) {
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 5000,
                timerProgressBar: true,
                title: 'Per favore completa il tuo profilo! I dati verranno usati per la fatturazione. Grazie',
                showConfirmButton: false,
                icon: 'info'
            });
        } else if(this.readonly === 0 && this.$store.state.userProfileComplete) {
            // devo recuperare i dati dell utente dal db
            // non posso prenderli nello store perchè l'utente non ha fatto logout quindi non ci sono in vuex
            this.readonly = 1;
            this.getUserData(this.$store.state.user.id);
            console.log(this.normalUserOrCompany);
        } else {
            console.log('sono nel caso in cui ho fatto un nuovo login e readonly è = 1');
            this.getUserData(this.$store.state.user.id);
        }
    }
}
</script>

<style>

</style>
