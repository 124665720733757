import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import router from '../router/index'
import axios from 'axios'
import sweet from 'vue-sweetalert2'
import VueSweetalert2 from 'vue-sweetalert2';

const LOGIN_URL = process.env.VUE_APP_API_URL + '/api/v1/auth/login';
const REGISTER_URL = process.env.VUE_APP_API_URL + '/api/v1/auth/register';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = createStore({
  state: {
    user: {
      id: '',
      firstname: '',
      lastname: '',
      username: '',
      role: '',
      status: '',
      force_password_change: null
    },
    "x-access-token": null,
    logged: false,
    userProfileComplete: false,
    areThereUserActiveSubs: false,
    loginError: null,
    selCourseName: '',
    selCourseID: null,
    selEdtId: null,
    selUSubId: null,
    selSubID: null,
    selSubPrice: null,
    selQuestionID: null,
    selQuizID: null,
    selAttemptID: null,
    selUsIDforCharts: null,
    selCourseIDforCharts: null
  },
  mutations: {
    // qui si definiscono i cambiamenti di stato 
    // in base a delle azioni che vengono definite dopo
    defineUser: (state, user) => {
      state.user = user;
    },
    loginStart: (state) => state.logged = true,
    loginStop: (state, errorMessage) => {
      state.logged = false;
      state.loginError = errorMessage;
    },
    updateXAccessToken: (state, token) => {
      state['x-access-token'] = token;
    },
    changeUserStatus: (state, status) => {
      state.user.status = status;
    },
    changeUserHasActiveSub: (state, status) => {
      state.user.has_active_sub = status;
    }, 
    existsActiveSubs: (state, boolean) => {
      state.areThereUserActiveSubs = boolean;
    },
    userProfileCompleted: (state, boolean) => {
      state.userProfileComplete = boolean;
    },
    saveActualCName: (state, cName) => {
      state.selCourseName = cName;
    },
    saveActualCourseId: (state, id) => {
      state.selCourseID = id;
    },
    saveActualEdtId: (state, id) => {
      state.selEdtId = id;
    },
    saveActualUSubId: (state, id) => {
      state.selUSubId = id;
    },
    saveActualSubID: (state, id) => {
      state.selSubID = id;
    },
    saveActualSubPrice: (state, price) => {
      state.selSubPrice = price;
    },
    saveActualQuestionID: (state, id) => {
      state.selQuestionID = id;
    },
    saveActualQuizID: (state, id) => {
      state.selQuizID = id;
    },
    saveActualAttemptID: (state, id) => {
      state.selAttemptID = id;
    },
    saveActualUsIDforCharts: (state, id) => {
      state.selUsIDforCharts = id;
    },
    saveActualCourseIDforCharts: (state, id) => {
      state.selCourseIDforCharts = id;
    },
    logout: (state) => {
      state['x-access-token'] = null,
      state.logged = false,
      state.areThereUserActiveSubs = false,
      state.userProfileComplete = false,
      state.user = {
        id: '',
        firstname: '',
        lastname: '',
        username: '',
        role: '',
        status: '',
        force_password_change: null,
        has_active_sub: 0,
        readonly: 0,
        address: '',
        bNum: null,
        city: '',
        cap: null,
        prov: '',
        country: '',
        pIva: null,
        sdi: null,
        businessName: '',
        pec: '',
        custom_fields: '',
        trialSubUsed: 0
      },
      state.selCourseName = '',
      state.selCourseID = null,
      state.selEdtId = null,
      state.selUSubId = null
      state.selSubID = null,
      state.selSubPrice = null,
      state.selQuestionID = null,
      state.selQuizID = null,
      state.selAttemptID = null,
      state.selUsIDforCharts = null,
      state.selCourseIDforCharts = null
    }
  },
  actions: {
    // qui vengono definite delle azioni che cambiano lo stato
    // attivano la mutations
    async doLogin({ commit }, data) {
      /* console.log('dati da actions store');
      console.log(data); */
      console.log(LOGIN_URL);
      console.log('variable VUE_APP_API_URL:', process.env.VUE_APP_API_URL);

      return axios.post(LOGIN_URL, data)
      .then((res) => {
        console.log('dati dalla risposta della chiamata axios in actions');
        console.log(res.data.user['force_password_change']);
        console.log('valore user has_active_sub @@@@@@@@@@@@');
        console.log(res.data.user.has_active_sub);
        console.log('valore user readonly ############');
        console.log(res.data.user.readonly);
        localStorage.setItem('x-access-token', res.data.token);
        localStorage.setItem("selCourseName", '');
        localStorage.setItem("selCourseID", null);
        localStorage.setItem("selEdtId", null);
        localStorage.setItem("selUSubId", null);
        localStorage.setItem("selSubID", null);
        localStorage.setItem("selSubPrice", null);
        localStorage.setItem("selQuestionID", null);
        localStorage.setItem("selQuizID", null);
        localStorage.setItem("selAttemptID", null);
        localStorage.setItem("selUsIDforCharts", null);
        localStorage.setItem("selCourseIDforCharts", null);
        commit('loginStart');
        commit('defineUser', res.data.user);
        /* commit('loginStop', null); */
        commit('updateXAccessToken', res.data.token);
        // valutiamo il ruolo della persona che si sta loggando
        // se user o admin e decidiamo dove fare il redirect
        // valutiamo anche se l'utente ha già cambiato la pwd 
        // oppure è al primo accesso
        if(res.data.user.status === 0) {
          throw new Error('Attenzione Login Fallito! Contattare amministratore del sito per ulteriori chiarimenti!');
        } else {
          if(res.data.user.role === "user" && res.data.user['force_password_change'] === 1) {
            localStorage.setItem("force_password_change", res.data.user['force_password_change']);
            // l'utente ha già cambiato la pwd
            // dobbiamo controllare se ha sub attive
            if(res.data.user['has_active_sub'] === 0) {
              // l'utente non ha ancora sub attive
              router.push('/courses');
            } else {
              // VECCHIA VERSIONE
              // viene mandato alla pagina dei suoi risultati fino a questo momento
              // NUOVA VERSIONE
              router.push('/userCourseChoice');
            }
            
          } else if(res.data.user.role === "user" && res.data.user['force_password_change'] === 0) {
            localStorage.setItem("force_password_change", res.data.user['force_password_change']);
            // l'utente è al primo accesso
            // DEVE cambiare la pwd
            router.push('/changePwd');
  
          } else if(res.data.user.role === "admin") {
            router.push('/dashboard');
          } 
        }
        
      })
      .catch((error) => {
        //console.log(error);
        commit('loginStop', 'ATTENZIONE! I dati inseriti non sono corretti.');
        commit('updateXAccessToken', null);
        commit('logout');
        return this.state.loginError;
      })
    },
    updateToken(context, refreshedTkn) {
      localStorage.removeItem("x-access-token");
      localStorage.setItem("x-access-token", refreshedTkn)
      context.commit('updateXAccessToken', refreshedTkn);
    },
    changeStatus(context, status) {
      context.commit("changeUserStatus", status);
    },
    changeUserHasActiveSub(context, status) {
      context.commit("changeUserHasActiveSub", status);
    },
    setExistsActiveSubs(context, status) {
      context.commit("existsActiveSubs", status);
    },
    setUserProfileCompleted(context, status) {
      context.commit("userProfileCompleted", status);
    },
    setCourseName(context, status) {
      localStorage.removeItem("selCourseName");
      localStorage.setItem("selCourseName", status);
      context.commit("saveActualCName", status);
    },
    setCourseId(context, status) {
      localStorage.removeItem("selCourseID");
      localStorage.setItem("selCourseID", status);
      context.commit("saveActualCourseId", status);
    },
    setEdtId(context, status) {
      localStorage.removeItem("selEdtId");
      localStorage.setItem("selEdtId", status);
      context.commit("saveActualEdtId", status);
    },
    setUSubId(context, status) {
      localStorage.removeItem("selUSubId");
      localStorage.setItem("selUSubId", status);
      context.commit("saveActualUSubId", status);
    },
    setSubID(context, status) {
      localStorage.removeItem("selSubID");
      localStorage.setItem("selSubID", status);
      context.commit("saveActualSubID", status);
    },
    setSubPrice(context, status) {
      localStorage.removeItem("selSubPrice");
      localStorage.setItem("selSubPrice", status);
      context.commit("saveActualSubPrice", status);
    },
    setQuestionID(context, status) {
      localStorage.removeItem("selQuestionID");
      localStorage.setItem("selQuestionID", status);
      context.commit("saveActualQuestionID", status);
    },
    setQuizID(context, status) {
      localStorage.removeItem("selQuizID");
      localStorage.setItem("selQuizID", status);
      context.commit("saveActualQuizID", status);
    },
    setAttemptID(context, status) {
      localStorage.removeItem("selAttemptID");
      localStorage.setItem("selAttemptID", status);
      context.commit("saveActualAttemptID", status);
    },
    setUsIDforCharts(context, status) {
      localStorage.removeItem("selUsIDforCharts");
      localStorage.setItem("selUsIDforCharts", status);
      context.commit("saveActualUsIDforCharts", status);
    },
    setCourseIDforCharts(context, status) {
      localStorage.removeItem("selCourseIDforCharts");
      localStorage.setItem("selCourseIDforCharts", status);
      context.commit("saveActualCourseIDforCharts", status);
    },
    logout({ commit }) {
      localStorage.removeItem('x-access-token');
      localStorage.removeItem("areThereUserActiveSubs");
      localStorage.removeItem("userProfileComplete");
      localStorage.removeItem("selCourseName");
      localStorage.removeItem("selCourseID");
      localStorage.removeItem("selEdtId");
      localStorage.removeItem("selUSubId");
      localStorage.removeItem("selSubID");
      localStorage.removeItem("selSubPrice");
      localStorage.removeItem("selQuestionID");
      localStorage.removeItem("selQuizID");
      localStorage.removeItem("selAttemptID");
      localStorage.removeItem("selUsIDforCharts");
      localStorage.removeItem("selCourseIDforCharts");
      localStorage.removeItem('vuex');
      commit('logout');
      router.push('/');
    }
  },
  plugins: [vuexLocal.plugin],
  getters: {
    
  },
  modules: {}
});

export default store;
