<template>
    <div v-if="user.role === 'admin'">
        <div class="menuBarBox">
            <Menubar :model="menuAdmin" class="myBgColor flex justify-content-around">
                <template #menuAdmin="{item}">
                    <router-link :to="item.to">{{ item.label }}</router-link>
                </template>
            </Menubar>
        </div>
        <div v-if="loading">
            <ProgressSpinner />
        </div> 
        <div v-else>
            <div v-if="getValues.success">
                <div class="btnToolbar">
                    <Toolbar>
                        <template #start>
                            <Button icon="pi pi-user-plus" class="btnLightBlue" @click="goToAddNewUser" />
                        </template>
                        <template #end>
                            <Button icon="pi pi-download" class="btnGrey" @click="exportData" />
                        </template>
                    </Toolbar>
                </div>
                <div class="overallStatsBox">
                    <!-- clientiIscritti: 152
                    sottoscrizioniAttive: 47
                    sottoscrizioniNonIniziate: 6
                    sottoscrizioniSospese: 0
                    sottoscrizioniTerminate: 143
                    sottoscrizioniTotali: 196 -->
                    <div>clienti iscritti: {{ overallStats.clientiIscritti }}</div>
                    <div>sub attive: {{ overallStats.sottoscrizioniAttive }}</div>
                    <div>sub non iniziate: {{ overallStats.sottoscrizioniNonIniziate }}</div>
                    <div>sub sospese: {{ overallStats.sottoscrizioniSospese }}</div>
                    <div>sub terminate: {{ overallStats.sottoscrizioniTerminate }}</div>
                </div>
                <div class="filtersRowD">
                    <form @submit.prevent="getAllUsersSubs()" class="filterBox grid justify-content-around align-items-center">
                        <div class="form-group ml-2 sm:col-12 md:col-6 lg:col-2">
                            <InputText type="text" placeholder="Filtra per cognome" v-model="filters.name" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2">
                            <Dropdown v-model="filters.subType" :options="subTypeOpts" optionLabel="sub_type" optionValue="sub_type" placeholder="Filtra per tipo" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2">
                            <Dropdown v-model="filters.stateSub" :options="subStateOpts" optionLabel="state" optionValue="state" placeholder="Filtra per stato" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2">
                            <Dropdown v-model="filters.payStatus" :options="payStatusOpts" optionLabel="status" optionValue="status" placeholder="Filtra per pagamento" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2">
                            <Dropdown v-model="filters.payMethod" :options="payMethodOpts" optionLabel="metodo" optionValue="method" placeholder="Filtra per metodo" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2">
                            <Button label="FILTRA" class="myBtnBgGreen mr-2 mb-2" type="submit" />
                            <Button label="ANNULLA" class="myBtnBgBlue mb-2" @click="resetFilters" />
                        </div>
                    </form>
                </div>    
                <div class="filtersRowM">
                    <form @submit.prevent="getAllUsersSubs()" class="filterBox">
                        <div class="form-group sm:col-12 md:col-6 lg:col-2 mb-2">
                            <InputText type="text" placeholder="Filtra per cognome" v-model="filters.name" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2 mb-2">
                            <Dropdown v-model="filters.subType" :options="subTypeOpts" optionLabel="sub_type" optionValue="sub_type" placeholder="Filtra per tipo" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2 mb-2">
                            <Dropdown v-model="filters.stateSub" :options="subStateOpts" optionLabel="state" optionValue="state" placeholder="Filtra per stato" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2 mb-2">
                            <Dropdown v-model="filters.payStatus" :options="payStatusOpts" optionLabel="status" optionValue="status" placeholder="Filtra per pagamento" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2 mb-4">
                            <Dropdown v-model="filters.payMethod" :options="payMethodOpts" optionLabel="metodo" optionValue="method" placeholder="Filtra per metodo" class="dimCustom" />
                        </div>
                        <div class="form-group sm:col-12 md:col-6 lg:col-2">
                            <Button label="FILTRA" class="myBtnBgGreen mr-2" type="submit" />
                            <Button label="ANNULLA" class="myBtnBgBlue" @click="resetFilters" />
                        </div>
                    </form>
                </div>    
                <!-- <div v-if="loading">
                    <ProgressSpinner />
                </div> -->
                <DataTable :value="allUsersSubs" responsiveLayout="stack" breakpoint="1370px">
                    <Column header="Discente">
                        <template #body="slotProps">
                            <span class="capitalize mr-2">{{slotProps.data.firstname}}</span>
                            <span class="uppercase">{{slotProps.data.lastname}}</span>
                        </template>
                    </Column>
                    <Column field="courseName" header="Corso"></Column>
                    <Column field="editionNum" header="Edizione"></Column>
                    <Column field="sub_type" header="Tipo sottoscrizione"></Column>
                    <Column field="sub_state" header="Stato sottoscrizione"></Column>
                    <Column field="price" header="Prezzo in €"></Column>
                    <Column field="paymentProgress" header="Stato pagamento"></Column>
                    <Column field="paymentMethod" header="Modalità pagamento"></Column>
                    <Column field="days_duration" header="Durata in GG"></Column>
                    <Column field="start_date" header="Attivazione"></Column>
                    <Column field="end_date" header="Termine"></Column>
                    <Column :exportable="false" style="min-width:8rem">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.sub_state === 'attiva'" icon="pi pi-pencil" class="p-button-rounded btnGreen mr-2" @click="showEditSub(parseInt(slotProps.data.id))" title="modifica" />
                            <Button v-else-if="slotProps.data.sub_state === 'non iniziata'" icon="pi pi-pencil" class="p-button-rounded btnBlue mr-2" @click="showEditSub(parseInt(slotProps.data.id))" title="modifica" />
                            <Button v-else-if="slotProps.data.sub_state === 'sospesa'" icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="showEditSub(parseInt(slotProps.data.id))" title="modifica" />
                            <Button v-else icon="pi pi-pencil" class="p-button-rounded btnRed mr-2" @click="showEditSub(parseInt(slotProps.data.id))" title="modifica" />
                            <Button v-if="slotProps.data.sub_state === 'attiva'" icon="pi pi-chart-line" class="p-button-rounded btnLightBlue mr-2" @click="goToUserResultsCharts(slotProps.data.user_id, slotProps.data.courseID, slotProps.data.firstname, slotProps.data.lastname)" title="grafici" />
                            <Button v-else icon="pi pi-chart-line" class="p-button-rounded btnLightBlue" disabled="disabled" />
                        </template>
                    </Column>
                </DataTable>
                <div v-if="pages !== 1" class="myPaginator">
                    <div v-if="previous.page" class="arrowBox" @click="getAllUsersSubs(parseInt(previous.page), parseInt(limit))"><i class="pi pi-angle-left"></i></div>
                    <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
                    <div class="pagesBox">
                        <span>PAGINA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
                    </div>
                    <div v-if="next.page" class="arrowBox" @click="getAllUsersSubs(parseInt(next.page), parseInt(limit))"><i class="pi pi-angle-right"></i></div>
                    <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
                </div>
            </div>
            <div v-else>
                <h2>{{ getValues.message }}</h2>
                <Button icon="pi pi-plus" label="INVITA NUOVO DISCENTE" @click="goToAddNewUser" />
            </div>    
            <Toast />
        </div>
    </div>
    <div v-else-if="user.role === 'user'">
        <h3>Pagina in creazione</h3>
        <router-link to="/">VAI ALLA HOME</router-link>
    </div>
</template>


<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            loading: false,
            token: '',
            allUsersSubs: [],
            userSubs: [],
            filtersAreActive: false,
            overallStats: {},
            getValues: {
                success: '',
                message: ''
            },
            subTypeOpts: [],
            subTypeOptsOld: [
                {type: '10 giorni Prova', label: '10 giorni Prova'},
                {type: '30 giorni', label: '30 giorni'},
                {type: '90 giorni', label: '90 giorni'},
                {type: '180 giorni', label: '180 giorni'},
                {type: '365 giorni', label: '365 giorni'}
            ],
            subStateOpts: [
                {state: 'non iniziata'},
                {state: 'sospesa'},
                {state: 'attiva'},
                {state: 'terminata'}
            ],
            payStatusOpts: [
                {status: 'in attesa'},
                {status: 'pagato'}
            ],
            payMethodOpts: [
                { metodo: 'Nessuno', method: 'gratis'},
                { metodo: 'Carta di credito', method: 'cc'},
                { metodo: 'Bonifico', method: 'bonifico'}
            ],
            filters: {
                name: '',
                subType: '',
                stateSub: '',
                payStatus: '',
                payMethod: ''
            },
            filterResult: {
                success: '',
                message: ''
            },
            currentPage: 1,
            page: 1,
            first: 0,
            limit: 10,
            totalRecords: null,
            next: {
                page: null,
                limit: null
            },
            previous: {
                page: null,
                limit: null
            },
            pages: null,
            totalUsersWithSub: null,
            numUserActiveSubs: null,
            menuAdmin: [
                {
                    label: 'Lista Utenti',
                    to: '/users',
                },
                {
                    label: 'Lista Percorsi',
                    to: '/courses',
                },
                {
                    label: 'Lista Domande',
                    to: '/questions',
                },
                {
                    label: 'Quiz',
                    to: '/quiz',
                },
                {
                    label: 'Risultati Quiz',
                    to: '/quizdone',
                }
            ],
            menuUserWithSub: [
                {
                    label: 'Lista Percorsi',
                    to: '/courses',
                },
                {
                    label: 'Cosa vuoi fare oggi',
                    to: '/quiz',
                },
                {
                    label: 'I tuoi risultati',
                    to: '/userResults',
                },
                {
                    label: 'Le tue risorse'
                }    
            ],
            menuUserNoSub: [
                {
                    label: 'Lista Percorsi',
                    to: '/courses',
                },
                {
                    label: 'Cosa vuoi fare oggi',
                    command: () => {
                        this.$toast.add({ severity: 'warn', summary: 'ATTENZIONE, ACCESSO NEGATO', detail: 'Area non accessibile, in quanto non hai sottoscrizioni attive!', life: 3000 });
                    }
                },
                {
                    label: 'I tuoi risultati',
                    command: () => {
                        this.$toast.add({ severity: 'warn', summary: 'ATTENZIONE, ACCESSO NEGATO', detail: 'Area non accessibile, in quanto non hai sottoscrizioni attive!', life: 3000 });
                    }
                },
                {
                    label: 'Le tue risorse'
                }    
            ]
        }
    },
    methods: {
        ...mapActions(['setUSubId', 'setEdtId', 'setExistsActiveSubs', 'setUsIDforCharts', 'setCourseIDforCharts']),
        getAllUsersSubs(page = 1, limit = 10) {
            this.loading = true;
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            if(this.filters.name || this.filters.subType || this.filters.stateSub || this.filters.payStatus || this.filters.payMethod) {
                console.log('c è almeno un campo dei filtri selezionato o compilato');
                this.filtersAreActive = true;
                console.log(this.filtersAreActive);
            } else {
                console.log('nessun campo compilato');
                console.log(this.filtersAreActive);
            }
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/allUserSubs', { filtering: this.filtersAreActive, filters: this.filters },{ params: { page: page, limit: limit }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.allUsersSubs = res.data.allUserSubs;
                    this.getValues.success = res.data.success;
                    this.pages = res.data.pages;
                    this.totalUsersWithSub = res.data.totalItems;
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                } else {
                    if(res.data.info) {
                        this.filterResult.success = false;
                        this.filterResult.message = res.data.message;
                        this.allUsersSubs = [];
                    } else {
                        this.getValues.message = res.data.message;
                        this.getValues.success = res.data.success;
                    }
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                if(this.filterResult === false) {
                    this.$toast.add({ severity: 'warn', summary: this.filterResult.message, life: 3000 });
                } else if(this.filterResult === true) {
                    this.$toast.add({ severity: 'success', summary: this.filterResult.message, life: 1500 });
                }
                console.log('chiamata terminata');
            });
        },
        resetFilters() {
            this.filters.name = '';
            this.filters.subType = '';
            this.filters.stateSub = '';
            this.filters.payStatus = '';
            this.filters.payMethod = '';
            this.filtersAreActive = false;
            this.getAllUsersSubs();
        },
        goToAddNewUser() {
            this.$router.push('/newuser');
        },
        exportData() {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/exportData', this.filters, { headers: { 'x-access-token': this.token } })
              .then((res) => {
                console.log('exportData: RESPONSE');
                console.log(res.data);
                if(res.data.success) {
                  if (res.data.redirect) {
                    window.open( res.data.redirect, "_blank");
                    return;
                  } else {
                    this.$toast.add({ severity: 'warn', summary: 'ERRORE', detail:'Il link di download non è stato generato', life: 2500 });
                  }
                } else {
                  this.$toast.add({ severity: 'warn', summary: 'ERRORE', detail: res.data.message || 'Si è verificato un errore', life: 2500 });
                }
              })
              .catch((err) => {
                console.error(err.toString());
              })
              .finally(() => {
                console.log('exportData: FINISHED');
              });

        },
        goToUserResultsCharts(usID, courseID, fname, lname) {

            this.$router.push({ name: 'UserResults', query: { userID: usID, courseID: courseID, firstname: fname, lastname: lname } });

        },
        getSubTypesList() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/subTypesList',{ headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.subTypeOpts = res.data.subTypesList;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                console.log('chiamata terminata da fn getSubTypesList');
            });
        },
        getStatsForAdmin() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/statsForAdmin', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    // stats recuperate
                    this.overallStats = res.data.overallStats;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                console.log('chiamata terminata da fn getStatsForAdmin');
            });
        },
        // SEZIONE FUNZIONE LATO USER
        getUserSub(id) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userSubs', { params: { userId: id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.userSubs = res.data.userSubs;
                    this.getValues.success = res.data.success;
                } else {
                    this.getValues.message = res.data.message;
                    this.getValues.success = res.data.success;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata da fn getUserSub');
            });
        },
        countUserSub(id) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/userActiveSubs', { id: id }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.numUserActiveSubs = res.data.numActiveSubs;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.numUserActiveSubs === 0) {
                    // scrivere nel vuex false in corrispondenza della voce se esistono sub attive
                    console.log('valore store.state.areThereUserActiveSubs');
                    console.log(this.$store.state.areThereUserActiveSubs);
                    this.setExistsActiveSubs(false);
                } else if(this.numUserActiveSubs > 0) {
                    // scrivere nel vuex true
                    this.setExistsActiveSubs(true);
                    console.log('valore store.state.areThereUserActiveSubs');
                    console.log(this.$store.state.areThereUserActiveSubs);
                }
                console.log('chiamata terminata da fn countUserSub');
            });
        },
        showEditSub(USubId) {
            this.setUSubId(USubId);
            this.$router.push({ name: 'EditSub', params: { USubId: parseInt(this.$store.state.selUSubId)} });
        },
        goToPrivateCourseArea(EdId) {
            this.setEdtId(EdId);
            this.$router.push({ name: 'PrivateAreaHome', params: { EdId: parseInt(this.$store.state.selEdtId)} });
        }
    },
    computed: {
        ...mapState(['user'])
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        console.log(this.$route.query);
        if(this.user.role === 'admin') {
            this.getAllUsersSubs();
            this.getSubTypesList();
            this.getStatsForAdmin();
        } else if(this.user.role === 'user') {
            if(this.$store.state.user['force_password_change'] === 0) {
                this.$router.push('/changepwd');
            } else {
                this.getUserSub(this.user.id);
                this.countUserSub(this.user.id);
            }
        }
    }
}
</script>

<style scoped>

.menuBarBox >>> .p-menuitem-text  {
  color: white !important;
}

.myBgColor{
  background-color: #004b87 !important;
}

.cardBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 50px 0;
}
.p-card {
    width: 700px;
    padding: 50px;
    margin-bottom: 25px;
    border-radius: 20px !important;
    background-color: #ffffff;
    color: #101010;
}
/* .myPaginator {
    background-color: #2a323d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8dd0ff;
    padding: 0.75rem;
}
.arrowBox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3f4b5b;
    color: #8dd0ff;
    height: 2.357rem;
    min-width: 2.357rem;
    cursor: pointer;
}
.arrowBox:hover {
    background: rgba(255, 255, 255, 0.04);
}
.myBtnDisabled {
    opacity: 0.65;
    cursor: unset;
}
.myBtnDisabled:hover {
    background: none;
}

.pagesBox {
    display: flex;
    align-items: center;
    border-top: 1px solid #3f4b5b;
    border-bottom: 1px solid #3f4b5b;
    color: #8dd0ff;
    height: 2.357rem;
    min-width: 2.357rem;
    padding: 0 0.5rem;
} */
.overallStatsBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.filtersRowD {
    padding: 30px 0;
}
.filtersRowM {
    display: none;
}
.filterBox {
    margin: 0;
}
/* sezione stile bottoni pagina */
.myBtnBgGreen {
    background-color: #017632 !important;
    border: none !important;
    color: #eaeaea !important;
}
.myBtnBgGreen:enabled:hover {
    background-color: #037f36;
}
.myBtnBgBlue {
    background-color: #009bca !important;
    border: none !important;
    color: #eaeaea !important;
}
.myBtnBgBlue:enabled:hover {
    background-color: #009bca;
}

/* sezione dimensione input e dropdown */
.dimCustom {
    width: 100%;
}

/* sezione modifica input */
input {
    background: white !important;
}

@media screen and (max-width: 960px) {
    .menuBarBox >>> .p-menuitem-text  {
        color: #212529 !important;
    }
    .menuBarBox >>> .p-menubar-button {
        color: #fff !important;
    }
}

@media screen and (max-width: 575px) {
    .filtersRowD {
        display: none;
    }
    .filtersRowM {
        display: block;
        padding: 30px 0;
    }
    .dimCustom {
        width: 90%;
    }
}
</style>
