<template>
    <div class="dView">
        <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-10 lg:w-10 mx-auto my-6">
            <h3 class="mb-5 bgBlue">Riepilogo Dati Sottoscrizione</h3>
            <form @submit.prevent="subToCourse">
                <div class="grid mt-3">
                    <div class="sm:col-12 md:col-6 lg:col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">TIPO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.sub_type">
                        </div>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">COSTO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.price">
                            <div class="input-group-prepend">
                                <span class="input-group-text">€</span>
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">DURATA</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.days_duration">
                            <div class="input-group-prepend">
                                <span class="input-group-text">GG</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="sm:col-12 md:col-6 lg:col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">CORSO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.courseName">
                        </div>
                    </div>
                    <div class="sm:col-12 md:col-6 lg:col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">EDIZIONE</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.editionNum">
                        </div>
                    </div>
                </div>
                <div v-if="summary.days_duration === 10 || summary.days_duration === 7" class="grid">
                    <div class="sm:col-6 md:col-6 lg:col-6">
                        <router-link to="/subs" class="btn btnGrey">INDIETRO</router-link>
                    </div>
                    <div class="sm:col-6 md:col-6 lg:col-6">
                        <button v-if="trialBtn === 'on'" class="btn btnGreen">ATTIVA</button>
                        <button v-else-if="trialBtn === 'off'" class="btn btnGreen" disabled>ATTIVA</button>
                    </div>
                </div>
            </form>
            <div v-if="summary.days_duration !== 7" class="grid">
                <div class="sm:col-12 md:col-6 lg:col-4">
                    <router-link to="/subs" class="btn btnGrey">INDIETRO</router-link>
                </div>
                <div class="sm:col-12 md:col-6 lg:col-4">
                    <button v-if="payWithStripeBtn === 'on'" class="btn btnGreen" @click="checkout(summary.subID, summary.sub_type, summary.price, summary.days_duration)">PAGA E ATTIVA</button>
                    <button v-else-if="payWithStripeBtn === 'off'" class="btn btnGreen" disabled>PAGA E ATTIVA</button>
                </div>
                <div class="sm:col-12 md:col-6 lg:col-4">
                    <button v-if="payWithBankTransBtn === 'on'" class="btn btnLightBlue" @click="paymentByBT">PAGA CON BONIFICO</button>
                    <button v-else-if="payWithBankTransBtn === 'off'" class="btn btnLightBlue" disabled>PAGA CON BONIFICO</button>
                </div>
            </div>
        </div>
        <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-10 lg:w-10 mx-auto my-6 bWarning">
            <p>
                L'attivazione dell'<span class="txtRed"><b>APP</b></span> <b>ESAMIFINANZA</b> tramite bonifico è immediatamente attiva,<br>
                si prega di effettuare il bonifico, dell'importo relativo al periodo di sottoscrizione scelto, nella giornata di sottoscrizione con i seguenti dati:
            </p>
            <p>
                BENEFICIARIO: <b>ESAMIFINANZA SRL</b><br>
                CAUSALE: <b>SOTTOSCRIZIONE APP ESAMIFINANZA</b><br>
                IBAN: <b>IT42H0306901798100000006466</b>
            </p>
        </div>
    </div>
    <div class="mView">
        <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-full lg:w-10 mx-auto my-6">
            <h3 class="mb-5 bgBlue">Riepilogo Dati Sottoscrizione</h3>
            <form @submit.prevent="subToCourse">
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">TIPO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.sub_type">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">COSTO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.price">
                            <div class="input-group-prepend">
                                <span class="input-group-text">€</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">DURATA</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.days_duration">
                            <div class="input-group-prepend">
                                <span class="input-group-text">GG</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">CORSO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.courseName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">EDIZIONE</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="summary.editionNum">
                        </div>
                    </div>
                </div>
                <div v-if="summary.days_duration === 10 || summary.days_duration === 7">
                    <div class="row">
                        <div class="col-12">
                            <router-link to="/subs" class="btn btnGrey">INDIETRO</router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button v-if="trialBtn === 'on'" class="btn btnGreen">ATTIVA</button>
                            <button v-else-if="trialBtn === 'off'" class="btn btnGreen" disabled>ATTIVA</button>
                        </div>
                    </div>
                </div>
            </form>
            <div v-if="summary.days_duration !== 7">
                <div class="row">
                    <div class="col-12">
                        <router-link to="/subs" class="btn btnGrey">INDIETRO</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-if="payWithStripeBtn === 'on'" class="btn btnGreen" @click="checkout(summary.subID, summary.sub_type, summary.price, summary.days_duration)">PAGA E ATTIVA</button>
                        <button v-else-if="payWithStripeBtn === 'off'" class="btn btnGreen" disabled>PAGA E ATTIVA</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-if="payWithBankTransBtn === 'on'" class="btn btnLightBlue" @click="paymentByBT">PAGA CON BONIFICO</button>
                        <button v-else-if="payWithBankTransBtn === 'off'" class="btn btnLightBlue" disabled>PAGA CON BONIFICO</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-10 lg:w-10 mx-auto my-6 bWarning">
            <p>
                L'attivazione dell'<span class="txtRed"><b>APP</b></span> <b>ESAMIFINANZA</b> tramite bonifico è immediatamente attiva,<br>
                si prega di effettuare il bonifico, dell'importo relativo al periodo di sottoscrizione scelto, nella giornata di sottoscrizione con i seguenti dati:
            </p>
            <p>
                BENEFICIARIO: <b>ESAMIFINANZA SRL</b><br>
                CAUSALE: <b>SOTTOSCRIZIONE APP ESAMIFINANZA</b><br>
                IBAN: <b>IT42H0306901798100000006466</b>
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';

export default {
    props: ['id'],
    data() {
        return {
            token: '',
            summary: [],
            form: {
                user_id: this.$store.state.user.id,
                courseID: null,
                sub_id: null,
                sub_type: '',
                days_duration: null,
                paymentByBankTrans: false,
                paymentMethod: null
            },
            success: false,
            message: '',
            urlCheckout: '',
            trialBtn: 'on',
            payWithBankTransBtn: 'on',
            payWithStripeBtn: 'on'
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['setSubPrice']),
        getAllData() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/allDataBySubID', { params: { subID: this.$store.state.selSubID }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                /* console.log('VALORE dati recuparati da chiamata allDataBySubID @@@@');
                console.log(res.data); */
                if(res.data) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.summary = res.data;
            
                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);  
                    }
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.form.sub_id = this.summary.subID;
                this.form.sub_type = this.summary.sub_type;
                this.form.days_duration = this.summary.days_duration;
                this.form.courseID = this.summary.courseID;
                console.log('chiamata terminata');
            });
        },
        subToCourse() {
            this.trialBtn = 'off';
            this.form.paymentMethod = 'gratis';
            /* console.log('VALORE dati form che verranno passati alla funzione subToCourse @@@@');
            console.log(this.form);
            return; */
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/subToCourse', this.form,{ headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        this.success = true;
                        this.message = res.data.message;
                    }
                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);  
                    }
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.$store.state.user['has_active_sub'] = 1;
                    this.$swal({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 7000,
                        timerProgressBar: true,
                        title: this.message,
                        icon: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/userResults');
                    }, 5000);
                }
                console.log('chiamata terminata');
            });
        },
        checkout(sub_id, sub_type, price, days_duration) {
            this.payWithBankTransBtn = 'off';
            this.payWithStripeBtn = 'off';
            this.$swal({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                title: 'Attendi, verrai indirizzato alla pagina del pagamento',
                icon: 'info'
            });
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/checkout', { sub_id: sub_id, sub_type: sub_type, price: price, days_duration: days_duration, userID: this.$store.state.user.id, repay: false, courseID: this.form.courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.session.url) {
                    location.href = res.data.session.url;
                }
            });
        },
        /* getPriceID(sub_type, price) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/createPriceID', { sub_type: sub_type, price: price }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
            });
        }, */
        paymentByBT() {
            this.form.paymentByBankTrans = true;
            this.form.paymentMethod = 'bonifico';
            this.payWithBankTransBtn = 'off';
            this.payWithStripeBtn = 'off';
            /* console.log('VALORE dati form che verranno passati alla funzione subToCourse da fn paymentByBT @@@@');
            console.log(this.form);
            return; */
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/subToCourse', this.form,{ headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        this.success = true;
                        this.message = res.data.message;
                    }
                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);  
                    } 
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.$store.state.user['has_active_sub'] = 1;
                    setTimeout(() => {
                        this.$router.push('/userResults');
                    }, 5000);
                    this.$swal({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 6000,
                        timerProgressBar: true,
                        title: this.message,
                        html: `Se non hai ancora esguito il bonifico, ricordati di farlo!<br>
                        Altrimenti l'amministrazione provvederà a sospendere la sottoscrizione e non potrai utilizzarla!`,
                        icon: 'success'
                    });
                }
                console.log('chiamata terminata');
            });
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.getAllData();
        //this.form.courseID = this.$store.state.selCourseID;
        /* console.log('valore courseID recuperato dal vuex @@@@@@');
        console.log(this.form.courseID); */
    }
}
</script>

<style scoped>
.mView {
    display: none;
}
.bWarning {
    border: 3px solid #c4161c;
}

@media screen and (max-width: 575px) {
    .dView {
        display: none;
    }
    .mView {
        display: block;
    }
}

</style>
