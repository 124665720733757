<template>
    <h1>AREA QUIZ</h1>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>