<template>
    <div v-if="!logged" class="infoBoxBg">
        <div class="infoBox">
            <div class="contacts">
                <span class="mr-3"><small><i class="pi pi-phone mr-2"></i>328 48 26 188</small></span>
                <span><small><i class="pi pi-envelope mr-2"></i>info@esamifinanza.it</small></span>
            </div>
            <div class="versionBox">
                <strong>v1.5</strong>
            </div>    
        </div>
    </div>
    <div v-if="logged">
        <Menubar v-if="user.role === 'admin'" :model="LoggedAdminNavItems" class="p-menubar p-submenu-list justify-content-between">
            <template #start>
                <div class="imgBox navbar-brand">
                    <img src="../../public/images/logoEF.png" alt="Logo EsamiFinanzaApp" class="imgDim">
                </div>
            </template>
            <template #LoggedNavItem="{item}">
                <router-link :to="item.to">{{ item.label }}</router-link>
            </template>
        </Menubar>
        <Menubar v-else :model="LoggedUserNavItems" class="p-menubar p-submenu-list justify-content-between">
            <template #start>
                <div class="imgBox navbar-brand">
                    <img src="../../public/images/logoEF.png" alt="Logo EsamiFinanzaApp" class="imgDim">
                </div>
            </template>
            <template #LoggedNavItem="{item}">
                <router-link :to="item.to">{{ item.label }}</router-link>
            </template>
        </Menubar>
    </div>
    <Menubar v-else :model="NotLoggedNavItems" class="p-menubar justify-content-between">
        <template #start>
            <div class="imgBox navbar-brand">
                <img src="../../public/images/logoEF.png" alt="Logo EsamiFinanzaApp" class="imgDim">
            </div>
        </template>
        <template #NotLoggedNavItem="{item}">
            <router-link :to="item.to">{{ item.label }}</router-link>
        </template>
    </Menubar>        
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            currentPath: '',
            active: 1,
            firstname: '',
            NotLoggedNavItems: [
                {
                    label: 'Homepage',
                    to: '/',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                },
                {
                    label: 'Login',
                    to: '/login',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/login") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                },
                {
                    label: 'Registrati',
                    to: '/register',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/register") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            ],
            LoggedAdminNavItems: [
                {
                    label: 'Homepage',
                    to: '/',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                },
                {
                    label: 'Dashboard',
                    to: '/dashboard',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/dashboard") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                },
                {
                    icon: 'pi pi-user',
                    items: [
                        {
                            label: ``,
                        },
                        {
                            label: 'Profilo',
                            icon: 'pi pi-user-edit',
                            to: '/adminProfile'
                        },
                        {
                            label: 'Logout',
                            icon: 'pi pi-sign-out',
                            command: () => {
                                this.logout();
                            }
                        }
                    ]
                }
            ],
            LoggedUserNavItems: [
                {
                    label: 'Homepage',
                    to: '/',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                },
                {
                    label: 'Dashboard',
                    to: '/userResults',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/userResults") {
                            return false;
                        } else {
                            if(this.user['has_active_sub'] === 0) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    }
                },
                {
                    label: 'Dashboard',
                    to: '/courses',
                    visible: () => {
                        if(this.$router.currentRoute.value.path == "/courses") {
                            return false;
                        } else {
                            if(this.user['has_active_sub'] === 0) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                },
                {
                    icon: 'pi pi-user',
                    items: [
                        {
                            label: ``,
                        },
                        {
                            label: 'Profilo',
                            icon: 'pi pi-user-edit',
                            to: '/userProfile'
                        },
                        {
                            label: 'Percorsi',
                            icon: 'pi pi-list',
                            to: '/courses'
                        },
                        {
                            label: 'Sottoscrizioni',
                            icon: 'pi pi-wallet',
                            to: '/userSubs'
                        },
                        {
                            label: 'Logout',
                            icon: 'pi pi-sign-out',
                            command: () => {
                                this.logout();
                            }
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapState(['user', 'logged'])
    },
    mounted() {
        //console.log('valore has_active_sub');
        //console.log(this.user['has_active_sub']);
        this.firstname = this.$store.state.user.firstname;
        if(this.$store.state.user.role === 'admin') {
            this.LoggedAdminNavItems[2].items[0].label = `Ciao, ${this.firstname}`;
        } else {
            this.LoggedUserNavItems[3].items[0].label = `Ciao, ${this.firstname}`;
        }
    },
    methods: {
        ...mapActions([
            'logout'
        ]),
        doLogout(event){
            //console.log(event.currentTarget);
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Sei sicuro di voler uscire?',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-info',
                group: 'demo',
                accept: () => {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        title: 'Logout eseguito, Arrivederci!',
                        icon: 'success'
                    });
                    this.logout();
                },
                reject: () => {
                    this.$toast.add({severity:'info', detail:'Hai deciso di restare loggato!', life: 3000});
                }
            });
        }
    }

}
</script>

<style>
.p-menubar {
    width: 100vw;
    background-color: #ffffff;
    border-bottom: 2px groove #f1f2f2;
}
/* .p-menuitem-text {
  color: #2d2f2c !important;
} */
.p-submenu-list {
    right: 0;
}
.infoBoxBg {
    background: #004b87;
    color: white;
}
.infoBox {
    display: flex;
    justify-content: space-between;
    padding: 5px 16px;
}
.imgBox {
    width: 100%;
    max-width: 200px;
    height: 50px;
}
.imgDim {
    width: 100%;
    height: 100%;
}
</style>