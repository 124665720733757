<template>
  <div class="surface-card p-4 shadow-4 border-round sm:w-full lg:w-6 mx-auto my-6">
    <div class="text-center mb-4">
      <div class="text-900 text-3xl font-medium mb-3">Scegli una nuova password!</div>
    </div>
    <div class="p-fluid">
      <div class="field mb-4">
        <div class="p-float-label">
          <Password id="oldPwd" v-model="form.oldPwd" class="" toggleMask></Password>
          <label for="oldPwd" class="">Vecchia Password *</label>
        </div>
      </div>
      <div class="field mb-4">
        <div class="p-float-label">
            <Password id="pwd" v-model="form.pwd" class="" toggleMask>
                <template #footer="sp">
                    {{sp.level}}
                    <Divider />
                    <p class="mt-2">Consigli (non sono parametri obbligatori)</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>Minimo 8 caratteri</li>
                        <li>Almeno un carattere Maiuscolo</li>
                        <li>Almeno un carattere Speciale</li>
                        <li>Almeno un numero</li>
                    </ul>
                </template>
            </Password>
            <label for="pwd" class="">Nuova Password *</label>
        </div>
      </div>
      <div class="field mb-4">
        <div class="p-float-label">
            <Password id="pwdConf" v-model="form.pwdConf" class="" toggleMask></Password>
            <label for="pwdConf" class="">Conferma Password *</label>
        </div>
      </div>
      <Button @click=changePwd label="Cambia" class="w-full mt-4"></Button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        username: this.$store.state.user.username,
        oldPwd: '',
        pwd: '',
        pwdConf: ''
      },
      token: this.$store.state['x-access-token'],
      getResult: {
        success: '',
        message: ''
      }
    }
  },
  methods: {
    ...mapActions([
          'logout'
      ]),
    changePwd() {
      axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/auth/changePwd', this.form, {
          headers: {'x-access-token': this.token}
      })
      .then((res) => {
        if(res.headers['x-token-refresh']) {
          // devo effettuare l'aggiornamento del token 
          this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
        }
        if(res.data.success) {
          // significa che il cambio password è andato a buon fine
          this.getResult.success = res.data.success;
          this.getResult.message = res.data.message;  
        } else {
          this.getResult.success = res.data.success;
          this.getResult.message = res.data.message;
        }
      })
      .catch((err) => {
          console.error(err.toString());
      })
      .finally(() => {
        if(this.getResult.success) {
          this.$swal({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000,
            title: this.getResult.message,
            icon: 'success'
          });
          this.form.oldPwd = '';
          this.form.pwd = '';
          this.form.pwdConf = '';
          setTimeout(() => {
            this.logout();
          }, 1500);
        } else {
          this.$swal({
            toast: true,
            position: 'center',
            showConfirmButton: true,
            timer: 3000,
            title: this.getResult.message,
            icon: 'error'
          });
        }
        console.log('chiamata terminata');
      });
    }
  }
}
</script>

<style scoped>

</style>
