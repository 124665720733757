<template>
    <div v-if="loading">
        <ProgressSpinner />
    </div>
    <div v-else class="customCard">
        <h3 class="titleCustomBox mb-3">SCEGLI IL CORSO SU CUI VUOI ESERCITARTI</h3>
        <div class="btnCourseChoiceWrapper">
            <div v-for="(userSub, index) in userSubs" :key="index" class="btnCourseChoice">
                <Button :label="userSub.courseName" @click="selectCourseToPractice(userSub.idCorso)"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            token: '',
            uID: null,
            userSubs: [],
            loading: false 
        }
    },
    methods: {
        ...mapActions(['setCourseId']),
        findUserActiveSubs(uID) {
            this.loading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/findUserActiveSubs`, { params: { uID: uID }, headers: { 'x-access-token': this.token }})
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.userSubs = res.data.userSubs;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                if(this.userSubs.length === 1) {
                    this.userSubs.forEach(userSub => {
                        this.selectCourseToPractice(userSub.idCorso);
                    });
                } else {
                    this.loading = false;
                }
                console.log('chiamata terminata da fn findUserActiveSubs');
            });
        },
        selectCourseToPractice(courseID) {
            this.setCourseId(courseID);
            this.$router.push("/userResults");
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.uID = this.$store.state.user.id;
        this.findUserActiveSubs(this.uID);
    }

}
</script>

<style scoped>
.customCard {
    margin: 50px auto;
    background: #e5e7e8;
    width: 100%;
    max-width: 1100px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px;
}
.titleCustomBox {
    display: flex;
    justify-content: center;
}
.btnCourseChoiceWrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.btnCourseChoice > button {
    width: 200px;
    color: #fff;
    margin-bottom: 10px;
}
@media screen and (max-width: 599px) {
    .customCard {
        margin: 50px auto;
        background: #e5e7e8;
        width: 100%;
        padding: 20px;
        border: none;
        box-shadow: none;
    }
    .titleCustomBox {
        display: flex;
        justify-content: center;
        font-size: 15px;
    }
    .btnCourseChoice > button {
        width: 200px;
        color: #fff;
        margin-bottom: 10px;
    }
}
</style>