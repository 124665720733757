import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Button from 'primevue/button'
import Menubar from 'primevue/menubar'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InlineMessage from 'primevue/inlinemessage'
import Checkbox from 'primevue/checkbox'
import MultiSelect from 'primevue/multiselect'
import Toast from 'primevue/toast'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Card from 'primevue/card'
import Calendar from 'primevue/calendar'
import Carousel from 'primevue/carousel'
import Chart from 'primevue/chart'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Password from 'primevue/password'
import ProgressSpinner from 'primevue/progressspinner'
import RadioButton from 'primevue/radiobutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Toolbar from 'primevue/toolbar' 

// importare i file CSS Bootstrap e BootstrapVue ORDINE IMPORTANTE!!!
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
// importare CSS di sweetalert
import 'sweetalert2/dist/sweetalert2.min.css'
// importare css dependencies per PrimeVue ORDINE IMPORTANTE!!!
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

const app = createApp(App);

const localeOptionsObj = {
    startsWith: 'Inizia con',
    contains: 'Contiene',
    notContains: 'Non contiene',
    endsWith: 'Finisce con',
    equals: 'Uguale',
    notEquals: 'Non uguale',
    noFilter: 'Nessun filtro',
    lt: 'Minore di',
    lte: 'Minore o uguale a',
    gt: 'Maggiore di',
    gte: 'Maggiore o uguale a',
    dateIs: 'La data è',
    dateIsNot: 'La data non è',
    dateBefore: 'La data è precedente a',
    dateAfter: 'La data è successiva a',
    clear: 'Svuota',
    apply: 'Applica',
    matchAll: 'Confronta tutti',
    matchAny: 'Confronta ognuno',
    addRule: 'Aggiungi regola',
    removeRule: 'Rimuovi regola',
    accept: 'Sì',
    reject: 'No',
    choose: 'Scegli',
    upload: 'Carica',
    cancel: 'Cancella',
    dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
    monthNames: ["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    today: 'Oggi',
    weekHeader: 'Sett',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'Debole',
    medium: 'Media',
    strong: 'Forte',
    passwordPrompt: 'Inserisci la password',
    emptyFilterMessage: 'Nessun risultato',
    emptyMessage: 'Nessun opzione disponibile'
}


app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.use(PrimeVue, {ripple: true, locale: localeOptionsObj});
app.use(ConfirmationService);
app.use(ToastService);
// ricordarsi di definire il componente se no non verrà trovato
app.component('Button', Button);
app.component('Menubar', Menubar);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InlineMessage', InlineMessage);
app.component('Checkbox', Checkbox);
app.component('MultiSelect', MultiSelect);
app.component('Toast', Toast);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Card', Card);
app.component('Calendar', Calendar);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Password', Password);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Toolbar', Toolbar);

app.mount('#app');

//createApp(App).use(store).use(router).use(VueSweetalert2).mount('#app')