<template>
    <h1 class="myTitleStyle">TENTATIVI COMPLETATI</h1>
    <div class="btnToolbar">
        <Toolbar>
            <template #start>
                <Button v-if="!playlistInProg" class="btnLightBlue" icon="pi pi-question" label="Playlist domande errate" @click="checkIfEsxistsPlaylistInProg(userID, 2)" />
                <Button v-else class="btnLightBlue" icon="pi pi-question" label="Playlist domande errate" @click="continueWithPlaylistAttemptInProg(playlistQuizID)" />
            </template>
            <template #end>
                <Button icon="pi pi-download" class="btnGrey" @click="exportQuizCompleted" />
            </template>
        </Toolbar>
    </div>
    <div v-if="userRole === 'admin'" class="filtersRow py-3">
        <form @submit.prevent="getQuizCompleted()" class="filterBox grid justify-content-around align-items-center">
            <div class="form-group ml-2 sm:col-12 md:col-6 lg:col-2">
                <InputText type="text" placeholder="Filtra per cognome" v-model="filters.lastname" class="dimCustom" />
            </div>
            <div class="form-group ml-2 sm:col-12 md:col-6 lg:col-2">
                <Calendar v-model="filters.limitDown" class="dimCustom" placeholder="Limite inferiore data fine" dateFormat="dd/mm/yy"/> 
            </div>
            <div class="form-group ml-2 sm:col-12 md:col-6 lg:col-2">
                <Calendar v-model="filters.limitUp" class="dimCustom" placeholder="Limite superiore data fine" dateFormat="dd/mm/yy"/> 
            </div>
            <div class="form-group sm:col-12 md:col-6 lg:col-2">
                <Button label="FILTRA" class="btnGreen mr-2" type="submit" />
                <Button label="ANNULLA" class="btnLightBlue" @click="resetFilters" />
            </div>
        </form>
    </div>
    <Toast />
    <div v-if="loading">
        <ProgressSpinner />
    </div>
    <div v-else>
        <div v-if="success === true">
            <DataTable :value="quiz2" v-model:expandedRows="expandedRows" dataKey="attemptID"
                responsiveLayout="stack" class="p-datatable">
                <template #header>
                    <div class="table-header-container">
                        <span v-if="!filtersAreActive">Tentativi completati: <strong>{{ totRows }}</strong></span>
                        <span v-else>Tentativi completati, in base ai filtri applicati: <strong>{{ totRows }}</strong></span>
                    </div>
                </template>
                <Column :expander="false" headerStyle="width: 3rem" />
                <Column field="description" header="nome quiz"></Column>
                <Column v-if="userRole === 'admin'" header="discente">
                    <template #body="slotProps">
                        <span class="capitalize mr-2">{{slotProps.data.firstname}}</span>
                        <span class="uppercase">{{slotProps.data.lastname}}</span>
                    </template>
                </Column>
                <Column field="visibility" header="disponibilità"></Column>
                <Column field="threshold" header="soglia %"></Column>
                <Column field="score" header="risultato %"></Column>
                <Column field="date_start" header="data inzio"></Column>
                <Column header="data fine">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.date_end">{{ slotProps.data.date_end }}</span>
                        <span v-else>---</span>
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button v-if="userID !== slotProps.data.attemptAuthorID" icon="pi pi-info-circle" class="p-button-warning mr-2" @click="showQuizEnded(slotProps.data.attemptID)" />
                        <Button v-else icon="pi pi-info-circle" class="btnGreen mr-2" @click="showQuizEnded(slotProps.data.attemptID)" />
                    </template>
                </Column>
            </DataTable> 
            <div v-if="pages !== 1" class="myPaginator">
                <div v-if="previous.page" class="arrowBox" @click="getQuizCompleted(parseInt(previous.page), parseInt(limit))"><i class="pi pi-angle-left"></i></div>
                <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
                <div class="pagesBox">
                    <span>PAGINA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
                </div>
                <div v-if="next.page" class="arrowBox" @click="getQuizCompleted(parseInt(next.page), parseInt(limit))"><i class="pi pi-angle-right"></i></div>
                <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
            </div> 
        </div>
        <div v-else>
            <div v-if="info && userRole === 'user'">
                <p class="mt-5"><strong>Non ci sono ancora quiz completati per questo corso</strong></p>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            quizStatus: 'completed',
            loading: false,
            token: '',
            expandedRows: [],
            quiz2: [],
            message: '',
            success: '',
            getValues: {
                success: '',
                message: ''
            },
            attemptID: null,
            userID: null,
            userRole: '',
            playlistQuizID: null,
            numUserActiveSubs: null,
            playlistInProg: false,
            filters: {
                lastname: '',
                limitDown: '',
                limitUp: ''
            },
            filterResult: {
                success: '',
                message: ''
            },
            filtersAreActive: false,
            currentPage: 1,
            page: 1,
            first: 0,
            limit: 10,
            totalRecords: null,
            next: {
                page: null,
                limit: null
            },
            previous: {
                page: null,
                limit: null
            },
            pages: null,
            totRows: null,
            courseID: null,
            info: false
        }
    },
    methods: {
        ...mapActions(['setQuizID', 'setAttemptID', 'setExistsActiveSubs', 'changeUserHasActiveSub']),
        getQuizCompleted(page = 1, limit = 10) {
            this.loading = true;
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            if(this.filters.lastname || this.filters.limitDown || this.filters.limitUp) {
                console.log('c è almeno un campo dei filtri selezionato o compilato');
                this.filtersAreActive = true;
                console.log(this.filtersAreActive);
            } else {
                console.log('i filtri sono compilati?');
                console.log(this.filtersAreActive);
            }
            console.log(this.filters);
            
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/quizcompleted', { filtering: this.filtersAreActive, filters: this.filters }, { params: {quizStatus: this.quizStatus, userID: this.userID, userRole: this.userRole, courseID: this.courseID, page: page, limit: limit}, headers:{ 'x-access-token': this.token } })
            .then((res) => {
                console.log("QuizzesCompleted", res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.totRows = res.data.totalCompleted;
                    this.quiz2 = res.data.quizCompleted;
                    this.message = res.data.message;
                    this.success = res.data.success;
                    /* this.attemptID =  res.data.quiz[0].attemptID; */
                    this.pages = res.data.pages;
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = "";
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = "";
                    }
                    if(this.filtersAreActive) {
                        this.filterResult.success = res.data.success;
                        this.filterResult.message = res.data.message;
                    }
                } else if(res.data.info) {
                    this.filterResult.success = false;
                    this.filterResult.message = res.data.message;
                    this.info = true;
                    this.totRows = 0;
                    this.quiz2 = [];
                    this.pages = 1;
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                //console.log('Valore nel finally ###########', this.filterResult.success, this.filterResult.message);
                if(this.filterResult.success === true) {
                    this.$toast.add({ severity: 'success', summary: this.filterResult.message, life: 1500 });
                } else if(this.filterResult.success === false) {
                    if(this.userRole === 'admin'){
                        this.$toast.add({ severity: 'warn', summary: this.filterResult.message, life: 2500 });
                    }
                }
                //console.log('chiamata terminata da fn getQuizCompleted in QuizCompleted.vue');
            });
        },
        showQuizEnded(aID) {
            this.setAttemptID(aID);
            /* this.$router.push({name: 'ShowQuizCompleted', params: { attempt_id: aID }}); */
            this.$router.push('/showquizended');
        },
        goToQuizInProgress() {
            this.$router.push('/quiz');
        },
        createPlaylistWrongAnswer(id) {
            this.$swal({
                title: 'Vuoi creare una playlist di domande errate?',
                html: `Il quiz che verrà creato, raccoglierà fino a 25 domande,<br>
                scelte casualmente tra tutte quelle che hai sbagliato nei quiz completati!<br>
                Questo ti permetterà di esercitarti in modo mirato!`,
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'CREA',
                cancelButtonText: 'ESCI',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    // l'utente ha premuto il tasto crea, perchè vuole procedere con la creazione di una playlist
                    // nella situazione di data 24/06 se apre il box con la domanda per creare una playlist
                    // su due o più dispositivi e poi preme crea in tutti 
                    // verranno create x playlist in base a quante finestre aveva aperto prima di cliccare crea
                    // SAREBBE OPPORTUNO CONTROLLARE ANCHE DOPO CHE L UTENTE PREME CREA SE EFFETTIVAMENTE NON CI SIANO PLAYLIST IN CORSO
                    this.checkIfEsxistsPlaylistInProg(id, 3);

                } else {
                    this.$toast.add({ severity: 'info', summary: 'Torna per allenarti con le domande sbagliate!', life: 3000 });
                }
            });

        },
        continueWithPlaylistAttemptInProg(id) {
            this.$swal({
                title: 'Attenzione!',
                html: `Esiste già un tentativo non completato.,<br>
                Prima di poterne creare uno nuovo devi finire questo!<br>
                Vuoi continuare questo quiz?`,
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'CONTINUA',
                cancelButtonText: 'ESCI',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    this.setQuizID(id);
                    this.$router.push({name: 'ShowQuizInProgress', params: { quizID: id }});
                } else {
                    this.$toast.add({ severity: 'info', summary: 'Torna quando vuoi per allenarti!', life: 3000 });
                }
            });
        },
        playlistWrongAnswer(uID) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/playlist', { userID: uID, courseID: this.courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.playlistQuizID = res.data.quizID;
                    this.getValues.message = res.data.message;
                    this.getValues.success = res.data.success;
                } else {
                    this.getValues.message = res.data.message;
                    this.getValues.success = res.data.success;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log(this.getValues.success);
                if(this.getValues.success) {
                    // mando l'utente alla pagine del quiz
                    this.setQuizID(this.playlistQuizID);
                    this.$router.push({name: 'ShowQuizInProgress', params: { quizID: this.playlistQuizID }});
                    /* this.continueWithPlaylistAttemptInProg(this.playlistQuizID); */
                } else {
                    this.$toast.add({ severity: 'info', summary: this.getValues.message, life: 3000 })
                }
                console.log('chiamata terminata da fn playlistWrongAnswer');
            });
        },
        checkIfEsxistsPlaylistInProg(uID, levelCheck) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/existsplaylist', { userID: uID, courseID: this.courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    // siamo al controllo appena arrivati sulla pagina
                    // significa che esiste già una playlist in corso
                    // quindi setto playlistInProg true
                    this.playlistInProg = true;
                    // e mi posso recuperare l id della playlist dall'array che ho insieme
                    this.playlistQuizID = res.data.playlistValues.id;

                    if(levelCheck === 3) {
                        this.continueWithPlaylistAttemptInProg(this.playlistQuizID);
                    }
                    
                    console.log('VALORE ARRAY playlistValues ##############');
                    console.log(res.data.playlistValues);
                } else {
                    console.log('SONO NEL CASO IN CUI NON ESITE UNA PLAYLIST IN PROGRESS');
                    switch (levelCheck) {
                        case 1:
                            // siamo al controllo appena arrivati sulla pagina
                            // non faccio nulla
                            console.log('levelCheck 1 => sono appena arrivato sulla pagina');
                            console.log(this.playlistInProg);
                            break;
                        case 2:
                            // faccio vedere il messaggio per poter scegliere di creare una playlist
                            console.log('sono nel caso con levelCheck 2 => ho cliccato il tasto per creare una playlist e non essendoci playlist in progress mi mostra il messaggio della nuova creazione');
                            this.createPlaylistWrongAnswer(uID);
                            break;
                        case 3:
                            // allora posso crearla
                            console.log('sono nel caso levelCheck 3 => ho cliccato crea nel pannello della domanda di nuova creazione');
                            this.playlistWrongAnswer(uID);
                            break;
                    
                        default:
                            break;
                    }
    
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.playlistInProg) {
                    console.log('chiamata terminata da fn checkPlayInProg');
                    this.existsPlaylistInProg = this.playlistQuizID;
                } else {
                    console.log('chiamata terminata da fn checkPlayInProg');
                    this.existsPlaylistInProg = this.playlistInProg;
                }
            });
        },
        // FUNZIONE CHE CONTROLLA SE ESISTE UNA SOTTOSCRIZIONE ATTIVA
        countUserSub(id) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/userActiveSubs', { id: id }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.numUserActiveSubs = res.data.numActiveSubs;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.numUserActiveSubs === 0) {
                    // scrivere nel vuex false in corrispondenza della voce se esistono sub attive
                    console.log('valore store.state.areThereUserActiveSubs');
                    console.log(this.$store.state.areThereUserActiveSubs);
                    this.setExistsActiveSubs(false);
                    // cambio anche il valore di has_active_sub nel vuex, perchè anche se cambiato in db nel vuex non cabia fino al logout
                    this.changeUserHasActiveSub(0);
                } else if(this.numUserActiveSubs > 0) {
                    // scrivere nel vuex true
                    this.setExistsActiveSubs(true);
                    console.log('valore store.state.areThereUserActiveSubs');
                    console.log(this.$store.state.areThereUserActiveSubs);
                    this.changeUserHasActiveSub(1);
                }
                console.log('chiamata terminata da fn countUserSub');
            });
        },
        /* SEZIONE FUNZIONE EXPORT */
        exportQuizCompleted() {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/exportQuizCompleted', { userRole: this.userRole, userID: this.userID, courseID: this.courseID, filters: this.filters}, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log('exportUsersList: RESPONSE');
                console.log(res.data);
                if(res.data.success) {
                    if (res.data.redirect) {
                    window.open( res.data.redirect, "_blank");
                    return;
                    } else {
                    this.$toast.add({ severity: 'warn', summary: 'ERRORE', detail:'Il link di download non è stato generato', life: 2500 });
                    }
                } else {
                    this.$toast.add({ severity: 'warn', summary: 'ERRORE', detail: res.data.message || 'Si è verificato un errore', life: 2500 });
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('exportUsersList: FINISHED');
            });

        },
        /* SEZIONE FUNZIONE RESET FILTRI */
        resetFilters() {
            this.filters.lastname = '';
            this.filters.limitDown = '';
            this.filters.limitUp = '';
            this.filtersAreActive = false;
            this.filterResult.success = '';
            this.filterResult.message = '';
            this.getQuizCompleted();
        },

    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.userID = this.$store.state.user.id;
        this.userRole = this.$store.state.user.role;
        if(this.userRole === 'admin') {
            if(this.$store.state.selCourseID) {
                this.courseID = this.$store.state.selCourseID;
            } else {
                this.courseID = null;
            }
            this.getQuizCompleted();
            this.countUserSub(this.userID);
            this.checkIfEsxistsPlaylistInProg(this.userID, 1); // il secondo parametro è la variabile levelCheck per definire in che situazione viene chiamata la fn
        } else {
            // siamo utente normale
            this.courseID = this.$store.state.selCourseID;
            console.log('valore courseID');
            console.log(this.courseID);
            this.getQuizCompleted();
            this.countUserSub(this.userID);
            this.checkIfEsxistsPlaylistInProg(this.userID, 1); // il secondo parametro è la variabile levelCheck per definire in che situazione viene chiamata la fn
        }
    }
 
}
</script>

<style scoped>
.p-datatable {
    max-width: 100vw;
}
</style>
