<template>
    <div class="grid">
        <h1 class="bgBlue col-12">DASHBOARD <strong>{{ firstname.toUpperCase() }} {{ lastname.toUpperCase() }}</strong></h1>
    </div>
    <div v-if="loading">
        <ProgressSpinner />
    </div>
    <div v-else>
        <div v-if="!success">
            <div v-if="info === 'noSub'">
                <Card class="p-card error">
                    <template #title>
                        <span class="text-900">{{ message }}</span>
                    </template>
                    <template #footer>
                        <Button @click="goToCourses" label="LISTA PERCORSI" autofocus />
                    </template>
                </Card>
            </div>
            <div v-else-if="info === 'noAttempts'">
                <Card class="p-card warn">
                    <template #title>
                        <span class="text-900">{{ message }}</span>
                    </template>
                    <template #content>
                        <span class="text-900">Vai all'area dei quiz, per iniziare o continuare un test, oppure creane uno e mettiti alla prova!</span>
                        <br>
                        <span class="text-900">Una volta concluso, potrai trovare i tuoi risultati in questa sezione!</span>
                    </template>
                    <template #footer>
                        <Button @click="goToQuiz" label="VAI AI QUIZ" class="btnBlue" />
                    </template>
                </Card>
            </div>
        </div>
        <div v-else>
            <Menubar :model="menuUserWithSub" class="myBgColor flex justify-content-around">
                <template #menuUserWithSub="{item}">
                    <router-link :to="item.to">{{ item.label }}</router-link>
                </template>
            </Menubar>
            <Toast />
            <div class="titleANDinfoBox pt-3">
                <h4 class="titleQuestion">STATISTICHE GENERALI</h4>
                <div class="ml-3" @click="showOverlayStatsGen" aria:haspopup="true" aria-controls="overlay_panel_sGen">
                    <i class="pi pi-question p-1 btnQuestion"></i>
                </div>
                <OverlayPanel ref="opSG" id="overlay_panel_sGen" :showCloseIcon="true" :breakpoints="{'550px': '90vw', '960px': '75vw'}">
                    <p>
                        In questa sezione sono riportati i grafici che mostrano la % di copertura e la % di risposte esatte<br>
                        La % di copertura è calcolata tra le domande ancora da rispondere e quelle risposte<br>
                        La % di risposte esatte indica quante sono state le risposte corrette rispetto alle risposte totali<br>
                        I grafici si aggiornano dopo ogni tentativo completato 
                    </p>
                </OverlayPanel>
            </div>
            <p>In questa sezione sono presenti i grafici che mostrano la percentuale di copertura e la percentuale di risposte esatte generale</p>
            <div class="grid justify-content-evenly statGen">
                <div class="sm:col-12 md:col-4 lg:col-2">
                    <div class="text-left pl-5 mt-5"><h6>% COPERTURA</h6></div>
                    <div class="relative myDoughnutBox">
                        <Chart type="doughnut" :data="PercDiCopertura" class="p-chart"/>
                        <div class="absolute myTransform">{{PercDiCoperturaValue}}%</div>
                    </div>
                </div>
                <div class="sm:col-12 md:col-4 lg:col-2">
                    <div class="text-left pl-3 mt-5"><h6>% RISPOSTE ESATTE</h6></div>
                    <div class="relative myDoughnutBox">
                        <Chart type="doughnut" :data="PercRispEsatte" class="p-chart"/>
                        <div class="absolute myTransform">{{PercRispEsatteValue}}%</div>
                    </div>
                </div>
            </div>
            <!-- AREA GRAFICI PERC COPERTURA PER MATERIA -->
            <h4 v-if="courseID == 1">PERCENTUALE DI COPERTURA PER MATERIA</h4>
            <h4 v-else>PERCENTUALE DI COPERTURA PER MODULO</h4>
            <p v-if="courseID == 1">In questa sezione sono presenti i cinque grafici che mostrano la percentuale di copertura per ognuna delle 5 materie presenti nell'Albo</p>
            <p v-else>In questa sezione sono presenti i grafici che mostrano la percentuale di copertura per ognuno dei singoli moduli</p>
            <div style="background-color: white; width: 100%;">
                <div class="container">
                    <div class="row percCop">
                        <div v-for="(copxmat, index) in arrayCopxMat" :key="index+1" class="col">
                            <div class="text-left pl-4 mt-5 boxStatMateria"><h6>{{copxmat.nomemateria}}</h6><p style="font-size: 12px;">({{copxmat.codicemateria}})</p> </div> 
                            <div class="relative myDoughnutBox">
                                <Chart type="doughnut" :data="copxmat.chart" class="p-chart"/>
                                <div class="absolute myTransform">{{copxmat.copertura}}%</div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- AREA GRAFICI PERC RISPOSTE ESATTE PER MATERIA -->
            <h4 v-if="courseID == 1">PERCENTUALE RISPOSTE ESATTE PER MATERIA</h4>
            <h4 v-else>PERCENTUALE RISPOSTE ESATTE PER MODULO</h4>
            <p v-if="courseID == 1">In questa sezione sono presenti i cinque grafici che mostrano la percentuale di risposte esatte date alle domande risposte fino a questo momento, divisi per singola materia</p>
            <p v-else>In questa sezione sono presenti i grafici che mostrano la percentuale di risposte esatte date alle domande risposte fino a questo momento, divisi per singolo modulo</p>
            <div style="background-color: white; width: 100%;">
                <div class="container">
                    <div class="row percRispEsatte">
                        <div v-for="(rispxmat, index) in arrayRispxMat" :key="index+2" class="col">
                            <div class="text-left pl-2 mt-5 boxStatMateria"><h6> {{rispxmat.nomemateria}}</h6><p style="font-size: 12px;">({{rispxmat.codicemateria}})</p></div>
                            <div class="relative myDoughnutBox">
                                <Chart type="doughnut" :data="rispxmat.chart" class="p-chart"/>
                                <div class="absolute myTransform">{{rispxmat.corrette}}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filteredStatsBox">
                <h4>AREA STATISTICHE FILTRATE PER ARGOMENTO</h4>
                <div class="p-fluid formgrid">
                    <div class="field xs:col-12 sm:col-10 sm:col-offset-1 md:col-6 md:col-offset-3">
                        <label>Argomento</label>
                        <Dropdown v-model="selectedSubject" :options="subjects" optionLabel="subject" placeholder="Scegli l'argomento" @change="getFilteredStatsByS(selectedSubject.subject_code)" />
                    </div>
                </div>
                <div v-if="!selectedSubject">
                    <h4>Nessun argomento selezionato per il filtro</h4>
                </div>
                <div v-else>
                    <div v-if="percCopXSValue !== '0.0'" class="grid justify-content-evenly">
                        <div class="sm:col-12 md:col-4 lg:col-2">
                            <div class="text-left pl-6 mt-5"><h6>% COPERTURA</h6></div>
                            <div class="relative myDoughnutBox">
                                <Chart type="doughnut" :data="copXSGraf" class="p-chart"/>
                                <div class="absolute myTransform">{{percCopXSValue}}%</div>
                            </div>
                        </div>
                        <div class="sm:col-12 md:col-4 lg:col-2">
                            <div class="text-left pl-3 mt-5"><h6>% RISPOSTE ESATTE</h6></div>
                            <div class="relative myDoughnutBox">
                                <Chart type="doughnut" :data="rightAnsXSGraf" class="p-chart"/>
                                <div class="absolute myTransform">{{percRightAnsXSValue}}%</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p><strong>I Dati per questo argomento non sono disponibili, in quanto non hai ancora risposto a domande di questa sezione!</strong></p>
                    </div>
                </div>
            </div>
            <div class="impIndxBox">
                <div class="titleANDinfoBox">
                    <h4 class="titleQuestion">INDICE DI MIGLIORAMENTO</h4>
                    <div v-if="bool" class="ml-3" @click="showOverlay" aria:haspopup="true" aria-controls="overlay_panel">
                        <i class="pi pi-question p-1 btnQuestion"></i>
                    </div>
                    <OverlayPanel ref="op" id="overlay_panel" :showCloseIcon="true" :breakpoints="{'550px': '90vw', '960px': '75vw'}">
                        <p>
                            In questa sezione sono riportati i grafici che mostrano il tuo andamento<br>
                            Da un lato trovi i valori calcolati in base alle tue performance considerando le singole domande affrontate<br>
                            con sotto il relativo grafico che mostra il tuo andamento. L'indice di miglioramento (che può anche essere negativo)<br>
                            è la differenza tra due punti successivi della curva.<br>
                            Dall'altra parte ci sono gli stessi grafici che riguardano i tentativi svolti e la media dei risultati ottenuti<br>
                            nell'arco della settimana di riferimento indicata sotto ogni coppia di colonne o punto.<br>
                            Il discorso per la curva di progressione del tuo indice di miglioramento è lo stesso fatto in precedenza
                        </p>
                    </OverlayPanel>
                </div>
                <div v-if="!bool">
                    <h4>{{ mess }}</h4>
                </div>
                <div v-else>
                    <div class="card">
                        <div class="dLayout">
                            <h5>In questa sezione sono presenti i grafici che mostrano il tuo indice di miglioramento</h5>
                            <div class="row">
                                <div class="col-6">
                                    <Chart type="bar" :data="multiAxisData" class="customWidth my-5" />
                                </div>
                                <div class="col-6">
                                    <Chart type="bar" :data="multiAxisDataAttempts" class="customWidth my-5" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <Chart type="line" :data="lineData" :options="basicOptions" class="customWidth mt-3" />
                                </div>
                                <div class="col-6">
                                    <Chart type="line" :data="lineDataAttempts" :options="basicOptions" class="customWidth mt-3" />
                                </div>
                            </div>
                        </div>
                        <div class="mLayout">
                            <h5>In questa sezione sono presenti i grafici che mostrano il tuo indice di miglioramento</h5>
                            <div class="row">
                                <div class="col-12">
                                    <Chart type="bar" :data="multiAxisData"  class="customWidth my-5" />
                                </div>
                            </div>
                            <div class="row">    
                                <div class="col-12">
                                    <Chart type="line" :data="lineData" :options="basicOptions" class="customWidth mt-3" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <Chart type="bar" :data="multiAxisDataAttempts"  class="customWidth my-5" />
                                </div>
                            </div> 
                            <div class="row">   
                                <div class="col-12">
                                    <Chart type="line" :data="lineDataAttempts" :options="basicOptions" class="customWidth mt-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';

export default {
    data() {
        return {
            // zona dati menubar
            menuUserWithSub: [
                {
                    label: 'Lista Percorsi',
                    to: '/courses',
                },
                {
                    label: 'Cosa vuoi fare oggi',
                    to: '/quiz',
                },
                /* {
                    label: 'I tuoi risultati',
                    to: '/userResults',
                }   */
            ],
            menuUserNoSub: [
                {
                    label: 'Lista Corsi',
                    to: '/courses',
                },
                {
                    label: 'Cosa vuoi fare oggi',
                    command: () => {
                        this.$toast.add({ severity: 'warn', summary: 'ATTENZIONE, ACCESSO NEGATO', detail: 'Area non accessibile, in quanto non hai sottoscrizioni attive!', life: 3000 });
                    }
                },
                /* {
                    label: 'I tuoi risultati',
                    command: () => {
                        this.$toast.add({ severity: 'warn', summary: 'ATTENZIONE, ACCESSO NEGATO', detail: 'Area non accessibile, in quanto non hai sottoscrizioni attive!', life: 3000 });
                    }
                } */
            ],
            getValues: {
                success: '',
                message: ''
            },
            numUserActiveSubs: this.courseID,
            // zona dati pagina statistiche
            loading: false,
            token: '',
            message: '',
            success: '',
            info: '',
            userID: null,
            firstname: '',
            lastname: '',
            courseID: null,
            userRole: '',
            isAdmin: false,
            values: {
                totQ: null,
                userAns: null,
                rightAns: null,
                qToAns: null,
                wrongAns: null
            },
            matters: [],
            selectedSubject: '',
            subjects: [],
            outcome: '',
            outcomeMex: '',
            subject_code: '',
            PercDiCoperturaValue: null,
            PercDiCopertura: {
                labels: ['DomRisp', 'DomDaRisp'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#017632", "#c4161c"],//#FF1304 rosso vivo, #22FF04 verde vivo
                        hoverBackgroundColor: ["#017632", "#c4161c"]
                    }
                ],
            },
            PercRispEsatteValue: null,
            PercRispEsatte: {
                labels: ['RispEsatte', 'RispErrate'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#017632", "#c4161c"],
                        hoverBackgroundColor: ["#017632", "#c4161c"]
                    }
                ],
                
            },
            /* PercRispEsatteOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: 'orange'
                        }
                    }
                }
            }, */
            loading2: false,
            success2: '',
            mex: '',
            totQXMat: [],
            totQM1: null,
            totQM2: null,
            totQM3: null,
            totQM4: null,
            totQM5: null,
            ansByMatter: [],
            ansByM1: null,
            ansByM2: null,
            ansByM3: null,
            ansByM4: null,
            ansByM5: null,
            rightAnsByMatter: [],
            rightAnsByM1: null,
            rightAnsByM2: null,
            rightAnsByM3: null,
            rightAnsByM4: null,
            rightAnsByM5: null,
            qNotAnsM1: null,
            qNotAnsM2: null,
            qNotAnsM3: null,
            qNotAnsM4: null,
            qNotAnsM5: null,
            wrongAnsM1: null,
            wrongAnsM2: null,
            wrongAnsM3: null,
            wrongAnsM4: null,
            wrongAnsM5: null,
            percCopM1Value: null,
            copM1Graf: {
                labels: ['DomDaRispXMat', 'DomRispXMat'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percCopM2Value: null,
            copM2Graf: {
                labels: ['DomDaRispXMat', 'DomRispXMat'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percCopM3Value: null,
            copM3Graf: {
                labels: ['DomDaRispXMat', 'DomRispXMat'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percCopM4Value: null,
            copM4Graf: {
                labels: ['DomDaRispXMat', 'DomRispXMat'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percCopM5Value: null,
            copM5Graf: {
                labels: ['DomDaRispXMat', 'DomRispXMat'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            // PER AVERE I DATI DI TUTTE LE MATERIE MESSI NEI GRAFICI 
            // DEVO CREARE IL CONTENITORE PER TUTTE E CINQUE LE SINGOLE MATERIE
            percRightAnsM1Value: null,
            rightAnsM1Graf: {
                labels: ['RispErrate', 'RispEsatte'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percRightAnsM2Value: null,
            rightAnsM2Graf: {
                labels: ['RispErrate', 'RispEsatte'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percRightAnsM3Value: null,
            rightAnsM3Graf: {
                labels: ['RispErrate', 'RispEsatte'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percRightAnsM4Value: null,
            rightAnsM4Graf: {
                labels: ['RispErrate', 'RispEsatte'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percRightAnsM5Value: null,
            rightAnsM5Graf: {
                labels: ['RispErrate', 'RispEsatte'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            filteredValues: {
                totQByS: null,
                ansBySubject: null,
                notAnsBySubject: null,
                rightAnsBySubject: null,
                wrongAnsBySubject: null,
            },
            percCopXSValue: null,
            copXSGraf: {
                labels: ['DomDaRispXMat', 'DomRispXMat'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            percRightAnsXSValue: null,
            rightAnsXSGraf: {
                labels: ['RispErrate', 'RispEsatte'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#c4161c", "#017632"],
                        hoverBackgroundColor: ["#c4161c", "#017632"]
                    }
                ]
            },
            bool: false,
            mess: '',
            idss3L: null,
            idss2L: null,
            idssL: null,
            impIndxS2S3: null,
            impIndxS1S2: null,
            impIndx: null,
            lWv3: {},
            lWv2: {},
            lWv: {},
            cWv: {},
            customLabels: [],
            dataset1: [],
            dataset2: [],
            multiAxisData: {
                labels: [],
                datasets: [{
                    label: '#Risposte Totali',
                    backgroundColor: '#c4161c',
                    data: []
                }, {
                    label: '#Risposte Esatte',
                    backgroundColor: '#017632',
                    data: []
                }]
            },
            mADA_datatset1: [],
            mADA_datatset2: [],
            multiAxisDataAttempts: {
                labels: [],
                datasets: [{
                    label: '#Tentativi',
                    backgroundColor: '#c4161c',
                    data: []
                }, {
                    label: 'Media Risultati',
                    backgroundColor: '#017632',
                    data: []
                }]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            lineDataDatasetsCustom: [],
            lineData: {
                labels: [],
                datasets: [
                    {
                        label: 'Indice di miglioramento #Domande totali risposte su #Risposte Esatte (%)',
                        data: [],
                        fill: false,
                        borderColor: '#009bca',
                        tension: .4
                    }
                ]
            },
            lDA_dataset: [],
            lineDataAttempts: {
                labels: [],
                datasets: [
                    {
                        label: 'Indice di miglioramento Media risultati tentativi (%)',
                        data: [],
                        fill: false,
                        borderColor: '#004b87',
                        tension: .4
                    }
                ]
            },
            corradoFnValues: [],
            arrayCopxMat: [],
            arrayRispxMat: []
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['setUSubId', 'setEdtId', 'setExistsActiveSubs', 'changeUserHasActiveSub', 'setCourseId']),
        goToCourses() {
            this.$router.push('/courses');
        },
        goToQuiz() {
            this.$router.push('/quiz');
        },
        showResults(userID, courseID) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/showUserResults', { id: userID , courseID: courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log('valore res.data showResults @@@@@',res.data);
                this.success = res.data.success;
                this.message = res.data.message;
                if(res.data.info) {
                    this.info = res.data.info;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                if(this.success) {
                    // true esistono risultati caricherò i risultati
                    //console.log(this.message);
                } else {
                    // non esistono risultati, ma potrebbe esserci la sottoscrizione
                    console.log(this.message);
                }
                console.log('chiamata terminata dalla fn showResults');
            })
        },
        getValuesForStats(userID, courseID) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/overallStats', { id: userID , courseID: courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log("totalStats: ", res.data);
                this.values.totQ = res.data.values.totQ;
                this.values.userAns = res.data.values.userAns;
                this.values.rightAns = res.data.values.rightAns;
                this.values.qToAns = (res.data.values.totQ - res.data.values.userAns);
                this.values.wrongAns = (res.data.values.userAns - res.data.values.rightAns);
                this.PercDiCoperturaValue =  ((this.values.userAns * 100)/ this.values.totQ).toFixed(1);
                this.PercRispEsatteValue = ((this.values.rightAns * 100)/ this.values.userAns).toFixed(1);
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.values) {
                    this.PercDiCopertura.datasets[0].data = [
                        this.values.userAns, this.values.qToAns
                    ];
                    this.PercRispEsatte.datasets[0].data = [
                        this.values.rightAns, this.values.wrongAns
                    ];
                }
                console.log('chiamata terminata da fn getValuesForStats');
            });
        },
        fetchMatters(courseID) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getMatters', { params: { courseID: courseID }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.matters = res.data.matters;
                    //console.log("MATTERS: ", this.matters);

                    this.getFilteredStatsByMatter(this.userID, this.courseID);
                } else {
                    this.getResults.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log("chiamata terminata da fetchmatters");
            });
        
        },
        getFilteredStatsByMatter(userID, courseID) {
            this.loading2 = true;
            const arrayM = this.matters.map(matter => matter.matter_code);
            //console.log(arrayM);
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/filteredStats', { id: userID, matter_code: arrayM, subject_code: '', courseID: courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                
                this.success2 = res.data.success;
                this.mex = res.data.message;

                // devo calcolare la copertura per materia
                // totale domande per quella materia
                // totale risposte date da user per quella materia
                for (let i = 0; i < res.data.totQXMat.length; i++) {
                    const totQXMat = res.data.totQXMat[i];
                    for (let j = 0; j < res.data.ansByMatter.length; j++) {
                        const obj = {
                            copertura: null,
                            nomemateria: null,
                            chart: {
                                labels: ['DomRispXMat', 'DomDaRispXMat'],
                                datasets: [
                                    {
                                        data: [],
                                        backgroundColor: ["#017632", "#c4161c"],
                                        hoverBackgroundColor: ["#017632", "#c4161c"]
                                    }
                                ]
                            }
                        };
                        const ansByM = res.data.ansByMatter[j];
                        // valutare solo i casi in cui le posizioni nell'array corrispondono
                        if(i===j) {
                            obj.copertura = ((ansByM/totQXMat)*100).toFixed(1);
                            obj.chart.datasets[0].data = [ansByM, totQXMat-ansByM];
                            obj.nomemateria = this.matters[j].matter;
                            obj.codicemateria = this.matters[j].matter_code;
                            this.arrayCopxMat.push(obj);
                        }
                    }    
                }
                //console.log('ARRAY copxmat',this.arrayCopxMat);
                
                // necessario trovare numero di domande errate sul totale di quelle risposte da utente
                // per calcorare percentuale di risposte esatte
                for (let i = 0; i < res.data.ansByMatter.length; i++) {
                    const ansByM = res.data.ansByMatter[i];
                    for (let j = 0; j < res.data.rightAnsByMatter.length; j++) {
                        const obj = {
                            corrette: null,
                            nomemateria: null,
                            chart: {
                                labels: ['RispEsatte', 'RispErrate'],
                                datasets: [
                                    {
                                        data: [],
                                        backgroundColor: ["#017632", "#c4161c"],
                                        hoverBackgroundColor: ["#017632", "#c4161c"]
                                    }
                                ]
                            }
                        };
                        const rightAnsByM = res.data.rightAnsByMatter[j];
                        // valutare solo i casi in cui le posizioni nell'array corrispondono
                        if(i===j) {
                            let wrongAns;
                            if(ansByM === 0) {
                                wrongAns = res.data.totQXMat[i];
                                obj.corrette = '0.0';
                                obj.chart.datasets[0].data = [rightAnsByM, wrongAns];
                            } else {
                                wrongAns = ansByM - rightAnsByM;
                                obj.corrette = ((rightAnsByM/ansByM)*100).toFixed(1);
                                obj.chart.datasets[0].data = [rightAnsByM, wrongAns];
                            }
                            obj.nomemateria = this.matters[j].matter;
                            obj.codicemateria = this.matters[j].matter_code;
                            this.arrayRispxMat.push(obj);
                        }
                    }    
                }
                //console.log('VALORE DI ARRAY DI rispxmat @@@@@@@',this.arrayRispxMat);

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.getSubjects(this.courseID);
                console.log('chiamata terminata da fn getFilteredStatsByMatter ###############');
            });
        },   
        getSubjects(courseID) {
            const arrayM = this.matters.map(matter => matter.matter_code);

            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/getsubjects', { courseID: courseID, matter_code: arrayM }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.subjects = res.data.subjects;
                } else {
                    this.getResults.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata fn getSubjects');
            });
        },
        getFilteredStatsByS(sCode) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/filteredStatsByS', { id: this.userID, subject_code: sCode, courseID: this.courseID }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                /* console.log('VALORE RISPOSTA IN FILTEREDSTATSBYSUBCODE @@@@@@');
                console.log(res.data); */
                this.filteredValues.totQByS = res.data.totQByS;
                this.filteredValues.ansBySubject = res.data.ansBySubject;
                this.filteredValues.rightAnsBySubject = res.data.rightAnsBySubject;
                this.filteredValues.notAnsBySubject = res.data.totQByS - res.data.ansBySubject;
                this.filteredValues.wrongAnsBySubject = res.data.ansBySubject - res.data.rightAnsBySubject;
                this.percCopXSValue = ((this.filteredValues.ansBySubject * 100)/this.filteredValues.totQByS).toFixed(1);
                this.percRightAnsXSValue = ((this.filteredValues.rightAnsBySubject * 100)/this.filteredValues.ansBySubject).toFixed(1);
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.filteredValues) {
                    this.copXSGraf.datasets[0].data = [
                        this.filteredValues.notAnsBySubject, this.filteredValues.ansBySubject
                    ],
                    this.rightAnsXSGraf.datasets[0].data = [
                        this.filteredValues.wrongAnsBySubject, this.filteredValues.rightAnsBySubject
                    ]
                }
                console.log('chiamata terminata da fn getFilteredStatsByS ###########');
            });
        },
        getDataForImpIndx(userID, courseID) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/improvementIdx', { params: { id: userID, courseID: courseID }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log('VALORE RISPOSTA res.data IN FN getDataForImpIndx ########', res.data);  //TODO: periodo di 4 settimane
                this.bool = res.data.success;
                this.mess = res.data.message;
                if(res.data.success) {
                    /* this.lWv3 = { yWeekNum: 18, totDomRisp: 57, totRispCorrette: 29 }; */
                    this.lWv3 = res.data.lWv3;
                    this.lWv2 = res.data.lWv2;
                    this.lWv = res.data.lWv;
                    this.cWv = res.data.cWv;

                    if(this.lWv.yWeekNum !== 0) {
                        // esiste la settimana probabilmento ho dei dati
                        // vedo se esistono dati anche per la lWv2
                        if(this.lWv2.yWeekNum !== 0) {
                            // esistono risultati
                            // controllo la terza settimana
                            if(this.lWv3.yWeekNum !== 0) {
                                this.customLabels = [`Settimana ${this.lWv3.yWeekNum}`, `Settimana ${this.lWv2.yWeekNum}`, `Settimana ${this.lWv.yWeekNum}`];
                                this.dataset1 = [this.lWv3.totDomRisp, this.lWv2.totDomRisp, this.lWv.totDomRisp];
                                this.dataset2 = [this.lWv3.totRispCorrette, this.lWv2.totRispCorrette, this.lWv.totRispCorrette];
                                this.idss3L = Math.round(((this.lWv3.totRispCorrette * 100) / this.lWv3.totDomRisp));
                                this.idss2L = Math.round(((this.lWv2.totRispCorrette * 100) / this.lWv2.totDomRisp));
                                this.idssL = Math.round(((this.lWv.totRispCorrette * 100) / this.lWv.totDomRisp));
                                this.impIndxS2S3 = (this.idss2L - this.idss3L);
                                this.impIndxS1S2 = (this.idssL - this.idss2L);
                                this.lineDataDatasetsCustom = [(0 + this.idss3L), (0 + this.idss3L + this.impIndxS2S3), (0 + this.idss3L + this.impIndxS2S3 + this.impIndxS1S2)];
                            } else {
                                this.customLabels = [`Settimana ${this.lWv2.yWeekNum}`, `Settimana ${this.lWv.yWeekNum}`];
                                this.dataset1 = [this.lWv2.totDomRisp, this.lWv.totDomRisp];
                                this.dataset2 = [this.lWv2.totRispCorrette, this.lWv.totRispCorrette];
                                this.idss2L = Math.round(((this.lWv2.totRispCorrette * 100) / this.lWv2.totDomRisp));
                                this.idssL = Math.round(((this.lWv.totRispCorrette * 100) / this.lWv.totDomRisp));
                                this.impIndxS1S2 = (this.idssL - this.idss2L);
                                this.lineDataDatasetsCustom = [(0 + this.idss2L), (0 + this.idss2L + this.impIndxS1S2)];
                            }
                        } else {
                            this.customLabels = [`Settimana ${this.lWv.yWeekNum}`];
                            this.dataset1 = [this.lWv.totDomRisp];
                            this.dataset2 = [this.lWv.totRispCorrette];
                        }
                    } else  { 
                        // non esistono risultati già per la prima settimana
                        // in realtà non ci saranno fino a passati 14 giorni dall'inizio della sub e dello svolgimento dei quiz
                    }

                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.bool) {
                    this.multiAxisData.labels = this.customLabels;
                    this.multiAxisData.datasets[0].data = this.dataset1;
                    this.multiAxisData.datasets[1].data = this.dataset2;

                    this.lineData.labels = this.customLabels;
                    this.lineData.datasets[0].data = this.lineDataDatasetsCustom;

                    this.multiAxisDataAttempts.labels = this.customLabels;
                    this.lineDataAttempts.labels = this.customLabels;

                }
                console.log('chiamata terminata da getDataForImpIndx');
            });
        },
        getDataForImpIndxNew(userID, courseID) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/impIdxNew', { params: { id: userID, courseID: courseID }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log(res.data);
                this.corradoFnValues = res.data.values;
                /* this.corradoFnValues = [
                    { week_key: "202218", tentativi: 3, media: 65 },
                    { week_key: "202219", tentativi: 1, media: 77 },
                    { week_key: "202220", tentativi: 2, media: 35 }
                ]; */
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.corradoFnValues.length > 0) {
                    for (let i = 0; i < this.corradoFnValues.length; i++) {
                        const obj = this.corradoFnValues[i];
            
                        this.mADA_datatset1.push(obj.tentativi);
                        this.mADA_datatset2.push(obj.media);
                        this.lDA_dataset.push(obj.media);            
                    }
                }
                this.multiAxisDataAttempts.datasets[0].data = this.mADA_datatset1;
                this.multiAxisDataAttempts.datasets[1].data = this.mADA_datatset2;
                this.lineDataAttempts.datasets[0].data = this.lDA_dataset;
                console.log('chiamata terminata da getDataForImpIndxNew');
            });
        },
        showOverlayStatsGen(event) {
            this.$refs.opSG.toggle(event);
        },
        showOverlay(event) {
            this.$refs.op.toggle(event);
        },
        getUserSub(id) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userSubs', {
                    params: { userId: id },
                    headers: { 'x-access-token': this.token }
                })
                .then((res) => {
                    console.log(res.data);
                    this.userSubs = res.data.userSubs;
                    this.userSubs.forEach(sub => {
                    if (sub.sub_state === "attiva") {
                        this.courseID = sub.courseID;
                        this.setCourseId(this.courseID);
                        console.log(this.$store.state.selCourseID);
                    }
                    });
                    resolve(); // Resolve the promise when the data is processed
                })
                .catch((err) => {
                    console.error(err.toString());
                    reject(err); // Reject the promise if there's an error
                })
                .finally(() => {
                    console.log('chiamata terminata da fn getUserSub');
                });
            });
            },


    },
    async mounted() {
        this.token = this.$store.state['x-access-token'];
        this.userRole = this.$store.state.user.role;

        if(this.userRole === 'admin') {
            this.userID = this.$route.query.userID;
            this.courseID = this.$route.query.courseID;
            this.firstname = this.$route.query.firstname;
            this.lastname = this.$route.query.lastname;

        } else {
            this.courseID = this.$store.state.selCourseID;
            this.userID = this.$store.state.user.id;
            if (this.courseID === null || this.courseID === ""){
                await this.getUserSub(this.userID);
            }
            this.firstname = this.$store.state.user.firstname;
            this.lastname = this.$store.state.user.lastname;
        }
        this.showResults(this.userID, this.courseID); //FIXME: inutile? serve solo a mostrare dei menu differenti se l'utente ha o meno subs attive
        this.getValuesForStats(this.userID, this.courseID);
        this.fetchMatters(this.courseID);
        this.getDataForImpIndx(this.userID, this.courseID);
        this.getDataForImpIndxNew(this.userID, this.courseID);
    }
}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.p-chart {
    width: 200px;
    height: 200px;
}
.p-card.warn{
    background-color: #ebc334;
}
.p-card.error {
    background-color: #eb4f34;
}
.myDoughnutBox {
    width: 200px;
    height: 200px;
}
.myTransform {
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
}
.statGen {
    padding: 20px 0;
    margin: 10px 0 50px 0;
    background-color: #ffffff;
}
.boxStatMateria {
    height: 100px;
    width: 230px;
    line-height: 15px;
}

.percCop {
    padding: 20px 0;
    margin: 10px 0 50px 0;
    background-color: #ffffff;
}
.percRispEsatte {
    padding: 20px 0;
    margin: 10px 0 0 0;
    background-color: #ffffff;
}
.filteredStatsBox {
    margin: 100px auto;
    padding: 20px 0;
    background-color: #ffffff;
}
.impIndxBox {
    margin: 100px auto;
    padding: 30px 0;
}
.card {
    padding: 30px 0 100px;
}
.customWidth {
    width: 500px;
    margin: auto;
}
.titleANDinfoBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.titleQuestion {
    margin-bottom: 0;
}
.btnQuestion {
    border-radius: 50%;
    border: 1px solid black;
    background: #ffffff;
    font-size: 12px;
    cursor: pointer;
}
.row {
    margin-right: 0;
    margin-left: 0;
}
.dLayout {
    display: block;
}
.mLayout {
    display: none;
}
@media screen and (max-width: 999px) {
    .dLayout {
        display: none;
    }
    .mLayout {
        display: block;
    }
}
@media screen and (max-width: 580px) {
    .dLayout {
        display: none;
    }
    .mLayout {
        display: block;
    }
    .customWidth {
        width: 100%;
        margin: auto;
    }
}
</style>
