<template>
  <div class="surface-card p-4 shadow-4 border-round sm:w-full lg:w-6 mx-auto my-6">
      <div class="text-center mb-5">
          <div class="myCol text-3xl font-medium mb-3">Bentornato!</div>
          <span class="text-600 font-medium line-height-3">Non hai un account?</span>
          <router-link to="/register" class="font-medium no-underline ml-2 registerText cursor-pointer">Registrati!</router-link>
      </div>

      <div class="p-fluid">
          <div class="field mb-4">
              <div class="p-float-label">
                  <InputText id="name" v-model="v$.form.username.$model" :class="{'pInvalid': submitted}" class="uppercase" />
                  <label for="name" :class="{'p-error': submitted}">Codice Fiscale *</label>
              </div>
              <small v-if="(submitted) || v$.form.username.$pending.$response" class="p-error">{{v$.form.username.required.$message.replace('Codice Fiscale', 'Codice Fiscale')}}</small>
          </div>
          <div class="field mb-4">
              <div class="p-float-label">
                  <Password id="password" v-model="v$.form.password.$model" :class="{'pInvalid':submitted}" toggleMask :feedback="false" :inputStyle="{'background-color': '#f1f2f2', 'border': '1px solid #dcd8d2'}"></Password>
                  <label for="password" :class="{'p-error':submitted}">Password *</label>
              </div>
              <small v-if="(submitted) || v$.form.password.$pending.$response" class="p-error">{{v$.form.password.required.$message.replace('Password', 'Password')}}</small>
          </div>
          <div class="flex align-items-center justify-content-between mb-4">
            <router-link to="/forgotPwd" class="font-medium no-underline registerText text-right cursor-pointer">Password dimenticata?</router-link>
          </div>

          <Button @click=doLogin(!v$.$invalid) label="Accedi" icon="pi pi-user" class="w-full myBgC myBtnCol"></Button>
      </div>
      <Toast position="center"/>
  </div>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      submitted: false,
      loginFail: false
    }
  },
  validations() {
    const mexIfInputEmpty = {
      form: {
        username: {
          required: helpers.withMessage('Dato Mancante o Errato', required)
        },
        password: {
          required: helpers.withMessage('Dato Mancante o Errato', required)
        }
      }
    }

    const mexIfLoginFail = {
      form: {
        username: {
          required: helpers.withMessage('Controlla il Codice Fiscale inserito', required)
        },
        password: {
          required: helpers.withMessage('Controlla la password inserita', required)
        }
      }
    }

    if(this.loginFail) {
      return mexIfLoginFail;
    } else {
      return mexIfInputEmpty;
    }

  },
  methods: {
    async doLogin(isFormValid) {
      this.submitted = true;
      if(!isFormValid) {
        /* console.log('sono qui');
        const promise = this.v$.$validate();
          promise.then((res) => {
            console.log('valore response promise #########');
            console.log(res);
            if(res) {
              this.validations(false);
            }
            return res;
          }); */
          return;
      } else {
        this.form.username = this.form.username.toUpperCase();
        this.submitted = false;
        const result = await this.$store.dispatch("doLogin", this.form);
        if(result) {
          this.submitted = true;
          this.loginFail = true;
          this.$toast.add({severity:'error', summary: result, life: 3000});
          const promise = this.v$.$validate();
          promise.then((res) => {
            return res;
          });
        }
      }
    }
  }
}
</script>

<style scoped>
.myBgC {
  background-color: #004b87;
}
.myCol {
  color: #009bca;
}
.myBtnCol {
  color: #eaeaea;
}
.registerText {
  color: #004a87
}
.pInvalid {
  border: 1px solid #c4161c !important;
}
</style>