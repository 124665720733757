<template>
    <div class="container">
        <h1>AREA DI MODIFICA DOMANDA</h1>
        <h3 v-if="question.active === 1">L'attuale domanda è: ATTIVA</h3>
        <h3 v-else-if="question.active === 0">L'attuale domanda è: NON ATTIVA</h3>
        <form v-if="isqEFPA === 'false'" @submit.prevent="editQuestion(question)">
            <div class="form-group">
                <label>MATERIA</label>
                <input type="text" class="form-control" v-model="question.matter" readonly>
            </div>
            <div class="form-group">
                <label>ARGOMENTO</label>
                <input type="text" class="form-control" v-model="question.subject" readonly>
            </div>
            <div class="form-group">
                <label>CONTENUTO</label>
                <input type="text" class="form-control" v-model="question.content" readonly>
            </div>
            <div class="form-group">
                <label>EDIZIONE</label>
                <input type="text" class="form-control" v-model="question.edition" readonly>
            </div>
            <div class="form-group">
                <label>DOMANDA</label>
                <textarea v-model="question.question" cols="30" rows="5" class="form-control"></textarea>
            </div>
            <div class="form-group">
                <label>OPZIONE A</label>
                <input type="text" class="form-control" v-model="question.rispA">
            </div>
            <div class="form-group">
                <label>OPZIONE B</label>
                <input type="text" class="form-control" v-model="question.rispB">
            </div>
            <div class="form-group">
                <label>OPZIONE C</label>
                <input type="text" class="form-control" v-model="question.rispC">
            </div>
            <div class="form-group">
                <label>OPZIONE D</label>
                <input type="text" class="form-control" v-model="question.rispD">
            </div>
            <div class="form-group">
                <label>RISPOSTA CORRETTA</label>
                <input type="text" class="form-control" v-model="question.response">
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="question.active" id="enableState" value=1>
                <label class="form-check-label" for="enableState">ATTIVA</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="question.active" id="disableState" value=0>
                <label class="form-check-label" for="disableState">NON ATTIVA</label>
            </div>
            <div class="form-group">
                <button class="btn btn-success">SALVA MODIFICHE</button>
            </div>
            <!-- <div class="bg-success" v-if="getResult.success === true">
                {{ getResult.message }}
            </div>
            <div class="bg-danger" v-else>
                {{ getResult.message }}
            </div> -->
        </form>
        <form v-else @submit.prevent="editQuestionEFPA(question)">
            <div class="form-group">
                <label>MATERIA</label>
                <input type="text" class="form-control" v-model="question.new_courses_matter" readonly>
            </div>
            <div class="form-group">
                <label>ARGOMENTO</label>
                <input type="text" class="form-control" v-model="question.new_courses_subject" readonly>
            </div>
            <div class="form-group">
                <label>DOMANDA</label>
                <textarea v-model="question.question" cols="30" rows="5" class="form-control"></textarea>
            </div>
            <div class="form-group">
                <label>OPZIONE A</label>
                <input type="text" class="form-control" v-model="question.rispA">
            </div>
            <div class="form-group">
                <label>OPZIONE B</label>
                <input type="text" class="form-control" v-model="question.rispB">
            </div>
            <div class="form-group">
                <label>OPZIONE C</label>
                <input type="text" class="form-control" v-model="question.rispC">
            </div>
            <div class="form-group">
                <label>OPZIONE D</label>
                <input type="text" class="form-control" v-model="question.rispD">
            </div>
            <div class="form-group">
                <label>RISPOSTA CORRETTA</label>
                <input type="text" class="form-control" v-model="question.response_id">
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="question.active" id="enableState" value=1>
                <label class="form-check-label" for="enableState">ATTIVA</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="active" v-model="question.active" id="disableState" value=0>
                <label class="form-check-label" for="disableState">NON ATTIVA</label>
            </div>
            <div class="form-group">
                <button class="btn btn-success">SALVA MODIFICHE</button>
            </div>
        </form>
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            token: '',
            qID: null,
            question: [],
            getValues: {
                success: '',
                message: ''
            },
            getResult: {
                success: '',
                message: ''
            },
            isqEFPA: ''
        }
    },
    methods: {
        getQuestionById(id, isqEFPA) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/singleQuestion', { params: { id: id , isqEFPA: isqEFPA}, headers: { 'x-access-token': this.token } })
            .then((res) => {

                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.question = res.data.question;
                    this.getValues.success = true;
                    this.getValues.message = res.data.message;
                } else {
                    this.getValues.success = false;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                
            });
        },
        editQuestion(obj) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/updateQuestion', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {

                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.getResult.success = true;
                    this.getResult.message = res.data.message;
                } else {
                    this.getResult.success = false;
                    this.getResult.message = res.data.message;
                }

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.getResult.success) {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        title: this.getResult.message,
                        icon: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/questions');
                    }, 2000);
                } else {
                    this.$swal(this.getResult.message);
                }
                //console.log('chiamata terminata');
            });
        },
        editQuestionEFPA(obj) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/updateQuestionEFPA', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {

                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }

                if(res.data.success) {
                    this.getResult.success = true;
                    this.getResult.message = res.data.message;
                } else {
                    this.getResult.success = false;
                    this.getResult.message = res.data.message;
                }

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.getResult.success) {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        title: this.getResult.message,
                        icon: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/questions');
                    }, 2000);
                } else {
                    this.$swal(this.getResult.message);
                }
                //console.log('chiamata terminata');
            });
        }
    
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.isqEFPA = this.$route.params.qEFPA; //bool per verificare se ho cliccato EDIT nella tabella domande OCF o EFPA
        this.qID = this.$store.state.selQuestionID;
        this.getQuestionById(this.qID, this.isqEFPA);
    }
}
</script>

<style scoped>
div {
    margin-top: 30px;
}
div > label {
    margin-bottom: 15px;
}
div > button {
    margin-bottom: 50px;
}
</style>
