<template>
  <div class="surface-card p-4 shadow-4 border-round sm:w-full lg:w-6 mx-auto my-6">
      <div class="text-center mb-5">
          <div class="text-900 text-3xl font-medium mb-3">Area recupero password!</div>
          <span class="text-600 font-medium line-height-3">Non hai un account?</span>
          <router-link to="/register" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Registrati!</router-link>
      </div>

      <div class="p-fluid">
          <div class="field mb-4">
              <div class="p-float-label">
                  <InputText id="userInput" v-model="userInput" />
                  <label for="userInput">Codice Fiscale o Email *</label>
              </div>
          </div>

          <Button @click="getNewPwd" label="Recupera" class="w-full"></Button>
      </div>
      <Toast position="center"/>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            userInput: '',
            success: '',
            message: ''
        }
    },
    methods: {
        getNewPwd() {
            if(!this.userInput) {
                // entrambi i campi non sono stati compilati
                // blocco tutto
                this.$toast.add({ severity: 'warn', summary: 'Attenzione, è necessario compilare il campo!', life: 3000 });
            } else {
                axios.post(process.env.VUE_APP_API_URL + '/api/v1/auth/forgotPwd', { input: this.userInput})
                .then((res) => {
                    console.log(res.data);
                    this.success = res.data.success;
                    this.message = res.data.message;
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    console.log(this.message);
                    if(this.message) {
                        this.$toast.add({ severity: 'info', summary: this.message, life: 3000 });
                        this.userInput = '';
                        setTimeout(() => {
                            this.$router.push('/login');
                        }, 3500);
                    }
                    console.log('chiamata terminata da fn getNewPwd'); 
                });
            }
        }
    }

}
</script>

<style>

</style>
