<template>
    <div style="padding: 30px 0">
        <div v-if="!getValues.success" class="text-danger">
            {{ getValues.message }}
        </div>
        <div v-else>
            <div class="container" style="padding-bottom:70px;">
                <h3 class="mb-4">Riepilogo dati Sottoscrizione</h3>
                <div class="row">
                    <div class="col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">CORSO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="subToEdit.courseName">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">EDIZIONE #</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="subToEdit.editionNum">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">DISCENTE</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="fullName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">TIPO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="subToEdit.sub_type">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">DURATA</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="subToEdit.days_duration">
                            <div class="input-group-prepend">
                                <span class="input-group-text">GG</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">COSTO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="subToEdit.price">
                            <div class="input-group-prepend">
                                <span class="input-group-text">€</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="subToEdit.comment" class="row">
                    <div class="col-10 col-offset-1">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">COMMENTO</span>
                            </div>
                            <input type="text" class="form-control" readonly :value="subToEdit.comment">
                        </div>
                    </div>
                </div>
                <div class="my-4" v-if="subToEdit.sub_state !== 'non iniziata'">
                    <h5 class="my-4">Periodo di attivazione</h5>
                    <div class="row">
                        <div class="col-4 offset-2">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">INIZIO</span>
                                </div>
                                <input type="text" class="form-control" readonly :value="subToEdit.start_date">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">FINE</span>
                                </div>
                                <input type="text" class="form-control" readonly :value="subToEdit.end_date">
                            </div>
                        </div>
                        <div class="col-1" @click="showHide()">
                            <button class="btn btn-success">
                                <i v-if="!postponeBoxOpen" class="bi bi-arrow-bar-down" title="estendi data di fine"></i>
                                <i v-else class="bi bi-arrow-bar-up" title="nascondi estensione data di fine"></i>
                            </button>
                        </div>
                    </div>
                    <form class="row" id="postponeEndDate" style="display: none;" @submit.prevent="extend({ newEndDate: this.extendedEndDate, id: subToEdit.id })">
                        <div class="col-4 offset-4">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">NUOVA DATA FINE</span>
                                </div>
                                <input type="datetime-local" class="form-control" placeholder="ES. 20/07/2022, 15:00:00" v-model="extendedEndDate">  
                            </div> 
                        </div>
                        <div class="col-2 ">
                            <div class="input-group mb-3">
                                <button class="btn btn-info">ESTENDI</button>
                            </div>
                        </div>
                    </form>
                </div>
                <Toast position="center" />
                <div v-if="subToEdit.sub_state === 'non iniziata'">
                    <h5 class="my-4">Area attivazione</h5>
                    <form class="row" @submit.prevent="activate({sub_state: this.selectedSubState, paymentProgress: this.selectedPyStatus, id: subToEdit.id, days_duration: subToEdit.days_duration, sub_type: subToEdit.sub_type})">
                        <div class="form-group col-4 offset-2">
                            <label>Stato Sottoscrizione</label>
                            <select v-model="selectedSubState" class="form-control">
                                <option v-for="subState in subStateOptions" :key="subState.status" :value="subState.value">{{ subState.status }}</option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <label for="">Stato Pagamento</label>
                            <select v-model="selectedPyStatus" class="form-control">
                                <option v-for="pay in paymentOptions" :key="pay.status" :value="pay.value">{{ pay.status }}</option>
                            </select>
                        </div>
                        <button class="btn btn-info col-2 offset-5 mt-4">ATTIVA</button>
                    </form>
                </div>
                <div v-else>
                    <h5 class="my-4">Area modifica</h5>
                    <form class="row" @submit.prevent="activate({sub_state: this.selectedSubState, paymentProgress: this.selectedPyStatus, id: subToEdit.id, days_duration: subToEdit.days_duration, sub_type: subToEdit.sub_type})">
                        <div class="form-group col-4 offset-2">
                            <label>Stato Sottoscrizione</label>
                            <select v-model="selectedSubState" class="form-control">
                                <option v-for="subState in subStateOptions" :key="subState.status" :value="subState.value">{{ subState.status }}</option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <label for="">Stato Pagamento</label>
                            <select v-model="selectedPyStatus" class="form-control">
                                <option v-for="pay in paymentOptions" :key="pay.status" :value="pay.value">{{ pay.status }}</option>
                            </select>
                        </div>
                        <button class="btn btn-info col-2 offset-5 mt-4">MODIFICA</button>
                    </form>
                </div>
                <div v-if="responseValue.success" class="text-success">
                    {{ responseValue.message }}
                </div>
                <div v-else-if="responseValue.success === false" class="text-danger">
                    {{ responseValue.message }}
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['USubId'],
    data() {
        return {
            token: '',
            idSubToEdit: null,
            getValues: {
                success: '',
                message: ''
            },
            subToEdit: null,
            fullName: '',
            selectedPyStatus: '',
            paymentOptions: [
                { status: 'in attesa' , value: 'in attesa' },
                { status: 'pagato' , value: 'pagato' }
            ],
            selectedSubState: '',
            subStateOptions: [
                { status: 'non iniziata' , value: 'non iniziata' },
                { status: 'sospesa' , value: 'sospesa' },
                { status: 'attiva' , value: 'attiva' },
                { status: 'terminata' , value: 'terminata' }
            ],
            responseValue: {
                success: '',
                message: ''
            },
            extendedEndDate: '',
            postponeBoxOpen: false,
            extendResponseValue: {
                success: '',
                message: ''
            }
        }
    },
    methods: {
        getSubToEdit(id) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/userSubToEdit', { params: { 'id': id }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.getValues.success = res.data.success;
                    this.subToEdit = res.data.subToEdit;
                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.getValues.message = res.data.message;
                }

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log(this.subToEdit);
                console.log(this.subToEdit.firstname);
                this.fullName = this.subToEdit.firstname + ' ' + this.subToEdit.lastname;
                if(this.subToEdit.sub_state) {
                    this.selectedSubState = this.subToEdit.sub_state;
                }
                if(this.subToEdit.paymentProgress) {
                    this.selectedPyStatus = this.subToEdit.paymentProgress;
                }
                console.log('chiamata terminata');
            })
        },
        activate(obj) {
            console.log('VALORE OBJ IN FN ACTIVATE @@@@@@@@@@@');
            console.log(obj);
            axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/activateUsrSub', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                if(res.data.success) {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.responseValue.success = res.data.success;
                    this.responseValue.message = res.data.message;
                } else {
                    if(res.headers['x-token-refresh']) {
                        this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                    }
                    this.responseValue.success = res.data.success;
                    this.responseValue.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.responseValue.success){
                    setTimeout(() => {
                        this.$router.push('/dashboard');
                    }, 1000);
                }
                console.log('chiamata terminata');
            });
        },
        extend(obj) {
            /* console.log(this.extendedEndDate);
            console.log(this.subToEdit.startSubD); */
            const extDatems = Date.parse(this.extendedEndDate);
            /* console.log(extDatems); */
            const sDatems = Date.parse(this.subToEdit.startSubD);
            /* console.log(sDatems); */
            /* console.log('nuovo termine sub data estensione viene prima di inizio della sub?'); */
            /* console.log(extDatems < sDatems); */
            if(!this.extendedEndDate) {
                this.$toast.add({ severity: 'warn', summary: `ATTENZIONE DEVI SELEZIONARE UNA DATA PRIMA DI POTER ESTENDERE LA SOTTOSCRIZIONE!`, life: 1500 });
                return;
            }
            if(extDatems < sDatems) {
                this.$toast.add({ severity: 'error', summary: `ATTENZIONE LA DATA SCELTA E' PRECEDENTE ALL'INIZIO DELLA SOTTOSCRIZIONE, SCEGLIERE UN'ALTRA DATA!`, life: 1500 });
                this.extendedEndDate = '';
                return;
            } else {
                axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/extendSubEnd', obj, { headers: { 'x-access-token': this.token } })
                .then((res) => {
                    console.log(res);
                    if(res.data.success) {
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        }
                        this.extendResponseValue.success = res.data.success;
                        this.extendResponseValue.message = res.data.message;
                    } else {
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        }
                        this.extendResponseValue.success = res.data.success;
                        this.extendResponseValue.message = res.data.message;
                    }
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    if(this.extendResponseValue.success){
                        this.$toast.add({ severity: 'success', summary: this.extendResponseValue.message, life: 1500 });
                        setTimeout(() => {
                            this.$router.push('/dashboard');
                        }, 2000);
                    } else {
                        this.$toast.add({ severity: 'error', summary: this.extendResponseValue.message, life: 2500 });
                    }
                    console.log('chiamata terminata');
                });
            }    
        },
        showHide() {
            if(document.getElementById('postponeEndDate').style.display === "none") {
                document.getElementById('postponeEndDate').style.display = "flex";
                this.postponeBoxOpen = true;
            } else if(document.getElementById('postponeEndDate').style.display = "flex") {
                document.getElementById('postponeEndDate').style.display = "none";
                this.postponeBoxOpen = false;
            }
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.idSubToEdit = this.$store.state.selUSubId;
        this.getSubToEdit(this.idSubToEdit);
    }
}
</script>

<style scoped>

</style>
