<template>
  <Navbar />
  <router-view/>
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar
  },
  data() {
    return {
      
    }
  },
  computed: {
    
  },
  mounted() {
    
  }
}
</script>

<style>
@font-face {
  font-family: "corbel" !important;
  src: local("corbel"),
  url('./fonts/corbel/CORBEL.TTF') format("truetype") !important;
}
html {
  background-color: #f1f2f2;
}
#app {
  font-family: "corbel";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f1f2f2;
}
input {
  font-family: "corbel" !important;
  background-color: #f1f2f2 !important;
  border: 1px solid #dcd8d2 !important;
}
.p-component {
  font-family: "corbel" !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.p-datatable {
  font-family: "corbel" !important;
  max-width: 100vw !important;
  margin-bottom: 0 !important;
}
.p-column-header-content {
  justify-content: center;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align-last: center;
}
.p-menubar {
  background: #e5e7e8 !important;
}
/* .p-menuitem-text {
  color: #017331 !important;
} */
.p-submenu-list {
  right: 0;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #037b35 !important;
  border-color: #037b35 !important;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #037b35 !important;
}

/* sezione myPaginator */
.myPaginator {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 3px solid grey;
  background-color: #f1f2f2;
  color: #ffffff;
  padding: 0.75rem;
}
.arrowBox {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #004b87; */
  background-color: #004b87;
  color: #ffffff;
  height: 2.357rem;
  min-width: 2.357rem;
  cursor: pointer;
}
.arrowBox:hover {
  background: rgb(27, 125, 204);
}
.myBtnDisabled {
  opacity: 0.65;
  cursor: unset;
}
.myBtnDisabled:hover {
  background: #004b87;
  opacity: 0.65;
}
.pagesBox {
  display: flex;
  align-items: center;
  /* border-top: 1px solid #004b87;
  border-bottom: 1px solid #004b87; */
  background-color: #004b87;
  color: #ffffff;
  height: 2.357rem;
  min-width: 2.357rem;
  padding: 0 0.5rem;
}
.btnRed {
  background-color: #c4161c !important;
  border: none !important;
  color: #eaeaea !important;
}
.btnRed:enabled:hover {
  background-color: #c4161c !important;
}
/* sezione stile tabview */
.p-tabview-panels {
  padding: 0 !important;
}
/* SEZIONE STILE BOTTONI */
.btnGreen {
    background: #017632 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGreen:enabled:hover {
    background: #017300 !important;
}
.btnLightBlue {
    background: #009bca !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnLightBlue:enabled:hover {
    background: #007cdb !important;
}
.btnGrey {
    background: #58595b !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGrey:enabled:hover {
    background: #58595a !important;
}
.btnBlue {
    background: #004b87 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnBlue:enabled:hover {
    background: #004b87 !important;
}
/* sfondo titoli */
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}

select.form-control {
    font-family: "corbel" !important;
    -webkit-appearance: menulist;
}

@media screen and (max-width: 575px) {
    .p-dropdown-items-wrapper {
        width: 95vw !important;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      font-size: 0.8rem !important;
    }
}
</style>

