<template>
    <div class="dsktLayout">
        <h1 class="bgBlue">MODIFICA STRUTTURA QUIZ</h1>
        <div class="surface-card p-4 shadow-4 border-round sm:w-full md:w-10 lg:w-10 mx-auto my-6">
            <div class="p-fluid grid">
                <div class="field sm:col-12 md:col-4 md:col-offset-3">
                    <label>Descrizione</label>
                    <InputText v-model="description" type="text" placeholder="Es. Simulazione Esame" />
                </div>
                <div class="field sm:col-12 md:col-2">
                    <label>Soglia</label>
                    <Dropdown v-model="selectedThreshold" :options="thresholdOptions" />
                </div>
            </div>
            <div class="p-fluid grid">
                <div v-if="!selectedMatter" class="field sm:col-12 md:col-6 md:col-offset-3">
                    <label>Materia</label>
                    <Dropdown v-model="selectedMatter" :options="matters" optionLabel="matter" placeholder="Scegli la materia" @change="getFilteredContent($event)" />
                </div>
                <div v-else class="field sm:col-12 md:col-6 md:col-offset-3">
                    <label>Materia</label>
                    <Dropdown v-model="selectedMatter" :options="matters" optionLabel="matter" placeholder="Scegli la materia" @change="getFilteredContent($event)" />
                </div> 
                <div v-if="selectedMatter" class="field sm:col-12 md:col-3">
                    <label>annulla filtro materia</label>
                    <Button icon="pi pi-times" class="btnRed ml-2" @click="resetMatter" />
                </div> 
            </div>
            <div class="p-fluid grid">
                <div class="field sm:col-12 md:col-6 md:col-offset-3">
                    <label>Contenuto</label>
                    <Dropdown v-model="selectedContent" :options="contents" optionLabel="content" placeholder="Scegli il contenuto" @change="contentSelected($event)" />
                </div>
            </div>
            <div class="p-fluid grid align-items-end">
                <div class="field sm:col-12 md:col-3 md:col-offset-3">
                    <label>Numero di domande</label>
                    <InputText v-model="number" @keyup="test($event)" placeholder="Es. 1" />
                </div>
                <div v-if="infoMex" class="field sm:col-12 md:col-3">
                    <InlineMessage v-if="checkQNum === false" severity="warn">{{ infoMex }}</InlineMessage>
                    <InlineMessage v-else-if="checkQNum === true" severity="success">{{ infoMex }}</InlineMessage>
                </div>
                <div class="field sm:col-12 md:col-2">
                    <Button v-if="checkQNum === true" icon="pi pi-plus" class="btnGreen" @click="addRow()"/>
                    <Button v-else-if="checkQNum === false" icon="pi pi-plus" class="btnRed" disabled="disabled"/>
                    <Button v-else icon="pi pi-plus" class="btnLightBlue" disabled="disabled"/>
                </div>
            </div>

            <div v-if="quizStructure.length > 0">
                <h3 class="mt-3">Riepilogo scelte effettuate</h3>
                <div v-for="(item, index) in quizStructure" :key="index">
                    <div class="grid p-fluid">
                        <div class="sm:col-12 md:col-5">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">MAT.</span>
                                <InputText :value="item.matter.matter" readonly/>
                            </div>
                        </div>
                        <div class="sm:col-12 md:col-4">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">CONT.</span>
                                <InputText :value="item.content.content" readonly/>
                            </div>
                        </div>
                        <div class="sm:col-12 md:col-2">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">#DOM.</span>
                                <InputText :value="item.number" readonly />
                            </div>
                        </div>
                        <div class="col-1"> 
                            <Button icon="pi pi-trash" class="btnRed" @click="removeRow(index) "/>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="flex justify-content-center mt-5 mb-3">
                <Button label="LISTA QUIZ" class="mr-4 btnGrey" @click="goToQuizIndex" />
                <Button label="MODIFICA QUIZ" class="btnGreen" @click="updateQuizStructure" />
            </footer>
            <Toast />
        </div>
    </div>
    <div class="cellLayout">
        <!-- <p>contenuto da mostrare sotto la dimensione 575px width</p> -->
        <h1 class="bgBlue">MODIFICA STRUTTURA QUIZ</h1>
        <div class="p-fluid grid">
            <div class="field col-12">
                <label>Descrizione</label>
                <InputText v-model="description" type="text" placeholder="Es. Simulazione Esame" />
            </div>
            <div class="field col-12">
                <label>Soglia</label>
                <Dropdown v-model="selectedThreshold" :options="thresholdOptions" />
            </div>
        </div>
        <div class="p-fluid grid">
            <div v-if="!selectedMatter" class="field col-12">
                <label>Materia</label>
                <Dropdown v-model="selectedMatter" :options="matters" optionLabel="matter" placeholder="Scegli la materia" @change="getFilteredContent($event)" />
            </div>
            <div v-else class="field col-12">
                <label>Materia</label>
                <Dropdown v-model="selectedMatter" :options="matters" optionLabel="matter" placeholder="Scegli la materia" @change="getFilteredContent($event)" />
            </div> 
            <div v-if="selectedMatter" class="field col-12">
                <label>annulla filtro materia</label>
                <Button icon="pi pi-times" class="btnRed ml-2" @click="resetMatter" />
            </div> 
        </div>
        <div class="p-fluid grid">
            <div class="field col-12">
                <label>Contenuto</label>
                <Dropdown v-model="selectedContent" :options="contents" optionLabel="content" placeholder="Scegli il contenuto" @change="contentSelected($event)" />
            </div>
        </div>
        <div class="p-fluid grid align-items-end">
            <div class="field col-12">
                <label>Numero di domande</label>
                <InputText v-model="number" @keyup="test($event)" placeholder="Es. 1" />
            </div>
            <div v-if="infoMex" class="field col-12">
                <InlineMessage v-if="checkQNum === false" severity="warn">{{ infoMex }}</InlineMessage>
                <InlineMessage v-else-if="checkQNum === true" severity="success">{{ infoMex }}</InlineMessage>
            </div>
            <div class="field col-12">
                <Button v-if="checkQNum === true" icon="pi pi-plus" class="btnGreen" @click="addRow()"/>
                <Button v-else-if="checkQNum === false" icon="pi pi-plus" class="btnRed" disabled="disabled"/>
                <Button v-else icon="pi pi-plus" class="btnLightBlue" disabled="disabled"/>
            </div>
        </div>
        <div v-if="quizStructure.length > 0">
            <h3 class="mt-3">Riepilogo scelte effettuate</h3>
            <div v-for="(item, index) in quizStructure" :key="index">
                <div class="grid p-fluid">
                    <div class="col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">MAT.</span>
                            <InputText :value="item.matter.matter" readonly/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">CONT.</span>
                            <InputText :value="item.content.content" readonly/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">#DOM.</span>
                            <InputText :value="item.number" readonly />
                        </div>
                    </div>
                    <div class="col-1"> 
                        <Button icon="pi pi-trash" class="btnRed" @click="removeRow(index) "/>
                    </div>
                </div>
            </div>
        </div>
        <footer class="flex justify-content-center mt-5 mb-3">
            <Button label="LISTA QUIZ" class="mr-4 btnGrey txtSmall" @click="goToQuizIndex" />
            <Button label="MODIFICA STRUTTURA QUIZ" class="btnGreen txtSmall" @click="updateQuizStructure" />
        </footer>
        <Toast />
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['id'],
    data() {
        return {
            token: '',
            description: '',
            selectedThreshold: null,
            thresholdOptions: [70, 75, 80, 85, 90, 95, 100],
            matter: '',
            subject: '',
            content: '',
            number: null,
            data: [],
            selectedMatter: '',
            matters: [],
            selectedSubject: '',
            subjects: [],
            selectedContent: '',
            contents: [],
            getResults: {
                success: false,
                message: ''
            },
            quizStructure: [],
            avaiableQ: [],
            checkQNum: '',
            infoMex: '',
            editable: '',
            quizID: null
        }
    },
    methods: {
        addRow() {
            const row = {};
            row.matter = this.selectedMatter;
            row.subject = this.selectedSubject;
            row.content = this.selectedContent;
            row.number = parseInt(this.number);

            if(this.quizStructure.length > 0) {
                for (let i = 0; i < this.quizStructure.length; i++) {
                    const element = this.quizStructure[i];
                    console.log(`ciclo per i = ${i}`);

                    console.log(`element.matter = ${element.matter.matter} => row.matter = ${row.matter.matter}`);
                    console.log(`element.subject = ${element.subject.subject} => row.subject = ${row.subject.subject}`);
                    console.log(`element.content = ${element.content.content} => row.content = ${row.content.content}`);
                    if(element.matter.matter == row.matter.matter && element.subject.subject == row.subject.subject && element.content.content == row.content.content) {
                        console.log('i tre elementi di confronto sono uguali, sommo solo la quantità delle domande');
                        element.number = element.number + row.number;
                        this.selectedMatter = '';
                        this.selectedSubject = '';
                        this.selectedContent = '';
                        this.number = null;
                        this.checkQNum = "";
                        this.infoMex = "";
                        return;
                    }
                    // posso inserire la riga
                    console.log(`fine ciclo per i = ${i}`);
                }
                console.log('sono uscito dal ciclo');
                console.log('nessuna corrispondenza con i dati già presenti, potrei inserire qui una nuova riga nell array');
                this.quizStructure.push(row);
                this.selectedMatter = '';
                this.selectedSubject = '';
                this.selectedContent = '';
                this.number = null;
                this.checkQNum = "";
                this.infoMex = "";
                return;
            } else {
                console.log('siamo alla prima riga inserita');
                this.quizStructure.push(row);
                console.log(this.quizStructure);
                this.selectedMatter = '';
                this.selectedSubject = '';
                this.selectedContent = '';
                this.number = null;
                this.checkQNum = "";
                this.infoMex = "";
            }   
        },
        removeRow(index) {
            this.quizStructure.splice(index, 1);
        },
        getDataStructure(objCode) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/dataStructure', { 'params': objCode , headers: { 'x-access-token': this.token } })
            .then((res) => {
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.subjects = res.data.subjects;
                    this.contents = res.data.contents;
                } else {
                    this.getResults.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata');
            });
        },
        getMatters() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getMatters', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.matters = res.data.matters;
                } else {
                    this.getResults.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log(this.matters);
                console.log('chiamata terminata');
            });
        },
        getMatterByCCode(cCode) {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getmatterbyccode', { params: { 'content_code': cCode }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log('valore da fn getMatterByCCode');
                console.log(res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.selectedMatter = res.data.matter[0];
                } else {
                    this.getResults.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log(this.selectedMatter);
                console.log('chiamata terminata da fn getMatterByCCode');
            });
        },
        getAllContents() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getallcontents', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.contents = res.data.contents;
                } else {
                    this.getResults.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata fn getAllContents');
            });
        },
        getFilteredData(event) {
            this.number = "";
            this.infoMex = "";
            this.checkQNum = "";
            this.selectedSubject = "";
            this.selectedContent = "";
            console.log(event.value.matter_code);
            const code = {
                mCode: '',
                sCode: '',
                cCode: ''
            }

            if(event.value.matter_code) {
                code.mCode = event.value.matter_code;
                this.getDataStructure(code);
            }
            
        },
        getFilteredContent(event) {
            this.number = "";
            this.infoMex = "";
            this.checkQNum = "";
            this.selectedContent = "";
            this.contents = [];
            console.log('valore event.value #######');
            console.log(event.value);
            if(event.value.id) {
                axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getcontents', { params: { 'matter_code': event.value.matter_code }, headers: { 'x-access-token': this.token }})
                .then((res) => {
                    console.log(res.data);
                    if(res.data.success) {
                        this.contents = res.data.contents;
                    } else {
                        this.getResults.message = res.data.message;
                    }
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    console.log('chiamata terminata');
                });
            }
        },
        contentSelected(event) {
            this.number = "";
            this.infoMex = "";
            this.checkQNum = "";
            this.selectedMatter = "";
            console.log(event.value);
            if(this.selectedMatter) {
                console.log('la materia è stata selezionata');
                console.log(this.selectedMatter);
            } else {
                console.log('la materia non è stata selezionata');
                // la selezioniamo tramite una function
                this.getMatterByCCode(event.value.content_code);
            }
        },
        resetMatter() {
            this.selectedMatter = "";
            this.getAllContents();
        },
        updateQuizStructure() {
            const obj = {
                quizId: this.quizID,
                user: {
                    id: this.$store.state.user.id,
                    role: this.$store.state.user.role
                },
                description: this.description,
                editable: this.editable,
                structure: this.quizStructure
            };
            console.log(obj);
            axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/updateQuizStructure', obj, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success === true) {
                    this.getResults.success = true;
                    this.getResults.message = res.data.message;
                } else if(res.data.success === 'info') {
                    this.getResults.success = 'info';
                    this.getResults.message = res.data.message;
                } else {
                    this.getResults.message = res.data.message;
                }

            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.getResults.success === true) {
                    // successo, redirect 
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        title: this.getResults.message,
                        icon: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/quiz');
                    }, 1500);
                } else if(this.getResults.success == 'info') {
                    // info no redirect, box info
                    this.$swal({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 5000,
                        title: this.getResults.message,
                        icon: 'info'
                    });
                    this.quizStructure = [];
                    this.checkQNum = "";
                    this.infoMex = "";
                } else {
                    // errore box danger
                    this.$swal({
                        toast: true,
                        position: 'center',
                        showConfirmButton: true,
                        timer: 5000,
                        title: this.getResults.message,
                        icon: 'error'
                    });
                    this.quizStructure = [];
                    this.checkQNum = "";
                    this.infoMex = "";
                }
                console.log('chiamata terminata');
            });
        },
        getAvaiableQuestions() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/getQnumber', { headers: { 'x-access-token': this.token } })
            .then((res) => {
                //console.log(res);
                this.avaiableQ = res.data.avaiableQ;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('chiamata terminata');
            });
        },
        test(event) {
            //console.log(event);
            console.log(this.number);
            if(this.number == "") {
                this.checkQNum = "";
                this.infoMex = "";
            } else {
                if(this.selectedContent) {
                    console.log(this.selectedContent.content_code);
                    // se è stato selezionato il contenuto
                    // verifichiamo la disponibilità a questo livello
                    this.avaiableQ.find((item) => {
                        if(item.codice == this.selectedContent.content_code) {
                            console.log(item);
                            if(item.domande > this.number) {
                                console.log('Ok');
                                this.checkQNum = true;
                                this.infoMex = 'Quantità di domande scelte, disponibile, per questo contenuto!';
                            } else {
                                console.log('numero di domande richiesto supera la quantità disponibile');
                                this.checkQNum = false;
                                this.infoMex = `Attenzione, per questa materia il numero di domande disponibili è: ${item.domande}`;
                                return;
                            }
                        }
                    });
                } else if(this.selectedSubject) {
                    console.log(this.selectedSubject.subject_code);

                    this.avaiableQ.find((item) => {
                        if(item.codice == this.selectedSubject.subject_code) {
                            console.log(item);
                            if(item.domande > this.number) {
                                console.log('OK');
                                this.checkQNum = true;
                                this.infoMex = 'Quantità di domande scelte, disponibile, per questo argomento!';
                            } else {
                                console.log('numero di domande richiesto supera la quantità disponibile');
                                this.checkQNum = false;
                                this.infoMex = `Attenzione, il numero di domande scelto supera il numero di domande disponibili per questo argomento! \n
                                Numero di domande disponibili: ${item.domande}`;
                                return;
                            }
                        }
                    });
                } else if(this.selectedMatter) {
                    console.log(this.selectedMatter.matter_code);

                    this.avaiableQ.find((item) => {
                        if(item.codice == this.selectedMatter.matter_code) {
                            console.log(item);
                            if(item.domande > this.number) {
                                console.log('OK');
                                this.checkQNum = true;
                                this.infoMex = 'Quantità di domande scelte, disponibile, per questa materia!';
                            } else {
                                console.log('numero di domande richiesto supera la quantità disponibile');
                                this.checkQNum = false;
                                this.infoMex = `Attenzione, il numero di domande scelto supera il numero di domande disponibili per questa materia! \n
                                Numero di domande disponibili: ${item.domande}`;
                                return;
                            }
                        }
                    });
                } else {
                    console.log('DEVI SELEZIONARE ALMENO LA MATERIA, PRIMA DI SCEGLIERE IL NUMERO DI DOMANDE DA AGGIUNGERE!');
                    this.checkQNum = false;
                    this.infoMex = 'DEVI SELEZIONARE ALMENO LA MATERIA, PRIMA DI SCEGLIERE IL NUMERO DI DOMANDE DA AGGIUNGERE!';
                };
            }
        },
        getSingleQuiz() {
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/singlequizbyUID', { params: { id: this.$store.state.selQuizID, userID: this.$store.state.user.id }, headers:{ 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.quizID = res.data.singlequiz.qStructureID;
                this.description = res.data.singlequiz.description;
                /* this.date_start = res.data.singlequiz.date_start;
                this.date_end = res.data.singlequiz.date_end; */
                this.selectedThreshold = res.data.singlequiz.threshold;
                this.editable = res.data.singlequiz.editable;
                this.quizStructure = res.data.singlequiz.quiz_structure;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('VALORE this quiz structure');
                console.log(this.quizStructure);
                console.log('chiamata terminata da fn getSingleQuiz');
            });
        },
        goToQuizIndex() {
            this.$router.push('/quiz');
        }
    },
    mounted() {
        console.log('valore quizID');
        console.log(this.$store.state.selQuizID);
        this.token = this.$store.state['x-access-token'];
        this.getMatters();
        this.getAvaiableQuestions();
        this.getSingleQuiz();
    }

}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.btnGreen {
    background: #017632 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGreen:enabled:hover {
    background: #017300 !important;
}
.btnLightBlue {
    background: #009bca !important;
    border: none !important;
    color: #eaeaea !important;
}
.cellLayout {
    display: none;
}
@media screen and (max-width: 575px) {
    .dsktLayout {
        display: none;
    }
    .cellLayout {
        display: block;
        background: #ffffff;
    }
    .p-dropdown-items-wrapper {
        width: 95vw;
    }
    .txtSmall {
        font-size: 12px !important;
    }
}
</style>
