<template>
    <div v-if="getValues.success === false">
        <h1 class="text-center">{{ getValues.message }}</h1>
    </div>
    <div v-else>
        <h1 class="bgBlue">Lista Utenti</h1>
        <div class="btnToolbar">
            <Toolbar>
                <template #start>
                    <Button icon="pi pi-user-plus" class="btnLightBlue" @click="goToAddNewUser" />
                </template>
                <template #end>
                    <Button icon="pi pi-download" class="btnGrey" @click="exportUsersList" />
                </template>
            </Toolbar>
        </div>
        <div class="filtersRow py-3">
            <form @submit.prevent="getUsers()" class="filterBox grid justify-content-around align-items-center">
                <div class="form-group ml-2 sm:col-12 md:col-6 lg:col-2">
                    <InputText type="text" placeholder="Filtra per nome" v-model="filters.firstname" class="dimCustom" />
                </div>
                <div class="form-group ml-2 sm:col-12 md:col-6 lg:col-2">
                    <InputText type="text" placeholder="Filtra per cognome" v-model="filters.lastname" class="dimCustom" />
                </div>
                <div class="form-group sm:col-12 md:col-6 lg:col-2">
                    <Button label="FILTRA" class="btnGreen mr-2" type="submit" />
                    <Button label="ANNULLA" class="btnLightBlue" @click="resetFilters" />
                </div>
            </form>
        </div>    
        <DataTable :value="users" class="p-datatable">
            <Column field="firstname" header="NOME"></Column>
            <Column field="lastname" header="COGNOME"></Column>
            <Column field="username" header="USERNAME"></Column>
            <Column header="#SOTTOSCRIZIONI ATTIVE">
                <template #body="slotProps">
                    <span><strong>{{ slotProps.data.subsActive }}</strong> di <strong>{{ slotProps.data.subsTot }}</strong></span>
                </template>
            </Column>
            <Column :exportable="false" style="min-width:8rem">
                <template #body="slotProps">
                    <router-link :to="{ name: 'UserDetails', params: { id: slotProps.data.id } }" title="dettaglio">
                        <i class="bi bi-person-lines-fill btn btnLightBlue" style="border-radius: 50%"></i>
                    </router-link>
                    <Button v-if="slotProps.data.status === 0" icon="pi pi-check" class="p-button-rounded btnGreen mr-2" @click="enableUsr(slotProps.data.id)" />
                    <Button v-else icon="pi pi-times" class="p-button-rounded btnRed mr-2" @click="disableUsr(slotProps.data.id)" />
                </template>
            </Column>
        </DataTable>
        <Toast /> 
        <div v-if="pages !== 1" class="myPaginator">
            <div v-if="previous.page" class="arrowBox" @click="getUsers(parseInt(previous.page), parseInt(limit))"><i class="pi pi-angle-left"></i></div>
            <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
            <div class="pagesBox">
                <span>PAGINA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
            </div>
            <div v-if="next.page" class="arrowBox" @click="getUsers(parseInt(next.page), parseInt(limit))"><i class="pi pi-angle-right"></i></div>
            <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
        </div> 
    </div>
</template>

<script>
//import { mapState } from 'vuex';
import axios from 'axios'


export default {
    data() {
        return {
            token: '',
            users: [],
            getValues: {
                success: '',
                message: ''
            },
            filters: {
                firstname: '',
                lastname: ''
            },
            filterResult: {
                success: '',
                message: ''
            },
            filtersAreActive: false,
            currentPage: 1,
            page: 1,
            first: 0,
            limit: 10,
            totalRecords: null,
            next: {
                page: null,
                limit: null
            },
            previous: {
                page: null,
                limit: null
            },
            pages: null
        }
    },
    computed: {
        //...mapState(['user'])
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        // devo fare la chiamata per ottenere la lista degli utenti
        this.getUsers();
    },
    methods: {
        getUsers(page = 1, limit = 10) {
            /* this.loading = true; */
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            if(this.filters.firstname || this.filters.lastname) {
                console.log('c è almeno un campo dei filtri selezionato o compilato');
                this.filtersAreActive = true;
                console.log(this.filtersAreActive);
            } else {
                console.log('nessun campo compilato');
                console.log(this.filtersAreActive);
            }
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/users', { filtering: this.filtersAreActive, filters: this.filters }, { params: { page: page, limit: limit }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success) {
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;
                    this.users = res.data.users;
                    this.pages = res.data.pages;
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = "";
                    }
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = "";
                    }
                    this.filterResult.success = '';
                    this.filterResult.message = '';

                } else if(res.data.info) {
                    this.filterResult.success = false;
                    this.filterResult.message = res.data.message;
                    this.users = [];
                    this.pages = 1;
                } else {
                    this.getValues.success = res.data.success;
                    this.getValues.message = res.data.message;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.filterResult.success === true) {
                    this.$toast.add({ severity: 'success', summary: this.filterResult.message, life: 1500 });
                } else if(this.filterResult.success === false) {
                    this.$toast.add({ severity: 'warn', summary: this.filterResult.message, life: 2500 });
                }
                console.log('chiamata terminata da fn getUsers');
            });
        },
        disableUsr(id) {
            const result = confirm(`Vuoi disabilitare l'utente?`);
            console.log(result);
            if(result) {
                // vuole disabilitare l'utente
                axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/userUpdt', {'role': 'user', 'status': 0, 'id': id} , {headers: { 'x-access-token': this.token  }} )
                .then((res) => {
                    console.log(res);
                    if(res.data.success) { //true
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        }
                        // cambio lo stato dell'utente nello store
                        this.$store.dispatch("changeStatus", 0);
                        this.getValues.success = res.data.success;
                        this.getValues.message = res.data.message;
                    } else {// false, ci sono stati errori
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        }
                        this.$store.dispatch("changeStatus", 0);
                        this.getValues.success = res.data.success;
                        this.getValues.message = res.data.message;
                    }
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    console.log(id);
                    console.log(this.getValues);
                    // cerco l'utente nell array e cambio il valore di status
                    const user = this.users.find( (user) => user.id === id );
                    //console.log(user);
                    user.status = 0;
                    console.log('chiamata terminata');
                });
            }
        },
        enableUsr(id) {
            const result = confirm(`Vuoi abilitare l'utente?`);
            console.log(result);
            if(result) {
                axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/userUpdt', {'role': 'user', 'status': 1, 'id': id} , {headers: { 'x-access-token': this.token  }} )
                .then((res) => {
                    console.log(res);
                    if(res.data.success) { //true
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        }
                        this.$store.dispatch("changeStatus", 1);
                        this.getValues.success = res.data.success;
                        this.getValues.message = res.data.message;
                    } else {// false, ci sono stati errori
                        if(res.headers['x-token-refresh']) {
                            this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                        }
                        this.$store.dispatch("changeStatus", 1);
                        this.getValues.success = res.data.success;
                        this.getValues.message = res.data.message;
                    }
                })
                .catch((err) => {
                    console.error(err.toString());
                })
                .finally(() => {
                    console.log(this.getValues);
                    // cerco l'utente nell array e cambio il valore di status
                    const user = this.users.find( (user) => user.id === id );
                    //console.log(user);
                    user.status = 1;
                    console.log('chiamata terminata');
                });
            }
        },
        goToAddNewUser() {
            this.$router.push("/newuser");
        },
        /* SEZIONE FUNZIONE EXPORT */
        exportUsersList() {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/exportUsers', this.filters, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log('exportUsersList: RESPONSE');
                console.log(res.data);
                if(res.data.success) {
                    if (res.data.redirect) {
                    window.open( res.data.redirect, "_blank");
                    return;
                    } else {
                    this.$toast.add({ severity: 'warn', summary: 'ERRORE', detail:'Il link di download non è stato generato', life: 2500 });
                    }
                } else {
                    this.$toast.add({ severity: 'warn', summary: 'ERRORE', detail: res.data.message || 'Si è verificato un errore', life: 2500 });
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                console.log('exportUsersList: FINISHED');
            });

        },
        /* SEZIONE FUNZIONE RESET FILTRI */
        resetFilters() {
            this.filters.firstname = '';
            this.filters.lastname = '';
            this.filtersAreActive = false;
            this.getUsers();
        }
    }
}
</script>

<style scoped>
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.p-datatable {
    max-width: 100vw;
}
.dimCustom {
    width: 100%;
}
</style>
