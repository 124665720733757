<template>
    <div class="grid deleteDefault">
        <h1 class="bgBlue col-12">Correzione quiz completato</h1>
        <div class="bgBlue col-12">
            <router-link to="/quizdone" class="btn btnRed my-3">Lista Quiz Completati</router-link>
        </div>
        <div v-for="item in quiz" :key="item.id" class="p-card col-12">
            <div class="questionBox">
                <p class="mb-1"><strong>{{item.question}}</strong></p>
            </div>
            <div v-if="'questionTypology' in item">
                <p class="mb-1">Modulo: {{item.matter}}, Tipologia: {{item.questionTypology}}, corretta: {{item.points}} pt / penalità: -{{item.malus}} pt</p>
            </div>
            <div class="answerBox">
                <div v-for="(option, index) in answers" :key="index" class="field-radiobutton col-12">
                    <RadioButton :id="option+index" name="user_answer" :value="option.value" v-model="item.user_answer" disabled="disabled" />
                    <label v-if="item.right_answer === option.value" :for="option+index">{{ option.text }}<i class="pi pi-check ml-3 text-green-500"></i></label>
                    <label v-else :for="option+index">{{ option.text }}<i class="pi pi-times ml-3 wrongOption"></i></label>
                </div>
            </div>
        </div>
        <div class="myPaginator col-12">
            <div v-if="previous.page" class="arrowBox" @click="switchQuestions(attemptID, this.reqStatus, parseInt(previous.page), parseInt(previous.limit))"><i class="pi pi-angle-left"></i></div>
            <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-left"></i></div>
            <div class="pagesBox">
                <span>DOMANDA: <strong>{{ page }}</strong> di <strong>{{ pages }}</strong></span>
            </div>
            <div v-if="next.page" class="arrowBox" @click="switchQuestions(attemptID, this.reqStatus, parseInt(next.page), parseInt(next.limit))"><i class="pi pi-angle-right"></i></div>
            <div v-else class="arrowBox myBtnDisabled"><i class="pi pi-angle-right"></i></div>
        </div>
    </div>       
</template>

<script>
import axios from 'axios';

export default {
    //props: ['attemptID'],
    data() {
        return {
            token: '',
            quiz_id: null,
            userID: null,
            attemptID: null,
            description: '',
            quizStructure: [],
            success: '',
            message: '',
            quiz: [],
            answers: [],
            currentPage: 1,
            page: 1,
            first: 0,
            totalRecords: null,
            next: {
                page: null,
                limit: ''
            },
            previous: {
                page: null,
                limit: ''
            },
            pages: null,
            ansLeft: null,
            isLastPage: false,
            quizStatus: '',
            threshold: null,
            userResult: null,
            customMex: '',
            reqStatus: 'completed'
        }
    },
    methods: {
        getCompletedQuiz(attemptID, page = 1, limit = 1) {
            this.answers = [];
            
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/completedAttempt', { params: { attemptID: attemptID, page: page, limit:limit}, headers:{ 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.quiz = res.data.quizQuestion;
                //console.log(res.data.quizQuestion[0].rispA);
                this.answers.push(
                    { value: 1, text: res.data.quizQuestion[0].rispA},
                    { value: 2, text: res.data.quizQuestion[0].rispB},
                    { value: 3, text: res.data.quizQuestion[0].rispC},
                    { value: 4, text: res.data.quizQuestion[0].rispD}
                );
                this.totalRecords = res.data.totalQuizQuestions;
                this.pages = res.data.pages;
                this.ansLeft = res.data.ansLeft;
                if(res.data.next) {
                    this.next.page = res.data.next.page;
                    this.next.limit = res.data.next.limit;
                } else {
                    this.next.page = '';
                }
                if(res.data.previous) {
                    this.previous.page = res.data.previous.page;
                    this.previous.limit = res.data.previous.limit;
                } else {
                    this.previous.page = '';
                }
                this.success = res.data.success;
                this.message = res.data.message;
    
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    /* console.log('ORDINE ORIGINALE');
                    this.answers.forEach(element => {
                        console.log(element.text);
                    }); */
                    console.log(this.currentPage);
                    this.answers = this.shuffleAnswers(this.answers);
                    /* console.log('MISCHIATO');
                    console.log(this.answers); */
                }
                console.log('chiamata terminata');
            })
        },
        switchQuestions(aID, reqStatus, page = 1, limit = 1) {
            this.answers = [];
            if(page > this.currentPage) {
                // voglio andare alla pagina successiva
                // quindi ho usato il tasto next
                // quindi il valore di page è next.page
                this.page = this.next.page;
                this.currentPage = this.next.page;
            } else if (page < this.currentPage) {
                this.page = this.previous.page;
                this.currentPage = this.previous.page;
            }
            axios.get(process.env.VUE_APP_API_URL +  '/api/v1/p/changePage', { params: { attemptID: aID, reqStatus: reqStatus, page: page, limit:limit}, headers: { 'x-access-token': this.token }})
            .then((res) => {
                console.log(res.data);
                if(res.data.success) {
                    this.quiz = res.data.quizQuestion;
                    //console.log(res.data.quizQuestion[0].rispA);
                    this.answers.push(
                        { value: 1, text: res.data.quizQuestion[0].rispA},
                        { value: 2, text: res.data.quizQuestion[0].rispB},
                        { value: 3, text: res.data.quizQuestion[0].rispC},
                        { value: 4, text: res.data.quizQuestion[0].rispD}
                    );
                    this.totalRecords = res.data.totalQuizQuestions;
                    this.pages = res.data.pages;
                    this.ansLeft = res.data.ansLeft;
                    if(res.data.next) {
                        this.next.page = res.data.next.page;
                        this.next.limit = res.data.next.limit;
                    } else {
                        this.next.page = '';
                    }
                    if(res.data.previous) {
                        this.previous.page = res.data.previous.page;
                        this.previous.limit = res.data.previous.limit;
                    } else {
                        this.previous.page = '';
                    }
                    this.success = res.data.success;
                    this.message = res.data.message;
                } else {
                    this.success = res.data.success;
                    this.message = res.data.message;
                }
    
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    /* console.log('ORDINE ORIGINALE');
                    this.answers.forEach(element => {
                        console.log(element.text);
                    }); */
                    console.log(this.currentPage);
                    this.answers = this.shuffleAnswers(this.answers);
                    /* console.log('MISCHIATO');
                    console.log(this.answers); */
                }
                console.log('chiamata terminata');
            });
        },
        shuffleAnswers(array) {
            //console.log('INIZIO LOOP => expected values 4');
            //console.log(array.length);
            let result = [];

            while (array.length > 0) {
                let randomIndex = Math.floor(Math.random() * (array.length));
                //console.log(randomIndex);
                // trovo indice random
                // all inizio gli elementi saranno 4 perchè sono presenti tutte le opzioni
                // con il find trovo l'elemento
                const item = array.find((ele, index) => {
                    if(index === randomIndex) {
                        //console.log(ele);
                        return ele;
                    };
                });
                //console.log('valore elemento alla posizione random');
                //console.log(item);
                // push questo item nel nuovo array risultato
                result.push(item);
                // e lo rimuovo dall array originale
                array.splice(randomIndex, 1);
                //console.log('valore array length alla fine del ciclo');
                //console.log(array.length);
            }
            return result;
        }
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.userID = this.$store.state.user.id;
        this.attemptID = this.$store.state.selAttemptID;
        this.getCompletedQuiz(this.attemptID);
    }


}
</script>

<style scoped>
.deleteDefault {
    margin: 0;
}
.bgBlue {
    background-color: #004b87;
    margin: 0;
    padding: 5px 0;
    color: #ffffff;
}
.p-card {
    max-width: 100vw;
    margin: auto;
    padding: 0;
}
.questionBox {
    padding: 25px 0;
    max-width: 100vw;
    background: #f1f2f2;
}
.answerBox {
    background: #ffffff;
    color: black;
    padding: 40px 0 25px 30px;
    margin-right: 0;
    text-align: left;
    max-width: 100vw;
}
.btnComplete {
    background: #ff0000;
    color: #ffffff;
}
.wrongOption {
    color: #ff0000;
}
</style>
