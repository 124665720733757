<template>
    <h2 class="myTitleStyle">QUIZ DISPONIBILI</h2>
    <div v-if="userRole === 'admin'">
        <Dropdown v-model="userCourseChoiceId" :options="coursesList" optionLabel="courseName" optionValue="id" placeholder="Seleziona un corso*" @change="getQuizzes(userCourseChoiceId)"/>
    </div>
    <div v-if="userCourseChoiceId">
        <div v-if="loading">
            <ProgressSpinner />
        </div>
        <div v-else>
            <div v-if="success === true">
                <Toolbar>
                    <template #start>
                        <Button icon="pi pi-plus" label="Crea Quiz" class="btnLightBlue mr-3 mt-2" @click="goToCreateQuiz" /> 
                    </template>
                    <template #end>
                        <Button icon="pi pi-arrow-circle-right" label="Risultati Quiz" class="btnGreen mt-2" @click="goToCompletedQuiz" /> 
                    </template>
                </Toolbar>
                <div class="desktopLayout">
                    <TabView lazy>
                        <TabPanel :header="this.userCourseChoiceId == 1 ? 'SIMULAZIONE ESAME E AREE TEMATICHE' : 'TEST GENERALE E TEST PER MODULO'">
                            <DataTable :value="quizPublic" v-model:expandedRows="expandedRows" dataKey="id"
                                responsiveLayout="stack" class="p-datatable">
                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="description" header="Descrizione"></Column>
                                <!-- <Column field="visibility" header="Visibilità"></Column> -->
                                <Column field="threshold" header="Soglia Superamento %"></Column>
                                <Column field="inProgress" header="Tentativi in corso"></Column>
                                <Column field="completed" header="Tentativi completati"></Column>
                                <Column v-if="userRole === 'admin'">
                                    <template #body="slotProps">
                                        <Button v-if="slotProps.data.inProgress || slotProps.data.available" title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue m-2" @click="showQuizInProgress(slotProps.data.id)" />
                                        <Button v-else title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue m-2" disabled="disabled" />
                                        <Button v-if="!slotProps.data.available" icon="pi pi-eye" class="btnGreen m-2" title="Rendi disponibile la struttura di questo quiz" @click="enableQuizS($event, slotProps.data.id)" />
                                        <Button v-else icon="pi pi-eye-slash" class="btnRed m-2" title="Disattiva l'utilizzo di questo quiz" @click="unableQuizS($event, slotProps.data.id)" />
                                        <Button v-if="slotProps.data.editable === 1" icon="pi pi-pencil" class="m-2 btnBlue" /> <!-- FIXME: @click="goToUpdateQuiz(slotProps.data.id)" -->
                                        <Button v-else icon="pi pi-pencil" class="m-2 btnBlue" disabled="disabled" />
                                        <Button v-if="slotProps.data.editable === 1" icon="pi pi-trash" class="m-2 btnGrey" @click="confirmDelete($event, slotProps.data.id)" />
                                        <Button v-else icon="pi pi-trash" class="m-2 btnGrey" disabled="disabled" />
                                        <ConfirmPopup></ConfirmPopup>
                                        <ConfirmPopup group="demo">
                                            <template #message="slotProps">
                                                <div class="flex p-4">
                                                    <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                                    <p class="pl-2">{{slotProps.message.message}}</p>
                                                </div>
                                            </template>
                                        </ConfirmPopup>
                                        <Toast />
                                    </template>
                                </Column>
                                <Column v-else-if="userRole === 'user'">
                                    <template #body="slotProps">
                                        <Button v-if="slotProps.data.inProgress || slotProps.data.available" title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue m-2" @click="showQuizInProgress(slotProps.data.id)" />
                                        <Button v-else title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue m-2" disabled="disabled" />
                                        <Button v-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'private'" icon="pi pi-pencil" class="m-2 btnBlue" /> <!-- FIXME: @click="goToUpdateQuiz(slotProps.data.id)" -->
                                        <Button v-else-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'public'" icon="pi pi-pencil" class="m-2 btnBlue" disabled="disabled" />
                                        <Button v-else icon="pi pi-pencil" class="m-2 btnBlue" disabled="disabled" />
                                        <Button v-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'private'" icon="pi pi-trash" class="m-2 btnGrey" @click="confirmDelete($event, slotProps.data.id)" />
                                        <Button v-else-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'public'" icon="pi pi-trash" class="m-2 btnGrey" disabled="disabled" />
                                        <Button v-else icon="pi pi-trash" class="m-2 btnGrey" disabled="disabled" />
                                        <ConfirmPopup></ConfirmPopup>
                                        <ConfirmPopup group="demo">
                                            <template #message="slotProps">
                                                <div class="flex p-4">
                                                    <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                                    <p class="pl-2">{{slotProps.message.message}}</p>
                                                </div>
                                            </template>
                                        </ConfirmPopup>
                                        <Toast />
                                    </template>
                                </Column>
                                <template #expansion="slotProps">
                                    <div class="extendedTable">
                                        <h5 class="greyTitle">Nome QUIZ: {{ slotProps.data.description }}</h5>
                                        <DataTable :value="slotProps.data.quiz_structure" stripedRows responsiveLayout="stack">
                                            <Column field="matter" header="materia">
                                                <template #body="slotProps">
                                                    {{ getMatterName(slotProps.data.matter) }} 
                                                </template>
                                            </Column>
                                            <Column v-if="slotProps.data.quiz_structure[0].subject.length > 0" field="subject" header="argomento">
                                                <template #body="slotProps">
                                                    <div v-for="subject in slotProps.data.subject">
                                                        {{ getSubjectName(subject) }}
                                                    </div>
                                                </template>
                                            </Column>
                                            <Column v-if="slotProps.data.quiz_structure[0].content && slotProps.data.quiz_structure[0].content.length > 0" field="content" header="contenuto">
                                                <template #body="slotProps">
                                                    <div v-for="content in slotProps.data.content">
                                                        {{ getContentName(content) }}
                                                    </div>
                                                </template>
                                            </Column>
                                            <Column field="quantity" header="numeroDomande"></Column>
                                        </DataTable>
                                    </div>
                                </template>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="I MIEI QUIZ">
                            <DataTable :value="quizPrivate" v-model:expandedRows="expandedRows" dataKey="id"
                                responsiveLayout="stack" class="p-datatable">
                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="description" header="Descrizione"></Column>
                                <Column field="threshold" header="Soglia Superamento %"></Column>
                            <Column field="inProgress" header="Tentativi in corso"></Column>
                            <Column field="completed" header="Tentativi completati"></Column>
                            <Column v-if="userRole === 'admin'">
                                    <template #body="slotProps">
                                        <Button title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue m-2" @click="showQuizInProgress(slotProps.data.id)" />
                                        <Button v-if="slotProps.data.editable === 1" icon="pi pi-pencil" class="m-2 btnBlue"  /> <!-- FIXME: @click="goToUpdateQuiz(slotProps.data.id)" -->
                                        <Button v-else icon="pi pi-pencil" class="m-2 btnBlue" disabled="disabled" />
                                        <Button v-if="slotProps.data.editable === 1" icon="pi pi-trash" class="m-2 btnGrey" @click="confirmDelete($event, slotProps.data.id)" />
                                        <Button v-else icon="pi pi-trash" class="m-2 btnGrey" disabled="disabled" />
                                        <ConfirmPopup></ConfirmPopup>
                                        <ConfirmPopup group="demo">
                                            <template #message="slotProps">
                                                <div class="flex p-4">
                                                    <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                                    <p class="pl-2">{{slotProps.message.message}}</p>
                                                </div>
                                            </template>
                                        </ConfirmPopup>
                                        <Toast />
                                    </template>
                                </Column>
                                <Column v-else-if="userRole === 'user'">
                                    <template #body="slotProps">
                                        <Button title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue m-2" @click="showQuizInProgress(slotProps.data.id)" />
                                        <Button v-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'private'" icon="pi pi-pencil" class="m-2 btnBlue" /> <!-- FIXME: @click="goToUpdateQuiz(slotProps.data.id)" -->
                                        <Button v-else-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'public'" icon="pi pi-pencil" class="m-2 btnBlue" disabled="disabled" />
                                        <Button v-else icon="pi pi-pencil" class="m-2 btnBlue" disabled="disabled" />
                                        <Button v-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'private'" icon="pi pi-trash" class="m-2 btnGrey" @click="confirmDelete($event, slotProps.data.id)" />
                                        <Button v-else-if="slotProps.data.editable === 1 && slotProps.data.visibility === 'public'" icon="pi pi-trash" class="m-2 btnGrey" disabled="disabled" />
                                        <Button v-else icon="pi pi-trash" class="m-2 btnGrey" disabled="disabled" />
                                        <ConfirmPopup></ConfirmPopup>
                                        <ConfirmPopup group="demo">
                                            <template #message="slotProps">
                                                <div class="flex p-4">
                                                    <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                                    <p class="pl-2">{{slotProps.message.message}}</p>
                                                </div>
                                            </template>
                                        </ConfirmPopup>
                                        <Toast />
                                    </template>
                                </Column>
                                <template #expansion="slotProps">
                                    <div class="extendedTable">
                                        <h5 class="greyTitle">Nome QUIZ: {{ slotProps.data.description }}</h5>
                                        <DataTable :value="slotProps.data.quiz_structure" stripedRows responsiveLayout="stack">
                                            <Column field="matter" header="materia">
                                                <template #body="slotProps">
                                                    {{ getMatterName(slotProps.data.matter) }} 
                                                </template>
                                            </Column>
                                            <Column v-if="slotProps.data.quiz_structure[0].subject.length > 0" field="subject" header="argomento">
                                                <template #body="slotProps">
                                                    <div v-for="subject in slotProps.data.subject">
                                                        {{ getSubjectName(subject) }}
                                                    </div>
                                                </template>
                                            </Column>
                                            <Column v-if="slotProps.data.quiz_structure[0].content && slotProps.data.quiz_structure[0].content.length > 0" field="content" header="contenuto">
                                                <template #body="slotProps">
                                                    <div v-for="content in slotProps.data.content">
                                                        {{ getContentName(content) }}
                                                    </div>
                                                </template>
                                            </Column>
                                            <Column field="quantity" header="numeroDomande"></Column>
                                        </DataTable>
                                    </div>
                                </template>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
                <div class="mobileLayout">
                    <div>
                        <div class="py-3">
                            <h6 class="py-2 mb-0"> {{ userCourseChoiceId == 1 ? 'SIMULAZIONE ESAME E AREE TEMATICHE' : 'TEST GENERALE E TEST PER MODULO' }} </h6>
                            <a href="#I_MIEI_QUIZ" class="linkStyle">I MIEI QUIZ</a>
                        </div>
                        <div class="rowBgGrey grid">
                            <div class="col-4">Descrizione</div>
                            <div class="col-5">In corso / Terminati</div>
                            <div class="col-3"></div>
                        </div>
                        <div v-for="(qp, i) in quizPublic" :key="i" class="tRow grid">
                            <div class="col-4">{{ qp.description }}</div>
                            <div class="col-5">{{ qp.inProgress }} / {{ qp.completed }}</div>
                            <div v-if="userRole === 'admin'" class="col-3 noPaddingY">
                                <Button v-if="qp.inProgress || qp.available" title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue btnSmall m-2" @click="showQuizInProgress(qp.id)" />
                                <Button v-else title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue btnSmall m-2" disabled="disabled" />
                                <Button v-if="!qp.available" icon="pi pi-eye" class="btnGreen btnSmall m-2" title="Rendi disponibile la struttura di questo quiz" @click="enableQuizS($event, qp.id)" />
                                <Button v-else icon="pi pi-eye-slash" class="btnRed btnSmall m-2" title="Disattiva l'utilizzo di questo quiz" @click="unableQuizS($event, qp.id)" />
                                <Button v-if="qp.editable === 1" icon="pi pi-pencil" class="btnBlue btnSmall m-2"  /> <!-- FIXME: @click="goToUpdateQuiz(qp.id)" -->
                                <Button v-if="qp.editable === 1" icon="pi pi-trash" class="btnGrey btnSmall m-2" @click="confirmDelete($event, qp.id)" />
                                <ConfirmPopup></ConfirmPopup>
                                <ConfirmPopup group="demo">
                                    <template #message="slotProps">
                                        <div class="flex p-4">
                                            <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                            <p class="pl-2">{{slotProps.message.message}}</p>
                                        </div>
                                    </template>
                                </ConfirmPopup>
                                <Toast />
                            </div>
                            <div v-else class="col-3">
                                <Button v-if="qp.inProgress || qp.available" title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue btnSmall m-2" @click="showQuizInProgress(qp.id)" />
                                <Button v-if="qp.editable === 1 && qp.visibility === 'private'" icon="pi pi-pencil" class="btnBlue btnSmall m-2"  /> <!-- FIXME: @click="goToUpdateQuiz(qp.id)" -->
                                <Button v-if="qp.editable === 1 && qp.visibility === 'private'" icon="pi pi-trash" class="btnGrey btnSmall m-2" @click="confirmDelete($event, qp.id)" />
                                <ConfirmPopup></ConfirmPopup>
                                <ConfirmPopup group="demo">
                                    <template #message="slotProps">
                                        <div class="flex p-4">
                                            <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                            <p class="pl-2">{{slotProps.message.message}}</p>
                                        </div>
                                    </template>
                                </ConfirmPopup>
                                <Toast />
                            </div>
                        </div>
                    </div>    
                    <a id="I_MIEI_QUIZ">
                        <h6 class="py-2 mb-0">I MIEI QUIZ</h6>
                        <div class="rowBgGrey grid">
                            <div class="col-4">Descrizione</div>
                            <div class="col-5">In corso / Terminati</div>
                            <div class="col-3"></div>
                        </div>
                        <div v-for="(qp, i) in quizPrivate" :key="i" class="tRow grid">
                            <div class="col-4">{{ qp.description }}</div>
                            <div class="col-5">{{ qp.inProgress }} / {{ qp.completed }}</div>
                            <div v-if="userRole === 'admin'" class="col-3">
                                <Button v-if="qp.inProgress || qp.available" title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue btnSmall m-2" @click="showQuizInProgress(qp.id)" />
                                <Button v-else title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue btnSmall m-2" disabled="disabled" />
                                <Button v-if="qp.editable === 1" icon="pi pi-pencil" class="btnBlue btnSmall m-2"  /> <!-- FIXME: @click="goToUpdateQuiz(qp.id)" -->
                                <Button v-if="qp.editable === 1" icon="pi pi-trash" class="btnGrey btnSmall m-2" @click="confirmDelete($event, qp.id)" />
                                <ConfirmPopup></ConfirmPopup>
                                <ConfirmPopup group="demo">
                                    <template #message="slotProps">
                                        <div class="flex p-4">
                                            <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                            <p class="pl-2">{{slotProps.message.message}}</p>
                                        </div>
                                    </template>
                                </ConfirmPopup>
                                <Toast />
                            </div>
                            <div v-else class="col-3">
                                <Button v-if="qp.inProgress || qp.available" title="Esegui o riprendi il tentativo" icon="pi pi-play" class="btnLightBlue btnSmall m-2" @click="showQuizInProgress(qp.id)" />
                                <Button v-if="qp.editable === 1 && qp.visibility === 'private'" icon="pi pi-pencil" class="btnBlue btnSmall m-2"  /> <!-- FIXME: @click="goToUpdateQuiz(qp.id)" -->
                                <Button v-if="qp.editable === 1 && qp.visibility === 'private'" icon="pi pi-trash" class="btnGrey btnSmall m-2" @click="confirmDelete($event, qp.id)" />
                                <ConfirmPopup></ConfirmPopup>
                                <ConfirmPopup group="demo">
                                    <template #message="slotProps">
                                        <div class="flex p-4">
                                            <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                                            <p class="pl-2">{{slotProps.message.message}}</p>
                                        </div>
                                    </template>
                                </ConfirmPopup>
                                <Toast />
                            </div>
                        </div>    
                    </a>
                </div>
            </div>    
            <div v-else class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div v-if="success === 'info'" class="text-700 text-center bg-yellow-500 p-5">
                    <div class="text-blue-600 font-bold mb-3">MESSAGGIO INFORMATIVO</div>
                    <div class="text-900 font-bold text-5xl mb-3">{{ message }}</div>
                    <Button label="Crea Quiz" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap" @click="goToCreateQuiz"></Button>
                </div>
                <div v-else class="text-700 text-center" style="background-color: red;">
                    <div class="text-blue-600 font-bold mb-3"><i class="pi pi-discord"></i>&nbsp;ERRORE GENERICO</div>
                    <div class="text-900 font-bold text-5xl mb-3">{{ message }}</div>
                    <Button label="Torna alla Dashboard" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            quizStatus: 'in progress',
            token: '',
            userID: null,
            userRole: '',
            userCourseChoiceId: null,
            expandedRows: [],
            quizPublic: [],
            quizPrivate: [],
            message: '',
            success: '',
            resultChangeAvQS: {
                success: '',
                message: ''
            },
            loading: false,
            numUserActiveSubs: null,
            coursesList: [],
            coursesIdsList: [],
            titoloTab: '',
            matters: [],
            subjects: [],
            contents: []
        }
    },
    methods: {
        ...mapActions(['setQuizID', 'setAttemptID', 'setExistsActiveSubs', 'changeUserHasActiveSub', 'setCourseId']),
        getQuizzes(courseID) {
            this.loading = true;
            this.message = '';
            this.success = '';
            console.log(courseID);
            this.setCourseId(courseID);
            console.log("getquizzesfn");
            console.log(this.$store.state.selCourseID);
            axios.get(process.env.VUE_APP_API_URL + '/api/v1/p/quiz', { params: {quizStatus: this.quizStatus, userID: this.userID, userRole: this.userRole, courseID: courseID}, headers:{ 'x-access-token': this.token } })
            .then((res) => {
                //console.log("quizDisponibili", res.data);
                if(res.headers['x-token-refresh']) {
                    this.$store.dispatch("updateToken", res.headers['x-token-refresh']);
                }
                if(res.data.success === true) {
                    this.quizPublic = res.data.quizPublic;
                    this.quizPrivate = res.data.quizPrivate;
                    this.message = res.data.message;
                    this.success = res.data.success;
                } else if(res.data.success === 'info') {
                    this.message = res.data.message;
                    this.success = res.data.success;
                } else {
                    this.message = res.data.message;
                    this.success = res.data.success;
                }
                this.fetchMatters(this.userCourseChoiceId);
                this.fetchSubjects(this.userCourseChoiceId);
                this.fetchContents(this.userCourseChoiceId);
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                this.loading = false;
                //console.log('chiamata terminata da fn getQuizzes in QuizIndex.vue');
            });
        },
        countUserSub(id) {
            axios.post(process.env.VUE_APP_API_URL + '/api/v1/p/userActiveSubs', { id: id }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.numUserActiveSubs = res.data.numActiveSubs;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.numUserActiveSubs === 0) {
                    // scrivere nel vuex false in corrispondenza della voce se esistono sub attive
                    //console.log('valore store.state.areThereUserActiveSubs');
                    //console.log(this.$store.state.areThereUserActiveSubs);
                    this.setExistsActiveSubs(false);
                    // cambio anche il valore di has_active_sub nel vuex, perchè anche se cambiato in db nel vuex non cabia fino al logout
                    this.changeUserHasActiveSub(0);
                } else if(this.numUserActiveSubs > 0) {
                    // scrivere nel vuex true
                    this.setExistsActiveSubs(true);
                    //console.log('valore store.state.areThereUserActiveSubs');
                    //console.log(this.$store.state.areThereUserActiveSubs);
                    this.changeUserHasActiveSub(1);
                }
                //console.log('chiamata terminata da fn countUserSub');
            });
        },
        goToCreateQuiz() {
            this.$router.push('/createquiz');
        },
        goToUpdateQuiz(id) {
            console.log(id);
            this.setQuizID(id);
            this.$router.push({name: 'UpdateQuiz', params: { id: id }});
        },
        deleteQuizStructure(id) {
            // dovrà essere previsto il controllo per quando ci saranno i tentativi
            // se una struttura viene usata, non potrà più essere eliminata
            axios.delete(process.env.VUE_APP_API_URL + '/api/v1/p/deleteQuizStructure', { params: { id: id}, headers:{ 'x-access-token': this.token } })
            .then((res) => {
                if(res.data.success) {
                    this.message = res.data.message;
                    this.success = true;
                } else {
                    this.message = res.data.message;
                    this.success = false;
                }
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.success) {
                    this.getQuizzes(this.userCourseChoiceId);
                    this.$toast.add({ severity: 'success', summary: this.message, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: this.message, life: 3000 });
                }
            });
        },
        confirmDelete(event, id) {
            console.log(id);
            this.$confirm.require({
                target: event.currentTarget,
                message: "Procedere con l'eliminazione definitiva?",
                header: "Conferma Eliminazione",
                icon: "pi pi-exclamation-triangle",
                acceptClass: "p-button-danger",
                group: 'demo',
                accept: () => {
                    this.deleteQuizStructure(id);
                },
                reject: () => {
                    this.$toast.add({ severity: 'info', summary: `Il quiz con id #${id}, NON é stato eliminato!`, life: 3000 });
                }
            });
        },
        showQuizInProgress(id) {
            this.setQuizID(id);
            this.getQuizzes(this.userCourseChoiceId);
            this.$router.push({name: 'ShowQuizInProgress', params: { quizID: id , courseID: this.userCourseChoiceId}});
        },
        unableQuizS(event, qID) {
            console.log(qID);
            this.$confirm.require({
                target: event.currentTarget,
                message: "Procedere con la disattivazione della struttura quiz?",
                header: "Conferma Disattivazione",
                icon: "pi pi-exclamation-triangle",
                acceptClass: "p-button-danger",
                group: 'demo',
                accept: () => {
                    console.log('Ho deciso di disattivare la struttura quiz, devo lanciare la funzione che modifica lo stato in db!');
                    this.updateQuizStructureAv(qID, false);
                },
                reject: () => {
                    this.$toast.add({ severity: 'info', summary: `La struttura di questo quiz, NON é stata disattivata e sarà ancora disponibile!`, life: 1500, closable: false });
                }
            });
        },
        enableQuizS(event, qID) {
            console.log(qID);
            this.$confirm.require({
                target: event.currentTarget,
                message: "Procedere con l'attivazione della struttura quiz?",
                header: "Conferma Attivazione",
                icon: "pi pi-exclamation-triangle",
                acceptClass: "p-button-info",
                group: 'demo',
                accept: () => {
                    console.log('Ho deciso di attivare la struttura quiz, devo lanciare la funzione che modifica lo stato in db!');
                    this.updateQuizStructureAv(qID, true);
                },
                reject: () => {
                    this.$toast.add({ severity: 'info', summary: `La struttura di questo quiz, NON é stata attivata e quindi NON sarà disponibile!`, life: 1500, closable: false });
                }
            });
        },
        updateQuizStructureAv(id, available) {
            axios.put(process.env.VUE_APP_API_URL + '/api/v1/p/updtQuizStructureAv', { quizID: id, available: available }, { headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
                this.resultChangeAvQS.success = res.data.success;
                this.resultChangeAvQS.message = res.data.message;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                if(this.resultChangeAvQS.success) {
                    this.$toast.add({ severity: 'success', summary: this.resultChangeAvQS.message, life: 1500, closable: false });
                    this.quizPublic.forEach((quiz) => {
                        if(quiz.id === id) {
                            if(available) {
                                quiz.available = 1;
                            } else {
                                quiz.available = 0;
                            }
                        }
                    })
                    setTimeout(() => {
                        this.resultChangeAvQS.success = '';
                        this.resultChangeAvQS.message = '';
                    }, 2000);
                } else {
                    this.$toast.add({ severity: 'error', summary: this.resultChangeAvQS.message, life: 1500, closable: false });
                    setTimeout(() => {
                        this.resultChangeAvQS.success = '';
                        this.resultChangeAvQS.message = '';
                    }, 2000);
                }
                console.log('chiamata terminata da fn updateQuizStructureAv');
            });
        },
        goToCompletedQuiz() {
            this.$router.push('/quizdone');
        },
        deleteEmptyAttempt() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/deleteEmptyAttemptByUserID`, { params: { uID: this.userID }, headers: { 'x-access-token': this.token } })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.error(err);
                throw new Error(err);
            })
            .finally(() => {
                //console.log('chiamata terminata da fn deleteEmptyAttempt');
            });
        },
        getCoursesIds() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/coursesIds`, { headers: { 'x-access-token': this.token }})
            .then((res) => {
                //console.log(res.data);
                if(res.data.success) {
                    this.coursesList = res.data.coursesList;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                console.log('chiamata terminata da fn getCoursesIds');
                
            });
        },
        fetchMatters(courseID) {
            //console.log("fetchmattersfn")
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/getmatters`, {
                params: { courseID: courseID, isAdmin: 1, matterArray: null },
                headers: { 'x-access-token': this.token } 
            })
            .then((res) => { 
                //console.log("Matters", res.data);
                this.matters = res.data.matters;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log('chiamata terminata da fn fetchmatters in QuizIndex.vue');
            });
        },
        getMatterName(matter) {
            //console.log("matterid:", mID, "matterobjnames:", this.matters);
            let matterName = '';
            for (let i = 0; i < this.matters.length; i++) {
                if (this.matters[i].matter_code === matter) {
                    matterName = this.matters[i].matter;
                    break; // Exit the loop once the matching matter is found
                }
            }
            //console.log("Found matter:", matterName);
            return matterName;
        },
        fetchSubjects(courseID) {
            //console.log("fetchsubjectsfn");
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/getsubjects`, {
                params: { courseID: courseID,  matterID: null, matterArray: null },
                headers: { 'x-access-token': this.token } 
            })
            .then((res) => { 
                //console.log("Subjects", res.data);
                this.subjects = res.data.subjects;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log('chiamata terminata da fn fetchsubjects in QuizIndex.vue');
            });
        },
        getSubjectName(subject) {
            let subjectName = '';
            for (let i = 0; i < this.subjects.length; i++) {
                if (this.subjects[i].subject_code === subject) {
                    subjectName = this.subjects[i].subject;
                    break;
                }
            }
            return subjectName;
        },
        fetchContents(courseID = 1) {
            //console.log("fetchcontentsfn");
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/p/getallcontents`, {
                params: { courseID: courseID, isAdmin: 1 ,matterArray: null },
                headers: { 'x-access-token': this.token } 
            })
            .then((res) => { 
                //console.log(res.data);
                this.contents = res.data.contents;
            })
            .catch((err) => {
                console.error(err.toString());
            })
            .finally(() => {
                //console.log('chiamata terminata da fn fetchcontents in QuizIndex.vue');
            });
        },
        getContentName(content) {
            let contentName = '';
            for (let i = 0; i < this.contents.length; i++) {
                if (this.contents[i].content_code === content) {
                    contentName = this.contents[i].content;
                    break;
                }
            }
            return contentName;
        },
        
    },
    mounted() {
        this.token = this.$store.state['x-access-token'];
        this.userID = this.$store.state.user.id;
        this.userRole = this.$store.state.user.role;
        this.userCourseChoiceId = this.$store.state.selCourseID;
        console.log("courseidmounted",this.userCourseChoiceId);
        if(this.userRole === 'admin' && this.userCourseChoiceId){
            this.getQuizzes(this.userCourseChoiceId);
        };
        this.getCoursesIds();
        if(this.userRole === 'user'){
            this.getQuizzes(this.userCourseChoiceId);
        };
        this.countUserSub(this.userID); //???
        this.deleteEmptyAttempt();  //???
        
    }
 
}
</script>

<style>
.p-datatable {
    max-width: 100vw;
}
.p-datatable .p-datatable-header {
    background: #cdcfd0 !important;
}
.p-column-title {
    color: #004b87 !important;
}
.myTitleStyle {
    background: #004b87;
    margin: 0;
    padding: 5px 0 10px 0;
    color: white;
}
.extendedTable {
    width: 100% !important;
}
.greyTitle {
    background: #cdcfd0;
    color: #004b87;
    padding: 10px;
    margin: -1rem -1rem 0 -1rem;
}
.btnGreen {
    background: #017632 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGreen:enabled:hover {
    background: #017300 !important;
}
.btnLightBlue {
    background: #009bca !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnLightBlue:enabled:hover {
    background: #007cdb !important;
}
.btnBlue {
    background: #004b87 !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnBlue:enabled:hover {
    background: #004b87 !important;
}
.btnGrey {
    background: #58595b !important;
    border: none !important;
    color: #eaeaea !important;
}
.btnGrey:enabled:hover {
    background: #58595a !important;
}
.desktopLayout {
    display: block;
}
.mobileLayout {
    display: none;
}
/* MEDIA QUERY */
@media screen and (max-width: 550px) {
    .desktopLayout {
        display: none;
    }
    .mobileLayout {
        display: block;
        background: #ffffff;
        font-family: "corbel";
    }
    .rowBgGrey {
        background: #efefef;
        width: 100%;
    }
    .tRow {
        border-bottom: 1px solid #58595b;
    }
    .noPaddingY {
        padding: 0 8px !important;
    }
    .btnSmall {
        padding: 0.25rem !important;
        /* background: #009bca !important;
        color: #eaeaea !important;
        border: none !important; */
    }
    .linkStyle {
        color: #eaeaea;
        background: #009bca;
        padding: 5px;
        border-radius: 5px;
    }
}
</style>
