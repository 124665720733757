<template>
    <div class="m0 flex-column relative">
        <section class="container secP">
            <img src="../../public/images/ESAMIFINANZA_home_3.jpg" alt="home_img" class="coverImg">
            <div class="subTitle text-center">
                <h3 class="subTitleStyle">L’<span class="txtRed">app</span> ESAMIFINANZA ti aiuta a superare l'esame da Consulente Finanziario e per acquisire le certificazioni EFPA perchè:</h3>
            </div>
            <div class="container">
                <div class="row">
                <div class="customCardBox col">
                    <div class="customCard">
                        <div class="numBox">1</div>
                        <p class="pCustom">utilizza la <strong class="pCustomH">SELEZIONE SMART</strong><br>delle domande</p>
                    </div>
                </div>
                <div class="customCardBox col">
                    <div class="customCard">
                        <div class="numBox">2</div>
                        <p class="pCustom">è una <strong class="pCustomH">GUIDA EFFICACE</strong><br>nell'attività di TESTING</p>
                    </div>
                </div>
                <div class="customCardBox col">
                    <div class="customCard">
                        <div class="numBox">3</div>
                        <p class="pCustom">rappresenta un <strong class="pCustomH">METODO INNOVATIVO</strong><br>di apprendimento costante</p>
                    </div>
                </div>   
                </div>
            </div>
            <h2 class="subTitleStyle">
                Puoi scegliere i seguenti percorsi per gli esami:
            </h2>
        </section>
       
        <section class="container">
            <DataTable :value="exams" >
                <Column field="name" header="ESAME" class="font-bold text-xl"></Column>
                <Column field="qualifica" header="QUALIFICA" class="font-bold text-xl"></Column>
                <Column header="">
                    <template #body="slotProps">
                        <Button label="SCOPRI DI PIU'" @click="selectExam(slotProps.data), visible = true" />
                    </template>
                </Column>
            </DataTable>

            <Dialog v-model:visible="visible" modal :header="selectedExams.name" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">   
                <p class="text-lg">Hai scelto di prepararti per l’esame <span class="font-bold"> {{ selectedExams.name }} </span> , registrati o effettua il LOGIN, poi scegli il periodo che preferisci.</p>
                <div class="m0 align-items-center mb-5 mt-5 subContainer">
                    <div v-for="courseid in selectedExams.coursesid" :key="courseid" class="priceBox">
                        <div class="priceContent">
                            <div v-for="(subDetail, index) in getSubDetails(courseid)" :key="subDetail.id">
                                <p v-if="index === 0" style="font-size: 1rem;"><strong>{{ subDetail.courseName }}</strong> <br> {{ getCourseDesc(courseid).description }} </p>
                                <div v-if="subDetail.price === 0" class="priceDetails txtRed">
                                    <strong>{{ subDetail.days_duration }}</strong> giorni <strong>PROVA GRATUITA</strong>
                                </div>
                                <div v-else-if="index % 2 != 0" class="priceDetails bgGrey">
                                    <strong>{{ subDetail.days_duration }}</strong> giorni <strong>{{ subDetail.price }} €</strong> (iva compresa)
                                </div>
                                <div v-else-if="index % 2 == 0" class="priceDetails">
                                    <strong>{{ subDetail.days_duration }}</strong> giorni <strong>{{ subDetail.price }} €</strong> (iva compresa)
                                </div>
                            </div>
                        </div>
                    </div>                        
                </div>
                <div class="text-center mb-3">
                    <router-link to="/register" class="registerBtn"><strong>REGISTRATI</strong></router-link>
                </div>
            </Dialog>
        </section>
        
        <section class="container text-left">
            <div class="contentBox">
                <div>
                    <h3 class="contentTitle">Che cos’è la SELEZIONE SMART delle domande?</h3>
                    <p class="contentP">È un processo di selezione delle domande intelligente che ti consente di effettuare test sui singoli argomenti, per aree tematiche, sulle simulazioni d'esame e sulle domande che hai sbagliato!</p>
                </div>
                <div>
                    <h3 class="contentTitle">Perché è una GUIDA EFFICACE?</h3>
                    <p class="contentP">Perchè è un processo personalizzato sulla tua attività, che ti segnala il livello di preparazione sui diversi argomenti e sulla totalità delle domande presenti. <span class="contentPH">I grafici personalizzati</span> sono uno strumento che facilita la tua preparazione.</p>
                </div>
                <div>
                    <h3 class="contentTitle">Che cosa significa METODO INNOVATIVO di apprendimento costante?</h3>
                    <p class="contentP">Prepararsi con METODO significa poter affiancare allo studio di un singolo argomento i test relativi, coprire in modo efficace e più rapido l’analisi di tutto il bacino di domande, 
                        affrontare domande sempre nuove e superare, tramite una playlist dedicata, quelle che si sono sbagliate. <br>
                        L’<span>app</span> <span class="contentPH">ESAMIFINANZA</span> si inserisce perfettamente nel <span class="contentPH">METODO ESAMIFINANZA</span> perché, affiancata ai corsi di formazione <span class="contentPH">ESAMIFINANZA</span>, ti permette di superare l'esame con più facilità.
                    </p>
                </div>
                <div>
                    <h3 class="contentTitle">La sottoscrizione prevede il rinnovo automatico?</h3>
                    <p class="contentP">No, 2 giorni prima della scadenza del periodo da te scelto, riceverai una email di avviso e, se lo desideri, potrai sottoscrivere un altro periodo a tua scelta. 
                        Ricorda che puoi sottoscrivere un nuovo periodo in qualunque momento, <span class="font-bold">il periodo decorre sempre dalla scadenza</span>. 
                        Ad esempio, se la tua sottoscrizione scade il 30 aprile e sottoscrivi un periodo di 30 giorni il 15 aprile, il prolungamento scadrà il 30 maggio.</p>
                </div>
            </div>
        </section>
        <section class="container">
            <div class="mb-3">
                <h4 class="infoTitle"><i class="pi pi-envelope text-2xl pr-3"></i> Per ogni ulteriore approfondimento, invia una mail a <a class="myLink" href="mailto:info@esamifinanza.it">info@esamifinanza.it</a></h4>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            subsPriceList: [],
            coursesList: [],
            exams: [
                { name: 'OCF', qualifica: 'Consulente Finanziario', coursesid: [1] },
                { name: 'EIP', qualifica: 'European Investment Practitioner', coursesid: [2, 3] },
                { name: 'EFA', qualifica: 'European Financial Advisor', coursesid: [4, 5] },
                { name: 'EIS', qualifica: 'European Insurance Specialist', coursesid: [6, 7, 8] },
            ],
            visible: false,
            selectedExams: [],
        }
    },
    methods: {
        getSubsPriceList() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/subsPriceList`)
            .then((res) => {
                //console.log(res.data);
                if(res.data.success) {
                    this.subsPriceList = res.data.subsPriceList;
                    //console.log('valore subsPriceList @@@@', this.subsPriceList);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                //console.log('chiamata terminata da fn getSubsPriceList');
            });
        },
        fetchCourses(){
            axios.get(`${process.env.VUE_APP_API_URL}/api/v1/coursesEditions`)
            .then((res) => {
                //console.log(res.data.courses);
                if(res.data.success) {
                    this.coursesList = res.data.courses;
                    //console.log('valore coursesList @@@@', this.coursesList);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                //console.log('chiamata terminata da fn coursesList');
            });
        },
        selectExam(exams) {
            this.selectedExams = exams;
            //console.log("selectedExams: ", this.selectedExams)
        },
        getSubDetails(courseid) {
            //console.log("RetrieveinfoSubxCorso: ", courseid);
            return this.subsPriceList.filter((subDetail) => subDetail.idCorso === courseid);
        },
        getCourseDesc(courseid) {
            //console.log("RetrieveDescxCorso: ", courseid);
            return this.coursesList.find((course) => course.course_id === courseid)
        },
    },
    mounted() {
        this.getSubsPriceList();
        this.fetchCourses();
    }

}
</script>

<style>
    /* SEZIONE GENERALE */
    .m0 {
        margin: 0;
    }
    .coverImg {
        width: inherit;
        margin-bottom: 20px;
    }

    .secP {
        padding: 0 0 20px 0;
    }
    /* PRIMA SEZIONE */
    .mainTitle {
        color: #004b87;
        padding: 80px 0 40px;
        height: 500px;
        /* position: absolute;
        top: 10%;
        left:15%; 
        max-width: 1296px;
        width: 100%;*/
    }
    .fSize {
        font-size: 5rem;
        font-weight: 800;
        max-width: 100%;
    }
    /* SECONDA SEZIONE */
    .subTitle {
        margin-bottom: 20px;
    }
    .subTitleStyle {
        /* font-size: 2rem; */
        font-weight: 800;
        margin-top: 15px;
    }
    .txtRed {
        color: #c4161c;
    }
    .cardBoxMobile {
        display: none;
    }
    .customCard {
        background: #ffffff;
        position: relative;
        box-shadow: 3px 3px 5px #888888;
        text-align: left;
        padding: 15px 10px 15px 50px;
        height: 100%;
    }
    .customCard:nth-child(1) {
        margin: 0 10px;
    }
    .customCard:nth-child(3) {
        margin: 0 10px;
    }
    .numBox {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: 600;
        font-size: 3rem;
        color: #017632;
        padding: 0 0 0 5px;
    }
    .pCustom {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
    }
    .pCustomH {
        font-weight: 900;
        white-space: nowrap;
    }
    /* TERZA SEZIONE */
    .contentBox {
        background: #ffffff;
        padding: 30px 30px 0 30px;
        margin-top: 20px;
    }
    .contentTitle {
        color: #004b87;
        font-weight: 800;
        font-size: 28px;
        margin-bottom: 0;
    }
    .contentP {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        padding: 10px 0;
    }
    .contentPH {
        font-weight: 700;
    }
    /* QUARTA SEZIONE */
    .mView {
        display: none;
    }
    .subContainer{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .priceBox {
        margin: 20px;
        padding: 0;
        box-shadow: 3px 3px 5px #888888;
        max-width: 450px;
    }
    .priceBoxImg {
        padding: 0 30px 30px 30px;
        max-width: 100%;
        max-height: 100%;
    }
    .priceImg {
        max-width: 75%;
        max-height: 75%;
        object-fit: cover;
    }
    .priceHeader2{
        background: #018b3b;
        color: #ffffff;
        padding: 10px 0;
    }
    .priceHeader {
        background: #004b87;
        color: #ffffff;
        padding: 10px 0;
    }
    .priceHeadTitle {
        margin: 0;
        font-weight: 800;
        font-size: 1.1rem;
    }
    .priceContent {
        background: #ffffff;
        padding: 15px 80px;
    }
    .priceDetails {
        font-size: 17px;
    }
    .bgGrey {
        background: #e5e7e8;
    }
    .registerBtn {
        background: #017632;
        border-radius: 5px;
        padding: 15px;
        color: #ffffff;
        text-decoration: none;
    }
    .registerBtn:hover {
        background: #018b3b;
        color: #ffffff;
    }
    /* SECONDA PARTE LANDING PAGE */
    .contentBox2 {
        background: #ffffff;
        padding: 20px 30px 0 30px;
        margin-bottom: 20px;
    }
    .contentBox3 {
        background: #ffffff;
        padding: 20px 30px 0 30px;
    }
    .infoTitle {
        color: #004b87;
        padding: 10px 0 30px 0;
    }
    .myLink {
        color: #004b87;
        text-decoration: none;
    }
    #faq{
        text-align: left;
    }
    .dialogP{
        font-size: 18px;
    }
    #colOCF , #colEFPA{
        transition: box-shadow .3s;
        border-radius: 10px;
        border: 1px solid #ccc;
        float: left;
        }
    #colOCF:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
    #colEFPA:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
    /* MEDIA QUERY PER GESTIRE DIMENSIONE TITOLO */
    @media screen and (max-width: 600px) {
        .fSize {
            font-size: 3rem;
            font-weight: 800;
            max-width: 100%;
        }
    }
    @media screen and (max-width: 575px) {
        .cardBox {
            display: none;
        }
        .mainTitle {
            height: 350px;
        }
        .cardBoxMobile {
            display: block;
            padding-right: 10px;
        }
        .dView {
            display: none;
        }
        .mView {
            display: block;
        }
        .priceBoxImg {
            padding: 0 30px;
        }
        .priceContent {
            padding: 15px 30px;
        }
    }
</style>